import { useState, useEffect, useRef } from 'react';
//import {stopRecording} from "../components/Hooks/recorderHelpers";


const AudioContext = window.AudioContext || (window).webkitAudioContext;

export default function useDgListener(props) {

    const audioContextRef = useRef();
    //const timeoutId = useRef();
    const mediaStream = useRef();
    const mediaSocket = useRef();

    const [isRecording, setIsRecording] = useState(false);
    const [couldNotUnderstand, setCouldNotUnderstand] = useState("");
    const [isSpeaking, setIsSpeaking] = useState(false);
    const [dgInterimResult, setDgInterimResult] = useState();
    const [error, setError] = useState('');
    const [dgResults, setDgResults] = useState([]);
    const ua = navigator.userAgent.toLowerCase();
    let isApple = false
    //var isApple = true;

    if (ua.indexOf('safari') !== -1) {
        isApple = ua.indexOf('chrome') <= -1;
    }

    useEffect(() => {
        const ua = navigator.userAgent.toLowerCase();
        let isApple = false
        //var isApple = true;

        if (ua.indexOf('safari') !== -1) {
            isApple = ua.indexOf('chrome') <= -1;
        }

        if (isApple) {
            if (!navigator?.mediaDevices?.getUserMedia) {
                alert('getUserMedia is not supported on this device/browser :(');
            }

            if (!audioContextRef.current) {
                audioContextRef.current = new AudioContext();
            }
        }

    }, []);



    const languageMap = {
        "en-US": "en-US",
        "en-GB": "en-GB",
        "es-ES": "es",
        "es-MX": "es-419",
        "fr-FR": "fr",
        "de-DE": "de",
        "it-IT": "it",
        "pt-BR": "pt-BR",
        "pt-PT": "pt-PT",
        "ja-JP": "ja",
        "cmn-CN": "zh-CN",
        "ko-KR": "ko-KR",
        "hi-IN": "hi",
        "id-ID": "id",
        "vi-VN": "vi",

        "ne-NP": "ne-NP",
        "am-ET": "am-ET",
        "so-SO": "so-SO",
        "ar-SA": "ar-SA",
        "fil-PH": "fil-PH",
        "ar-IQ": "ar-IQ",

    }


    const dgStopSpeechToText = () => {
        setIsSpeaking(false);
        setIsRecording(false);
        stopMediaStream();

    };

    const dgStartSpeechToText = async (language) => {

        stopMediaStream();

        if (audioContextRef.current?.state === 'suspended') {
            audioContextRef.current?.resume();
        }

        navigator.mediaDevices.getUserMedia({audio: true, video: false}).then((stream) => {

            let options = {mimeType: 'audio/webm'};

            if (MediaRecorder.isTypeSupported('audio/webm')) {
                options = {mimeType: 'audio/webm'};
            } else if (MediaRecorder.isTypeSupported('video/mp4')) {
                options = {mimeType: 'video/mp4'};
            } else {
                console.error("no suitable mimetype found for this device");
            }
            //const mediaRecorder = new MediaRecorder(stream, options);

            const mediaRecorder = new MediaRecorder(stream, options);
            mediaStream.current = mediaRecorder;

            let socket = null;

            try {
                socket = new WebSocket("wss://api.deepgram.com/v1/listen?model=nova-2-general&multichannel=false&" +
                    "interim_results=true&punctuate=true&language=" + languageMap[language], ["token", "8dc4a90cd2e0732075ea0a58c993c4d9c880f289"]);
            } catch (e) {
                console.log(e)
            }

            mediaSocket.current = socket;
            socket.onopen = () => {

                mediaRecorder.addEventListener("dataavailable", (event) => {
                    try {
                        if (event.data.size > 0) {
                            socket.send(event.data)
                        }
                    } catch (e) {
                        //console.log(e)
                    }
                })
                mediaRecorder.start(1000);
            }

            socket.onmessage = (event) => {

                const receivedData = JSON.parse(event.data);
                const transcript = receivedData.channel.alternatives[0].transcript;

                if (transcript.length > 0) {
                    if (receivedData.is_final) {
                        console.log("final transcript", transcript)
                        setDgResults(transcript)
                    } else {
                        setDgInterimResult(transcript)
                    }
                }

            }

            socket.onclose = () => {
                //console.log("socket closed")
            }

        })

        //console.log("socket created")

    }


    const stopMediaStream = () => {

        if (mediaStream) {
            mediaStream.current?.stop();
        }

        if (mediaSocket) {
            mediaSocket.current?.close();
        }

    };


    return {
        //error,
        isSpeaking,
        dgInterimResult,
        isRecording,
        couldNotUnderstand,
        dgResults,
        dgStartSpeechToText,
        dgStopSpeechToText
    };
}

