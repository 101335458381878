import React, {Component} from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    TextField
} from "@mui/material";
import ChatOptionSelector from "../embeddedSelectors/chatOptionSelector";
import LanguageSelector from "../embeddedSelectors/languageSelector";
import QRCode from "react-qr-code";
import {
    req_create_session_id,
    req_quick_session_id,
    req_personal_session_id,
    req_shared_session_id
} from "../../helpers/requests";
import {
    createChatSession,
    createChatSessionObject,
    createChatSessionUser,
    createSelectedAccount, sessionReset
} from "../../helpers/session";
import eventBus from "../../helpers/eventBus";
import {content} from "../../globals/content";
import {ExplanationCard} from "../widgets/explanationCard";
import CopyAllIcon from "@mui/icons-material/CopyAll";

export default class CreateChat extends Component {

    state = {
        open: true,
        conversationType: "",
        sessionCode: "",
        language: "",
        peerLanguage: "",
        loading: false,
        chatSessionId: "",
        name: "",
        guestName: "Guest",
        chatNameError: false,
        guestNameError: false,
        inviteId: "",
        typeProperties: {
            conversation: {
                icon: "forum",
                color: "#cd5c5c",
                titleProp: "selectChatTypeGroupChat"
            },
            translate: {
                icon: "translate",
                color: "#7772b8",
                titleProp: "selectChatTypeTranslationChat"
            },
            shared: {
                icon: "account-multiple",
                color: "#008080",
                titleProp: "selectChatTypeSharedScreenChat"
            },
            quickConnect: {
                icon: "qrcode",
                color: "#de3163",
                titleProp: "selectChatTypeQuickConnectChat"
            }
        }
    };



    handleClose = () => {
        this.setState({open: false});
        this.props.close()
    }

    handleCopy = () => {
        console.log("copy")
        if (this.state.conversationType === "quickConnect") {
            navigator.clipboard.writeText("https://earthlingual.com/qc/" + this.state.inviteId);
        } else {
            navigator.clipboard.writeText("https://earthlingual.com/i/" + this.state.inviteId);
        }
    }

    redirectToChat = () => {
        //router.push("/sessionmodal");
    }

    componentDidMount() {
        this.setState({language: localStorage.getItem("siteLanguage") || "en-US"});
        this.setState({peerLanguage: localStorage.getItem("siteLanguage") || "en-US"});
    }

    setConversationInvoke = (ctype) => {
        this.setState({conversationType: ctype});
    }

    setLanguage = (lang) => {
        this.setState({language: lang});
    }
    setPeerLanguage = (lang) => {
        this.setState({peerLanguage: lang});
    }

    clearChatData = () => {
        this.setState({
            name: "",
            guestName: "Guest",
            language: "en-US",
            peerLanguage: "en-US",
            showQrCode: false,
            conversationType: "",
        })
    }

    updateSession = () => {

        let sessionObj = JSON.parse(localStorage.getItem("cSession"));

        eventBus.dispatch("rebaseChat", {sessionObj: sessionObj});

        /*
        if (localStorage.getItem("cSessionId") && localStorage.getItem("cSessionId") !== "undefined") {
            this.setState({chatSessionId: localStorage.getItem("cSessionId"), open: false});
            this.props.updateSession(localStorage.getItem("cSessionId"), this.state.language, {accountId: localStorage.getItem("selectedAccount")});

        } else {
            this.setState({chatSessionId: undefined});
        }
        */
    }

    createSession = (vConversationType, vSessionCode, vLanguage) => {

        let self = this;

        this.setState({chatNameError: false});
        this.setState({guestNameError: false});

        if (!this.state.name) {
            this.setState({chatNameError: true});
            return;
        }

        if ((this.state.conversationType === "shared") && !this.state.guestName) {
            this.setState({guestNameError: true});
            return;
        }

        eventBus.dispatch("showLoader", {showLoader: true})

        let sessionRequest = null;
        if (this.state.conversationType === "conversation") {
            sessionRequest = req_create_session_id(this.state.language, this.state.name)
        } else if (this.state.conversationType === "shared") {
            sessionRequest = req_shared_session_id(this.state.language, this.state.peerLanguage, this.state.name, this.state.guestName)
        } else if (this.state.conversationType === "translate") {
            sessionRequest = req_personal_session_id(this.state.language, this.state.peerLanguage, this.state.name)
        } else {
            sessionRequest = req_quick_session_id(this.state.language, this.state.name)
        }
        console.log(sessionRequest)


        sessionRequest
            .then((response) => {
                console.log("NEW RESPONSE", response.data)
                self.setState({inviteId: response.data.inviteId});

                //localStorage.setItem("chatSessions", JSON.stringify([]));
                //localStorage.setItem("cSession", JSON.stringify({}));
                //localStorage.setItem("cSessionId", response.data.newSession);

                //createChatSession(response.data.newSession);
                //createChatSessionUser(response.data.userId);
                //createChatSessionObject(response.data.newSession);


                //notify("openSessionPanel", { session: response.data.newSession });


                if (self.state.conversationType === "translate" || self.state.conversationType === "shared") {
                    console.log("Open directly")
                    //router.push("/sessionmodal");
                    sessionReset(response.data.newSession);

                    //this.updateSession();

                } else {
                    sessionReset(response.data.newSession, false);
                    self.setState({showQrCode: true});
                }

                eventBus.dispatch("updateSessions", {}); // ?

            })
            .catch((error) => console.error("Session creation failed:", error))
            .finally(() => eventBus.dispatch("showLoader", {showLoader: false}));




    }

    render() {

        return (
            <Dialog
                fullWidth={true}
                maxWidth={"md"}
                style={{minWidth: 370}}
                open={this.props.open}
                onClose={this.handleClose}
                PaperProps={{
                    component: 'form',
                    onSubmit: (event) => {
                        event.preventDefault();
                        this.handleClose();
                    },
                }}
            >
                <DialogTitle>{content.createChatPanel[localStorage.getItem("siteLanguage") || "en-US"].title}</DialogTitle>
                <DialogContent style={{padding: "10px"}}>
                    {this.state.conversationType === "" ?
                        <div style={{
                            display: "flex",
                            flexDirection: "row",
                            flexWrap: "wrap",
                            gap: 10,
                            justifyContent: "space-around"
                        }}>

                            <ExplanationCard key={"cardImp_conversation"}
                                             sessionType={"conversation"}
                                             setSessionType={this.setConversationInvoke}
                                             title={content.createChatPanel[localStorage.getItem("siteLanguage") || "en-US"].selectChatTypeGroupChat}
                                             typeProps={this.state.typeProperties.conversation}
                                             text={content.createChatPanel[localStorage.getItem("siteLanguage") || "en-US"].descriptionGroupChat}
                                             createButton={content.createChatPanel[localStorage.getItem("siteLanguage") || "en-US"].createButton}
                            />

                            <ExplanationCard key={"cardImp_translate"}
                                             sessionType={"translate"}
                                             setSessionType={this.setConversationInvoke}
                                             title={content.createChatPanel[localStorage.getItem("siteLanguage") || "en-US"].selectChatTypeTranslationChat}
                                             typeProps={this.state.typeProperties.translate}
                                             text={content.createChatPanel[localStorage.getItem("siteLanguage") || "en-US"].descriptionTranslationChat}
                                             createButton={content.createChatPanel[localStorage.getItem("siteLanguage") || "en-US"].createButton}
                            />
                            <ExplanationCard key={"cardImp_shared"}
                                             sessionType={"shared"}
                                             setSessionType={this.setConversationInvoke}
                                             title={content.createChatPanel[localStorage.getItem("siteLanguage") || "en-US"].selectChatTypeSharedScreenChat}
                                             typeProps={this.state.typeProperties.shared}
                                             text={content.createChatPanel[localStorage.getItem("siteLanguage") || "en-US"].descriptionSharedScreenChat}
                                             createButton={content.createChatPanel[localStorage.getItem("siteLanguage") || "en-US"].createButton}
                            />
                            <ExplanationCard key={"cardImp_quickConnect"}
                                             sessionType={"quickConnect"}
                                             setSessionType={this.setConversationInvoke}
                                             title={content.createChatPanel[localStorage.getItem("siteLanguage") || "en-US"].selectChatTypeQuickConnectChat}
                                             typeProps={this.state.typeProperties.quickConnect}
                                             text={content.createChatPanel[localStorage.getItem("siteLanguage") || "en-US"].descriptionQuickConnectChat}
                                             createButton={content.createChatPanel[localStorage.getItem("siteLanguage") || "en-US"].createButton}
                            />

                        </div> :


                        <div>
                            {!this.state.showQrCode ?
                        <div>
                                <div style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    backgroundColor: "#f7f7f7",
                                    justifyContent: "space-between",
                                    borderWidth: 1,
                                    borderColor: "#ddd",
                                    padding: 10,
                                    borderRadius: 5
                                }}>
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        fontSize: "1rem",
                                        fontWeight: "bold"
                                    }}>
                                        {content.createChatPanel[localStorage.getItem("siteLanguage") || "en-US"][this.state.typeProperties[this.state.conversationType].titleProp]}
                                    </div>
                                    <div>
                                        <Button
                                            onClick={this.clearChatData}>{content.createChatPanel[localStorage.getItem("siteLanguage") || "en-US"].changeButton}</Button>
                                    </div>
                                </div>



                                    {this.state.conversationType ?
                                        <div style={{width: "100%", margin: "10px auto 0px auto"}}>

                                            <div style={{textAlign: "center"}}>
                                                <TextField value={this.state.name}
                                                           required={true}
                                                           error={this.state.chatNameError}
                                                           label={content.createChatPanel[localStorage.getItem("siteLanguage") || "en-US"].chatNameLabel}
                                                           onChange={(e) => {
                                                               this.setState({name: e.target.value})
                                                           }} style={{width: "300px"}}
                                                >
                                                </TextField>
                                            </div>
                                        </div>
                                        : null
                                    }


                                    <div style={{textAlign: "center", margin: "10px auto", width: "300px"}}>
                                        <LanguageSelector language={this.state.language} setLanguage={this.setLanguage}
                                                          label={content.createChatPanel[localStorage.getItem("siteLanguage") || "en-US"].myLanguageLabel} mutliSelect={false}/>


                                        {this.state.conversationType === "shared" ?
                                            <div style={{width: "100%", margin: "10px auto 0px auto"}}>

                                                <div style={{textAlign: "center"}}>
                                                    <TextField value={this.state.guestName}
                                                               required={true}
                                                               error={this.state.guestNameError}
                                                               label={content.createChatPanel[localStorage.getItem("siteLanguage") || "en-US"].guestNameLabel}
                                                               onChange={(e) => {
                                                                   this.setState({guestName: e.target.value})
                                                               }} style={{width: "300px"}}
                                                    >
                                                    </TextField>
                                                </div>
                                            </div>
                                            : null
                                        }


                                        {this.state.conversationType === "shared" || this.state.conversationType === "translate" ?
                                            <div style={{textAlign: "center", margin: "10px auto", width: "300px"}}>
                                                <LanguageSelector language={this.state.peerLanguage} setLanguage={this.setPeerLanguage}
                                                                  label={content.createChatPanel[localStorage.getItem("siteLanguage") || "en-US"].translateToLanguageLabel} mutliSelect={true} />

                                            </div>: null
                                        }
                                    </div>


                        </div>: <div>
                                    <div style={{fontSize: "1.2rem", fontWeight: "bold", textAlign: "center", marginBottom: 15}}>
                                        {content.createChatPanel[localStorage.getItem("siteLanguage") || "en-US"].linkContentHead}
                                    </div>
                                    <div style={{textAlign: "center", marginBottom: 15}}>
                                        {content.createChatPanel[localStorage.getItem("siteLanguage") || "en-US"].linkContentDescription}
                                    </div>
                                    <div style={{textAlign: "center", alignSelf: "center", marginTop: 10}}>
                                        {this.state.conversationType === "quickConnect" ?

                                            <QRCode
                                                value={"https://earthlingual.com/qc/" + this.state.inviteId}
                                            /> :
                                            <QRCode
                                                value={"https://earthlingual.com/i/" + this.state.inviteId}
                                            />
                                        }
                                    </div>

                                    <div style={{marginTop: 20, textAlign: "center", marginBottom: 15}}>



                                        {this.state.conversationType === "quickConnect" ?

                                            <div>
                                                {"https://earthlingual.com/qc/" + this.state.inviteId}
                                                <IconButton onClick={this.handleCopy}><CopyAllIcon /></IconButton>
                                            </div>

                                             :
                                            <div>
                                                {"https://earthlingual.com/i/" + this.state.inviteId}
                                                <IconButton onClick={this.handleCopy}><CopyAllIcon /></IconButton>
                                            </div>


                                        }



                                    </div>
                                    <div style={{textAlign: "center"}}>
                                    <Button style={{
                                        margin: 5,
                                        borderRadius: 5,
                                        backgroundColor: "#333",
                                        color: "#FFF",

                                    }} onClick={this.updateSession}>
                                        {content.createChatPanel[localStorage.getItem("siteLanguage") || "en-US"].linkContentButton}
                                    </Button>
                                    </div>

                                </div>
                            }
                    </div>

                    }










                </DialogContent>
                {!this.state.showQrCode ?
                <DialogActions>
                    <Button variant={"outlined"} onClick={this.handleClose}>{content.createChatPanel[localStorage.getItem("siteLanguage") || "en-US"].cancelButton}</Button>
                    <Button variant={"outlined"} onClick={this.createSession}>{content.createChatPanel[localStorage.getItem("siteLanguage") || "en-US"].createButton}</Button>
                </DialogActions>
                : null
                }
            </Dialog>
        );
    }
}
