import { IconButton} from "@mui/material";
import ScrollableFeed from "react-scrollable-feed";
import MessageHistory from "../messages/messageHistory";
import React, {useEffect, useState, useRef} from "react";
import SettingsVoiceIcon from '@mui/icons-material/SettingsVoice';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import VideocamIcon from '@mui/icons-material/Videocam';
import EditIcon from '@mui/icons-material/Edit';
import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';
import HearingIcon from '@mui/icons-material/Hearing';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import {db} from "../../helpers/firebase";
import { v4 as uuidv4 } from 'uuid';
import {req_get_history, req_get_session} from "../../helpers/requests";

import "./chat.css"
import Typography from "@mui/material/Typography";
import CreateChat from "../dialogs/createChat";
import {
    clearChatSession,
    clearChatSessionUser,
    createChatSession,
    createChatSessionUser,
    getListeningState,
    setListeningState,
    setSuppressRestartState,
    getSuppressRestartState,
    getMicContious,
    setOtherInvokerState,
    getOtherInvokerState,
    createChatSessionObject, createChatSessionId,

} from "../../helpers/session";
import Drawer from "@mui/material/Drawer";
import SessionSelector from "../embeddedSelectors/sessionSelector";
import BottomNavigation from '@mui/material/BottomNavigation';
import ChatHeader from "../headers/chatHeader";
import AccountPanel from "../dialogs/accountPanel";
import SettingsPanel from "../dialogs/settingsPanel";
import InviteMember from "../dialogs/inviteMember";
import ChatDelete from "../dialogs/chatDelete";
import {content} from "../../globals/content";
import EditChatName from "../dialogs/editChatName";
import eventBus from "../../helpers/eventBus";
import DisplayNotifications from "../dialogs/displayNotifications";
import JoinChat from "../dialogs/joinChat";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import SiteInfo from "../dialogs/siteInfo";
import ChangeChatLanguage from "../dialogs/changeChatLanguage";
import ListeningOptions from "../dialogs/listeningOptions";
import QuickConnect from "../dialogs/quickConnect";
import LeftMenuBottom from "../widgets/leftMenuBottom";
import LeftMenuTop from "../widgets/leftMenuTop";
import ChatSubmissionContainer from "../widgets/chatSubmissionContainer";
import DashboardContent from "../widgets/dashboardContent";
import useSpeechToText, { ResultType } from "../Hooks";
import useDgListener from "../../listeners/dgListener";

export default function Chat(props) {

    const [language, setLanguage] = useState(props.selLang);
    const [otherLanguage, setOtherLanguage] = useState("en-US");
    const [messages, setMessages] = useState([]);
    const [chatSessionId, setChatSessionId] = useState(props.chatSessionId);
    const [waiting, setWaiting] = useState(false);
    const [understandingError, setUnderstandingError] = useState(false);
    const [projectId, setProjectId] = React.useState(window.location.pathname.replace("/", "").split("_")[0]);
    const [workflowStageId, setWorkflowStageId] = React.useState("");
    const [isVideoActive, setIsVideoActive] = React.useState(false);
    const [isAudioActive, setIsAudioActive] = React.useState(true);
    const [entryText, setEntryText] = useState("");
    const [otherEntryText, setOtherEntryText] = useState("");
    const [location, setLocation] = React.useState({latitude: "", longitude: ""});
    const [accountId, setAccountId] = useState(localStorage.getItem("selectedAccount"));

    const [firstLoad, setFirstLoad] = useState(true);
    const [loaded, setLoaded] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [createChatOpen, setCreateChatOpen] = React.useState(false);
    const [quickConnectOpen, setQuickConnectOpen] = React.useState(false);
    const [editMicOpen, setEditMicOpen] = React.useState(false);
    const [joinChatOpen, setJoinChatOpen] = React.useState(false);
    const [changeLanguageOpen, setChangeLanguageOpen] = React.useState(false);
    const [notificationsOpen, setNotificationsOpen] = React.useState(false);
    const [chatInviteOpen, setChatInviteOpen] = React.useState(false);
    const [chatDeleteOpen, setChatDeleteOpen] = React.useState(false);
    const [chatRenameOpen, setChatRenameOpen] = React.useState(false);
    const [drawerOpen, setDrawerOpen] = React.useState(false);
    const [accountDrawerOpen, setAccountDrawerOpen] = React.useState(false);
    const [settingsDrawerOpen, setSettingsDrawerOpen] = React.useState(false);
    const [drawerSessionType, setDrawerSessionType] = React.useState("");
    const [chatDetailObj, setChatDetailObj] = React.useState(null);

    const [refresh, setRefresh] = React.useState(false);
    const [autoplay, setAutoplay] = React.useState(localStorage.getItem("autoplay"));
    const [myLanguage, setMyLanguage] = React.useState(localStorage.getItem(""));
    const [sessionSpur, setSessionSpur] = React.useState(false);
    const [infoPanelOpen, setInfoPanelOpen] = React.useState(false);
    const [scrollButtonVisible, setScrollButtonVisible] = React.useState(false);
    const [micContinuous, setMicContinuous] = React.useState((localStorage.getItem("micContinuous") === "true") || false);

    const [recordingState, setRecordingState] = React.useState(false);
    const [buttonRecordingState, setButtonRecordingState] = React.useState(false);
    const [alertNewMessages, setAlertNewMessages] = React.useState(false);
    const [audioTimer, setAudioTimer] = React.useState(false);
    const [currentInterval, setCurrentInterval] = React.useState(0);
    const [chatPreselect, setChatPreselect] = React.useState("");
    const [waitingTimerInterval, setWaitingTimerInterval] = React.useState(0);

    const [isAnonymous, setIsAnonymous] = React.useState(localStorage.getItem("wstt") === "68245");
    const [activeSpeaking, setActiveSpeaking] = React.useState(false);
    const [speakingLanguage, setSpeakingLanguage] = React.useState("");
    const [recognition, setRecognition] = React.useState(null);
    const [selectedListen, setSelectedListen] = React.useState("browser");
    const [isListening, setIsListening] = React.useState(false);
    const [dgTimeout, setDgTimeout] = React.useState(null);
    const [googTimeout, setGoogTimeout] = React.useState(null);
    const [normTimeout, setNormTimeout] = React.useState(null);

    const ua = navigator.userAgent.toLowerCase();
    //console.log("NAVIGATOR", navigator.userAgent.toLowerCase());

    let isApple = false
    //var isApple = true;

    if (ua.indexOf('safari') != -1) {
        if (ua.indexOf('chrome') > -1) {
            isApple = false
        } else {
            isApple = true
        }
    }

    const scrollerRef = useRef(null);
    const messagesEndRef = useRef(null);
    const fbRef = useRef(null);


    const {dgIsSpeaking,
        dgInterimResult,
        dgIsRecording,
        dgCouldNotUnderstand,
        dgResults,
        dgStartSpeechToText,
        dgStopSpeechToText
    } = useDgListener({
        continuous: micContinuous,
        primaryLanguage: language,
        secondaryLanguage: otherLanguage,
    })


    const {
        error,
        interimResult,
        isRecording,
        results,
        isSpeaking,
        couldNotUnderstand,
        startSpeechToText,
        stopSpeechToText
    } = useSpeechToText({
        continuous: micContinuous,
        crossBrowser: true,
        googleApiKey: process.env.REACT_APP_API_KEY,
        speechRecognitionProperties: {interimResults: true, lang: language},
        useLegacyResults: false,
        enableAutomaticPunctuation: true,
        diarizationConfig: {},
        googleCloudRecognitionConfig: {
            encoding: 'LINEAR16',
            languageCode: language,
            enableAutomaticPunctuation: true,
            diarizationConfig: {},
        },
    });




    const rebaseChat = (uObj) => {

        eventBus.dispatch("showLoader", {showLoader: true})
        console.log("rebaseChat", uObj.sessionObj.id)
        setButtonRecordingState(false);

        turnOffMic();
        eventBus.dispatch("updateListeningState", {isListening: false})
        setDrawerOpen(false);
        setCreateChatOpen(false);
        setFirstLoad(true);

        if (uObj.sessionObj.hasOwnProperty("sessionMembers")) {
            setULanguage(uObj.sessionObj.sessionMembers);
        }

        setAccountId(uObj.sessionObj.accountId)
        setChatSessionId(uObj.sessionObj.id);
        setChatDetailObj(uObj.sessionObj);
        eventBus.dispatch("showLoader", {showLoader: false})

    }


    useEffect(() => {

        turnOffMic();
        setChatSessionId(props.chatSessionId);
        toggleAutoplay();
        eventBus.on("triggerChatRefresh", (uObj) => { simpleUpdateSession(); });
        eventBus.remove("triggerChatRefresh")

        eventBus.on("deleteMessage", (uObj) => { deleteMessage(uObj); });
        eventBus.remove("deleteMessage")

        eventBus.on("spurSession", (uObj) => { setSessionSpur(!sessionSpur); });
        eventBus.remove("spurSession")

        eventBus.on("showListening", (uObj) => { showListening(uObj); });
        eventBus.remove("showListening")

        eventBus.on("rebaseChat", (uObj) => { rebaseChat(uObj); });
        eventBus.remove("rebaseChat")

        refreshSessionData(true)

        if (localStorage.getItem("autoLoad") === "true") {

            let load_sessions = JSON.parse(localStorage.getItem("chatSessions"));
            let selectedSession = {};
            load_sessions.forEach((session) => {
                if (session.id === localStorage.getItem("cSessionId")) {
                    selectedSession = session;
                }
            })

            let lang = "";

            if (selectedSession && selectedSession.hasOwnProperty("sessionMembers")){
                (selectedSession["sessionMembers"]).forEach((member) => {
                    if (member.userId === localStorage.getItem("uid")){
                        lang = member.language;
                    }
                })
            }

            updateSession(localStorage.getItem("cSessionId"), lang, selectedSession);
        }

    }, [])

    useEffect(() => {
        refreshSessionData()
    }, [chatSessionId]);

    useEffect(() => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, [messages]);

    const clearEntryText = () => {
        setEntryText("")
        setOtherEntryText("")
        eventBus.dispatch("clearEntryText", {});
    }

    const setTextContent = (textString) => {
        if (getOtherInvokerState()) {
            setOtherEntryText(textString);
            return otherLanguage;
        } else {
            setEntryText(textString);
            return language;
        }
    }

    const evalNonContinuousBase = () => {
        let eRet = !getMicContious() && getListeningState()
        if (eRet){
            //console.log("not continuous")
            setSuppressRestartState(true);
            setListeningState(false);
            eventBus.dispatch("updateListeningState", {isListening: false})
            setIsListening(false);
        }
        return eRet;
    }

    const updateContinuous = (sendlanguage) => {
        setButtonRecordingState(true);
        setListeningState(true);
        eventBus.dispatch("updateListeningState", {isListening: true})
        turnOnMic(sendlanguage);
    }

    const updateSuppressRestart = () => {
        let preListeningState = getListeningState();
        setButtonRecordingState(false);
        eventBus.dispatch("updateListeningState", {isListening: false})
        setListeningState(false);
        setIsListening(false);
        return preListeningState;
    }



    useEffect(() => {
        //console.log("recognition change")

        if (recognition !== null) {

            recognition.onstart = () => {
                //console.log("recognizing")
            };

            recognition.onspeechstart = () => {
                //console.log("speech started")
                setActiveSpeaking(true)
            }

            recognition.onspeechend = () => {
                //console.log("speech ended")
                setActiveSpeaking(false)
            }

            recognition.onresult = (event) => {
                //console.log("recognition ON RESULT", isApple)
                if (isApple) {
                    console.log(event)
                } else {
                    //console.log("ON RESULT", getListeningState())
                    let final = "";
                    let interim = "";

                    console.log(event.results)
                    //console.log("otherInvoker", getOtherInvokerState())

                    for (var i = 0; i < event.results.length; ++i) {
                        if (event.results[i].isFinal) {
                            final = event.results[i][0].transcript;
                        } else {
                            interim += event.results[i][0].transcript;
                        }
                    }

                    //console.log("getSuppressRestartState", getSuppressRestartState())
                    //console.log("getListeningState", getListeningState())

                    let sendlanguage = language;

                    if (final !== "") {
                        //console.log(final, getOtherInvokerState())
                        sendlanguage = setTextContent(final)
                        if (evalNonContinuousBase()){
                            setRef(true, final, sendlanguage, null, "user", "", true);
                        } else {
                            console.log("continuous")
                            if (!getSuppressRestartState()) {
                                try {
                                    recognition.start();
                                } catch (e) {
                                    console.log("error", e)
                                }

                                if (normTimeout) {
                                    clearTimeout(normTimeout);
                                }

                                let timeoutId = setTimeout(() => {
                                    //console.log("Norm Finished at " + new Date())
                                    turnOffMic();
                                    setSuppressRestartState(true);
                                    setListeningState(false);
                                    eventBus.dispatch("updateListeningState", {isListening: false})
                                    setIsListening(false);
                                }, 20000);

                                //console.log("myTimer", timeoutId)
                                setNormTimeout(timeoutId)

                                updateContinuous(sendlanguage)
                                setRef(true, final, sendlanguage, null, "user", "", false);
                            } else {
                                console.log("in suppress restart")
                                //let preListeningState = getListeningState();
                                //setButtonRecordingState(false);
                                //eventBus.dispatch("updateListeningState", {isListening: false})
                                //setListeningState(false);

                                let preListeningState = updateSuppressRestart()

                                if (preListeningState) {
                                    setRef(true, final, sendlanguage, null, "user", "", true);
                                } else {
                                    clearEntryText();
                                }
                                turnOffMic();
                            }
                        }

                    } else {
                        if (getListeningState()) {
                            setTextContent(interim)
                        }
                    }
                }
            };
        }
    }, [recognition])





    useEffect(() => {

        console.log("dgResults", dgResults)
        let sendlanguage = setTextContent(dgResults)

        if (micContinuous !== "true") {
            setRecordingState(false);
            dgStopSpeechToText();
            clearEntryText();
        }

        if (evalNonContinuousBase()){
            clearEntryText();
            setRef(true, dgResults, sendlanguage, null,"user", "", true);

        } else {
            //console.log("continuous")
            // MUST TIME THIS OUT AT 45 SECONDS AFTER LAST INTERACTION
            //console.log("set timer here DG")


            if (dgTimeout) {
                clearTimeout(dgTimeout);
            }

            let timeoutId = setTimeout(() => {
                //console.log("Finished at " + new Date())
                turnOffMic();
                setSuppressRestartState(true);
                setListeningState(false);
                eventBus.dispatch("updateListeningState", {isListening: false})
                setIsListening(false);
            }, 20000);

            //console.log("myTimer", timeoutId)
            setDgTimeout(timeoutId)

            if (!getSuppressRestartState()) {
                updateContinuous(sendlanguage)
                setRef(true, dgResults, sendlanguage, null,"user", "", true);
                clearEntryText();

            } else {
                console.log("in suppress restart")
                //let preListeningState = getListeningState();
                //setButtonRecordingState(false);
                //eventBus.dispatch("updateListeningState", {isListening: false})
                //setListeningState(false);
                //setIsListening(false);

                let preListeningState = updateSuppressRestart()
                dgStopSpeechToText();
                if(preListeningState) {
                    setRef(true, dgResults, sendlanguage, null, "user", "", true);
                    clearEntryText();
                } else {
                    clearEntryText();
                }
                turnOffMic();
            }
        }

    }, [dgResults])



    useEffect(() => {
        console.log("result change")

        if (results.length > 0) {
            console.log("Results", results)
            let transcript = results[results.length - 1].transcript;
            let sendlanguage = language;
            sendlanguage = setTextContent(transcript)
            clearEntryText();

            if (micContinuous !== "true") {
                setRecordingState(false);
                stopSpeechToText();
            }

            //if (!getMicContious() && getListeningState()) {
                //console.log("not continuous", transcript, sendlanguage)
                //setSuppressRestartState(true);
                //setListeningState(false);
                //eventBus.dispatch("updateListeningState", {isListening: false})
                //setIsListening(false);

            if (evalNonContinuousBase()){
                clearEntryText();
                setRef(true, transcript, sendlanguage, null,"user", "", true);
            }

            else {
                //console.log("continuous")


                if (googTimeout) {
                    clearTimeout(googTimeout);
                }

                let timeoutId = setTimeout(() => {
                    console.log("Goog Finished at " + new Date())
                    turnOffMic();
                    setSuppressRestartState(true);
                    setListeningState(false);
                    eventBus.dispatch("updateListeningState", {isListening: false})
                    setIsListening(false);
                }, 20000);

                console.log("myTimer", timeoutId)
                setGoogTimeout(timeoutId)

                if (!getSuppressRestartState()) {
                    try {
                        //recognition.start();
                    } catch (e) {
                        console.log("error", e)
                    }

                    updateContinuous(sendlanguage)
                    setRef(true, transcript, sendlanguage, null, "user", "", false);
                } else {
                    console.log("in suppress restart")

                    let preListeningState = updateSuppressRestart()
                    stopSpeechToText();
                    if (preListeningState) {
                        setRef(true, transcript, sendlanguage, null, "user", "", true);
                    } else {
                        clearEntryText();
                    }
                    turnOffMic();
                }
            }

            /*
            THIS CODE IS GOOD
            else {
                console.log("in suppress restart")
                // I AM HERE

                let preListeningState = updateSuppressRestart()

                if (preListeningState) {
                    setRef(true, final, sendlanguage, null, "user", "", true);
                } else {
                    clearEntryText();
                }
                turnOffMic();

            }

             */
        }
    }, [results])




    useEffect(() => {
        if (getListeningState()) { setRecordingState(dgInterimResult); }
    }, [dgInterimResult])




    const turnOffMic = () => {
        //console.log("turnOffMic")
        //eventBus.dispatch("updateListeningState", {isListening: false})
        if (isApple)
        {
            //console.log("is apple")
            //try{ recognition.start(); }
            //catch(err) { }
            //stopSpeechToText(true);
            navigator.mediaDevices.getUserMedia({ audio: true }).then(stream => {
                stream.getTracks().forEach(track => track.stop())
            })

            try {
                dgStopSpeechToText();
            } catch (e) {}

            try {
                stopSpeechToText(true);
            } catch (e) {}

        } else {
            try {
                stopSpeechToText(true);
            } catch (e) {}
            try {
                recognition.stop();
            } catch (e) {}

        }
        setIsListening(false);

    }

    const turnOnMic = (setLang = null) => {
        setSpeakingLanguage(setLang);
        //console.log("turn on ", setLang)
        //recognition.lang = setLang || language;
        console.log("turnOnMic", isApple)
        if (isApple) {

            //localStorage.setItem("micContinuous", "false")
            console.log("STARTING LANGUAGE", setLang || language)
            if (["ne-NP", "am-ET", "so-SO", "fil-PH", "ar-IQ", "ar-SA"].includes(setLang || language)) {

                console.log("STARTING LANGUAGE", setLang || language)
                let selectedLang = setLang || language;
                startSpeechToText(selectedLang);
                setIsListening(true);

            } else {
                let selectedLang = setLang || language;
                dgStartSpeechToText(setLang || language);
                setIsListening(true);
            }

        } else {
            console.log("in Not apple", setLang, language)
            let selectedLang = setLang || language;
            recognition.lang = selectedLang || "en-US";
            recognition.continuous = localStorage.getItem("micContinuous") === "true" || false;
            try {
                recognition.start();
                setIsListening(true);
                //alert(recognition.toString())
            } catch (e) {

            }
        }
    }

    const updRecordingState = (b) => {
        //console.log("updRecordingState", b)
        setButtonRecordingState(b);
        localStorage.setItem("recordingState", b.toString())
        setListeningState(b);
        setRecordingState(b);
    }

    /*

    recognition.onend = () => {
        //console.log("stopped recognizing", getSuppressRestartState(), getListeningState())
        console.log("not recognition", recognition, speakingLanguage)
        if (!getSuppressRestartState() && getListeningState() ) {
        //if (!suppressRestart) {
            console.log("restarting", recognition === null)
            //if (recognition === null) {
                turnOnMic(speakingLanguage);
                setSuppressRestartState(false);
            //}
        }
    }
     */


    const deleteMessage = (messageObj) => {
        //console.log("deleteMessage", messageObj)
        let messageSwap = [];

        let tempMessages = [...messages];
        /*
        if (tempMessages.length === 0) {
         */

            req_get_history(localStorage.getItem("cSessionId"))
                .then(function (result) {
                    let tempFirstLoad = true;
                    if (result.status === 200) {

                        let historyData = result.data.data;
                        let chatHistory = historyData["history"]["exchangeLog"] || [];
                        //setMessagesLoaded(false)
                        chatHistory.forEach((chat) => {

                            if (chat["conveyor"] === "user") {
                                tempMessages.push({sender: "user", ...chat});
                            }
                            if (chat["conveyor"] === "assistant") {
                                tempMessages.push({sender: "assistant", ...chat});
                            }
                        });

                        tempMessages.forEach((message, index) => {
                            //console.log("message", message)
                            let messageClone = message;
                            if (messageObj.messageId === message.initialId) {
                                //console.log("matches")
                                messageClone["isDeleted"] = true;

                            }
                            messageSwap.push(messageClone)
                        });
                        //console.log(messageSwap);
                        //setRefresh(!refresh);
                        setMessages([...messageSwap]);

                    }

                })

            /*
        } else {
            tempMessages.forEach((message, index) => {
                //console.log("message", message)
                let messageClone = message;
                if (messageObj.messageId === message.initialId) {
                    console.log("matches")
                    messageClone["isDeleted"] = true;

                }
                messageSwap.push(messageClone)
            })

            console.log(messageSwap)
            //setRefresh(!refresh);
            setMessages([...messageSwap]);
        }
        */


    }

    const refreshSessionData = (refresh = false) => {
        //console.log("refresh", isApple)

        if (isApple) {
            //dgWebsocket();
            //const recognition = new (window.SpeechRecognition || window.webkitSpeechRecognition || window.mozSpeechRecognition || window.msSpeechRecognition)();
            //setRecognition(recognition)

        } else {
            const recognition = new (window.SpeechRecognition || window.webkitSpeechRecognition || window.mozSpeechRecognition || window.msSpeechRecognition)();
            setRecognition(recognition)
        }

        let tempLang = language;
        let session = localStorage.getItem("cSession") ? JSON.parse(localStorage.getItem("cSession")) : {};
        setChatDetailObj({...session});

        if (session.id === localStorage.getItem("cSessionId")) {
            session.sessionMembers.forEach((member) => {

                if (member.userId === localStorage.getItem("uid")){
                    setLanguage(member.language);
                    tempLang = member.language;

                    if (!chatDetailObj && !session) {
                        console.log("NO OBJECT")
                        let cSession = JSON.parse(localStorage.getItem("cSession"));
                        setChatDetailObj({...cSession});
                        updateSession(localStorage.getItem("cSessionId"), tempLang, cSession);
                    }

                    if (session.sessionType === "shared")
                    {
                        session.languages.forEach((lang) => {
                            if (lang !== member.language) {
                                setOtherLanguage(lang);
                            }
                        })
                    }
                    //updateSession(localStorage.getItem("cSessionId"), tempLang, session);
                }
            })
        }
        if (refresh && localStorage.getItem("cSessionId") !== "") {
            simpleUpdateSession();
            createChatSessionObject(localStorage.getItem("cSessionId"))
        }


    }


    const simpleUpdateSession = () => {
        console.log("simpleUpdateSession")

        let session = localStorage.getItem("cSession") ? JSON.parse(localStorage.getItem("cSession")) : {};
        //console.log("GOT SESSION", session)
        setChatDetailObj({...session});

        if (session.hasOwnProperty("sessionMembers")) {
            setLanguage(session.sessionMembers[0].language);
            setULanguage(session.sessionMembers);

            session.sessionMembers.forEach((member) => {
                if (session.sessionType === "shared") {
                    session.languages.forEach((lang) => {
                        if (lang !== member.language) {
                            setOtherLanguage(lang);
                        }
                    })
                }
            })

        }

        //setLanguage(language);

        loadData(session.id, session.accountId);

    }


    const stopTimer = () => {
        setAudioTimer(false);
        if (currentInterval) {
            clearInterval(currentInterval);
            setCurrentInterval(0);
        }
    };

    const showListening = (uObj) => {
        console.log("showListening", uObj)
        setIsListening(true)

    }



    const startWaitingTimer = () => {
        console.log("startWaitingTimer")
        let interval = setTimeout(() => {
            if (waitingTimerInterval !== 0) {
                eventBus.dispatch("showAlert", {
                    open: true,
                    message: content.chatScreenContent[myLanguage || "en-US"].responseFailure,
                    type: "error",
                    vertical: "bottom",
                    horizontal: "center",
                    timeout: 2000
                })
            }

        }, 10000);
        setWaitingTimerInterval(interval);
    }

    const clearWaitingTimer = () => {
        clearTimeout(waitingTimerInterval);
        setWaitingTimerInterval(0);
    }

    const toggleLeftDrawer = (anchor, open, sessionType) => {
        setDrawerSessionType(sessionType);
        setDrawerOpen(open);
    }


    const toggleSettingsDrawer = (anchor, open) => { setSettingsDrawerOpen(open); }

    const infoPanelToggle = (open) => { setInfoPanelOpen(open); }

    const setQuery = (e) => { setEntryText(e.target.value) }
    const setOtherQuery = (e) => { setOtherEntryText(e.target.value) }

    const onEnterPress = (e) => {
        if(e.keyCode === 13 && e.shiftKey === false) {
            e.preventDefault();
            sendMessage(e)
        }
    }

    const onOtherEnterPress = (e) => {
        if(e.keyCode === 13 && e.shiftKey === false) {
            e.preventDefault();
            sendOtherMessage(e)
        }
    }

    const updateMicSettings = () => {
        setEditMicOpen(false);
        setMicContinuous(localStorage.getItem("micContinuous"));
        setSuppressRestartState(localStorage.getItem("micContinuous") === "true");

        console.log(localStorage.getItem("micContinuous") === "true");
        //let isCont = localStorage.getItem("micContinuous") === "true";

        if (!isApple) {
            recognition.continuous = localStorage.getItem("micContinuous") === "true";
        }
    }


    const updateSession = (sessionId, language, row = null) => {
        console.log("updateSession", sessionId, language, row)

        if (row) {
            setChatDetailObj(row);
        }

        clearSession();
        createChatSession(sessionId);
        createChatSessionUser(localStorage.getItem("uid"));
        req_get_session(sessionId)
            .then(function (result) {
                if (result.status === 200) {
                    setChatDetailObj(result.data.session);

                    setChatSessionId(sessionId);
                    if (row.hasOwnProperty("sessionMembers")) {
                        setULanguage(row.sessionMembers);
                    }
                    setAccountId(row.accountId)
                    setCreateChatOpen(false);
                    setLanguage(language);
                    setDrawerOpen(false);
                    //console.log("before update header2")
                    //eventBus.dispatch("updateHeader", {rerun: false});
                    setFirstLoad(true);
                    loadData(sessionId, row.accountId);

                }
            })

    }

    const setULanguage = (members) => {

        //console.log("setULanguage", members)
        members.forEach((member) => {
            if (member.userId === localStorage.getItem("uid")) {
                setLanguage(member.language);
                setMyLanguage(member.language);
            }
        })
    }

    const clearSession = () => {
        let clearMessages = [];
        setMessages([...clearMessages]);
        setFirstLoad(true);
        setChatSessionId("");
        clearChatSession();
        //clearChatSessionObject();
        clearChatSessionUser();
    }


    const loadData = (sessionId, acctId) => {
        console.log("load data")

        setLoaded(false);
        let newMessages = [];

        if (messages.length === 0) {
            eventBus.dispatch("showLoader", {showLoader: true})
        }

        req_get_history(sessionId)
            .then(function (result) {

                let tempFirstLoad = true;
                if (result.status === 200) {

                    let historyData = result.data.data;
                    let chatHistory = historyData["history"]["exchangeLog"] || [];

                    chatHistory.forEach((chat) => {

                        if (chat["conveyor"] === "assistant") {
                            newMessages.push({sender: "assistant", ...chat});
                        }
                    });

                    //console.log("newMessages", newMessages);
                    if (newMessages.length === 0) {
                        tempFirstLoad = true;
                    }
                    //setFirstLoad(false);

                    let isFirst = true;

                    try {

                        //console.log("listener refs", fbRef.current)
                        if (fbRef.current) {
                            fbRef.current.off("value")
                        }

                        fbRef.current = "/translation/" + acctId + "/" + sessionId + "/c";

                        let newRef = db.ref("/translation/" + acctId + "/" + sessionId + "/c");

                            newRef.on("value", snapshot => {
                            console.log("SNAPSHOT", snapshot.val(), snapshot.exists())
                            if(snapshot.val() !== null) {

                                //console.log("before1")
                                if (snapshot.val().sender !== "user") {

                                    if (snapshot.val().hasOwnProperty("bypass") && snapshot.val().bypass === true) {
                                        setWaiting(false);
                                        clearWaitingTimer();
                                    } else {
                                        if (snapshot.val() && tempFirstLoad) {
                                            if (newMessages.length === 0) {
                                                newMessages.push(snapshot.val());
                                            }
                                            if (snapshot.val().sender !== "user") {
                                                setFirstLoad(false);
                                                setWaiting(false);
                                                clearWaitingTimer();
                                            }
                                        } else {

                                            if (!tempFirstLoad) {
                                                newMessages.push(snapshot.val());
                                                setRefresh(!refresh);
                                            }
                                            setFirstLoad(false);
                                            setWaiting(false);
                                            clearWaitingTimer();
                                        }
                                    }
                                }
                            }

                            setFirstLoad(false);
                            //console.log("newMessages", newMessages);
                            setMessages([...newMessages]);
                            tempFirstLoad = false;
                            if (scrollButtonVisible){
                                setAlertNewMessages(true);
                            }
                        });
                        fbRef.current = newRef;

                        //setLoaded(true);
                        setFirstLoad(false);

                    } catch (error) {
                        console.log("fail", error)
                    }
                }

                //setMessagesLoaded(true)
                setLoaded(true);
                eventBus.dispatch("showLoader", {showLoader: false})
            })
    }






    const Waiter = () => { return (<div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>) }

    const Listener = () => { return (<div className=""><HearingIcon style={{color: "#41a887", margin: 5}} /></div>) }

    const sendMessage = (e) => {
        console.log("send message")
        e.preventDefault();
        if (entryText === "") { return; }
        setIsListening(false);
        setRef(true, entryText, language,null, "user", "", false);
    }

    const sendOtherMessage = (e) => {
        console.log("send other message")
        e.preventDefault();
        if (otherEntryText === "") { return; }
        setRef(true, otherEntryText, otherLanguage, null, "user", "", false);
    }

    const sendFuncMessage = (message, messageType, messageObject) => {
        setRef(true, message, "en-US",null, "userFunc", messageType, messageObject, false);
    }

    const setRef = (createId, message, sendlanguage, messageId, sender, messageType, messageObject={}, stopListening = false) => {
        //console.log("set ref")
        if (createId) { messageId = uuidv4().toString() }
        //console.log("message", message)
        if (message === undefined) {
            console.log("UNDEFINED MESSAGE")

            setUnderstandingError(true);

            setTimeout(() => {
                setUnderstandingError(false);
            }, 5000);

            //eventBus.dispatch("showAlert", { open: true, message: "Sorry, I could not understand. Please repeat that.", type: "error", vertical: "bottom", horizontal: "center", timeout: 2000 })

        }

        if (message !== undefined) {
            db.ref(`translation/${accountId}/${chatSessionId}/c`).set({
                message: message,
                messageId: messageId,
                id: messageId,
                messageType: messageType,
                workflowStageId: ["user", "userFunc"].includes(sender) ? workflowStageId : "",
                sender: sender,
                initialId: "",
                senderId: localStorage.getItem("cSessionUserId"),
                senderDisplayName: localStorage.getItem("displayName"),
                projectId: projectId,
                payload: {audio: isAudioActive, location: location},
                //language: isOtherInvoker ? otherLanguage : language,
                language: sendlanguage,
                sent: new Date().toISOString()
            });

            setEntryText("");
            setOtherEntryText("");
            clearEntryText();
            setWaiting(true);
            console.log("startWaitingTimer")
            startWaitingTimer();
            if (stopListening) {
                //turnOffMic();
            }

        }
        // set timer
    }

    const openChatInvite = () => { setChatInviteOpen(true); }
    const openChatDelete = () => { setChatDeleteOpen(true); }
    const openChatRename = (sessionObj) => {
        setChatRenameOpen(true);
        setChatDetailObj(sessionObj);

    }

    const toggleAutoplay = () => {

        let ap = "true"
        if (localStorage.getItem("autoplay") !== "") {
            ap = "true" ? localStorage.getItem("autoplay") === "false" : "false";
            localStorage.setItem("autoplay", ap)
        }
        setAutoplay(ap);

    }

    const toggleNotifications = () => {
        setNotificationsOpen(!notificationsOpen);
    }

    const scrollToBottom = () => {
        scrollerRef.current.scrollToBottom();
    }

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <div className="homeContentContainer">
            <div style={{display: "flex", flexDirection: "row", gap: 10, marginLeft: "5px", marginRight: "5px"}}>
                { (!isAnonymous) ?
                <div style={{
                    minWidth: 32,
                    borderRadius: "5px",
                    display: isAnonymous ? "none" : "flex",
                    flexDirection: "column",
                    minHeight: "calc(100dvh - 200px)",
                    maxHeight: "calc(100dvh - 200px)",
                    justifyContent: "space-between",

                }}>
                    <LeftMenuTop toggleNotifications={toggleNotifications}
                                 toggleAutoplay={toggleAutoplay}
                                 toggleLeftDrawer={toggleLeftDrawer}
                                 autoplay={autoplay} id={id}
                                 setCreateChatOpen={setCreateChatOpen }
                    />

                    <LeftMenuBottom toggleSettingsDrawer={toggleSettingsDrawer} infoPanelToggle={infoPanelToggle} />

                </div>
                : null
                }

                <div style={{
                    border: "1px solid #e7e7e7",
                    borderRadius: "5px",
                    display: "flex",
                    flexDirection: "column",
                    minWidth: 300,
                    width: "100%"
                }}>

                    {chatSessionId && chatDetailObj !== null ?
                        <>

                            <div style={{
                                textAlign: "left",
                                backgroundColor: "#f7f7f7",
                                padding: "5px",
                                fontWeight: "bold",
                                borderBottom: "1px solid #aaa"
                            }}>
                                <ChatHeader detailObj={chatDetailObj} chatId={chatSessionId} openChatInvite={openChatInvite}
                                            chatName={chatDetailObj.name}
                                            openChatDelete={openChatDelete}
                                            openChatRename={openChatRename}
                                            clearSession={clearSession}
                                            spur={sessionSpur}
                                            isAnonymous={isAnonymous}
                                            toggleAutoplay={toggleAutoplay}
                                            autoplay={autoplay}
                                />
                            </div>
                            <div style={{minHeight: "calc(100dvh - 170px)", maxHeight: "calc(100dvh - 170px)", position: "relative"}}>
                                <div>
                                    {chatDetailObj.sessionType !== "translate" ?
                                        <ChangeChatLanguage sessionType={chatDetailObj.sessionType} myLanguage={myLanguage} secondaryLanguage={otherLanguage} sessionCode={chatDetailObj.inviteId} sessionId={chatDetailObj.id} open={changeLanguageOpen} updateSession={updateSession} close={() => {setChangeLanguageOpen(false)}} />
                                        : null
                                    }

                                    {
                                        localStorage.getItem("isApple") !== "truex" ?
                                        <div className={"micSelectorBox"} style={{
                                        left: chatDetailObj.sessionType !== "translate" ? "195px": 0,
                                    }}> <IconButton

                                        onClick={() => {
                                        setEditMicOpen(true)
                                    }}
                                        >
                                        <SettingsVoiceIcon style={{fontSize: "12px"}} />
                                        </IconButton></div>
                                        :null
                                    }

                                </div>


                                {scrollButtonVisible ?
                                    <div
                                        className={"scrollButtonBox pulse"}
                                        style={{
                                            animation: alertNewMessages ? "pulse 2s infinite" : "none",
                                    }}><IconButton onClick={() => {
                                        setAlertNewMessages(false);
                                        scrollerRef.current.scrollToBottom();
                                    }}>
                                        <ExpandCircleDownIcon style={{fontSize: "16px", color: "#fff"}}/>
                                    </IconButton></div> : null
                                }

                                {isListening ?
                                    <div style={{
                                        border: "1px solid #41a887",
                                        borderRadius: "10px",
                                        marginBottom: 0,
                                        left: 0,
                                        right: 0,
                                        marginLeft: "auto",
                                        marginRight: "auto",
                                        textAlign: "center",
                                        position: "absolute",
                                        bottom: 0,

                                        zIndex: 2000,
                                        minWidth: "250px",
                                        maxWidth: "350px",
                                        backgroundColor: "rgba(213, 244, 213, 0.7)"
                                    }}>
                                        <Listener key={"waiterDisplay"}/>
                                    </div> : null
                                }

                                {messages.length === 0 ?
                                    <div style={{textAlign: "center", margin: "20px", border: "1px solid #41a887", fontSize: "1rem", padding: "10px", borderRadius: "5px"}}>

                                        {!loaded ?
                                            <div>loading...</div>
                                            :
                                            <>
                                                {chatDetailObj.sessionType === "conversation" ?
                                                    <>
                                                        {content.chatScreenContent[myLanguage || "en-US"].noMessagesGroup}
                                                        <IconButton style={{marginLeft: "10px", fontWeight: "bold"}}
                                                                    onClick={() => {
                                                                        openChatInvite(chatDetailObj)
                                                                    }}>
                                                            <GroupAddIcon style={{fontSize: "30px", color: "#41a887"}}/>
                                                        </IconButton>
                                                    </> : null
                                                }

                                                {chatDetailObj.sessionType === "translate" ?
                                                    <>
                                                        {content.chatScreenContent[myLanguage || "en-US"].noMessagesTranslation}
                                                    </> : null
                                                }
                                                {chatDetailObj.sessionType === "quickConnect" ?
                                                    <>
                                                        {content.chatScreenContent[myLanguage || "en-US"].noMessagesQuickConnect}
                                                    </> : null
                                                }
                                                {chatDetailObj.sessionType === "shared" ?
                                                    <>
                                                        {content.chatScreenContent[myLanguage || "en-US"].noMessagesQuickConnect}
                                                    </> : null
                                                }

                                            </>
                                        }

                                    </div>
                                    :
                                    <ScrollableFeed
                                        ref={scrollerRef}
                                        id="output"
                                        className={" chat_scroller "}
                                        onScroll={(atBottom) => {
                                            setScrollButtonVisible(!atBottom)
                                            setAlertNewMessages(false)
                                        }}
                                    >
                                        <div className={"inner-chat"} id={"inner-chat"}>
                                            <MessageHistory key={"messageHistory"}
                                                            messages={messages}
                                                            detailObj={chatDetailObj}
                                                            interfaceObject={{}}
                                                            firstLoad={firstLoad}
                                                            refresh={refresh}
                                                            myLanguage={myLanguage}
                                            />
                                            {waiting ?
                                                <div style={{
                                                    borderRadius: "10px",
                                                    marginBottom: "5px",
                                                    marginLeft: "100px",
                                                    marginRight: "100px",
                                                    textAlign: "center"
                                                }}>
                                                    <Waiter key={"waiterDisplay"}/>
                                                </div> : null
                                            }


                                            {understandingError ?
                                                <div style={{
                                                    borderRadius: "10px",
                                                    marginBottom: "5px",
                                                    marginLeft: "100px",
                                                    marginRight: "100px",
                                                    textAlign: "center",
                                                    borderColor: "#ddd",
                                                    backgroundColor: "#f7f7f7",
                                                    color: "red",
                                                    fontWeight: "500"
                                                }}>
                                                    {content.chatScreenContent[myLanguage || "en-US"].couldNotUnderstand}
                                                </div> : null
                                            }

                                            <div
                                                style={{clear: "both"}}
                                                id={"bottomRef"}/>
                                        </div>
                                        <div ref={messagesEndRef} style={{height: 0}}/>
                                    </ScrollableFeed>
                                }
                            </div>
                            <BottomNavigation>

                                <ChatSubmissionContainer
                                    sendMessage={sendMessage}
                                    onEnterPress={onEnterPress}
                                    onOtherEnterPress={onOtherEnterPress}
                                    entryText={entryText}
                                    otherEntryText={otherEntryText}
                                    setQuery={setQuery}
                                    setOtherQuery={setOtherQuery}
                                    setSpeakingLanguage={setSpeakingLanguage}
                                    turnOnMic={turnOnMic}
                                    turnOffMic={turnOffMic}
                                    updRecordingState={updRecordingState}
                                    micContinuous={micContinuous}
                                    activeSpeaking={activeSpeaking}
                                    sendOtherMessage={sendOtherMessage}
                                    buttonRecordingState={buttonRecordingState}


                                />

                            </BottomNavigation>
                        </> :
                            <DashboardContent
                                toggleLeftDrawer={toggleLeftDrawer}
                                setCreateChatOpen={setCreateChatOpen}
                                setQuickConnectOpen={setQuickConnectOpen}
                                setJoinChatOpen={setJoinChatOpen}

                            />
                    }
                </div>

                <Drawer
                    PaperProps={{
                        sx: { maxWidth: "400px", width: "100%", maxHeight: "100%", marginTop: "46px", textAlign: "left"},
                    }}
                    anchor={"right"}
                    open={settingsDrawerOpen}
                    onClose={() => {toggleSettingsDrawer("right", false)}}
                >
                    <SettingsPanel toggleSettingsDrawer={toggleSettingsDrawer} />
                </Drawer>

            </div>



            {editMicOpen ? <ListeningOptions open={editMicOpen} updateSession={updateSession} close={() => {updateMicSettings()}} /> : null }

            {createChatOpen ? <CreateChat preselect={chatPreselect} open={createChatOpen} updateSession={updateSession} close={() => {setCreateChatOpen(false)}} /> : null }

            {quickConnectOpen ? <QuickConnect preselect={chatPreselect} open={quickConnectOpen} updateSession={updateSession} close={() => {setQuickConnectOpen(false)}} /> : null }

            {joinChatOpen ? <JoinChat open={joinChatOpen} updateSession={updateSession} close={() => {setJoinChatOpen(false)}} /> : null }


            {chatSessionId !== "" ?
            <>
            {chatInviteOpen ? <InviteMember open={chatInviteOpen} chatObj={chatDetailObj} updateSession={updateSession} close={() => {setChatInviteOpen(false)}} /> : null }

            {chatDeleteOpen ? <ChatDelete open={chatDeleteOpen} chatObj={chatDetailObj} clearSession={clearSession} close={() => {setChatDeleteOpen(false)}} /> : null }

            {chatRenameOpen ? <EditChatName open={chatRenameOpen} chatObj={chatDetailObj} clearSession={clearSession} close={() => {
                    setChatRenameOpen(false);
            }} /> : null }
            </> : null
            }

            {infoPanelOpen ? <SiteInfo open={infoPanelOpen} close={() => {setInfoPanelOpen(false)}} /> : null }


            <Drawer
                PaperProps={{
                    sx: { maxWidth: "400px", width: "100%", height: "500px", maxHeight: "100%", marginTop: "45px", borderRadius: "3px"},
                }}
                anchor={"left"}
                open={notificationsOpen}
                onClose={() => {toggleNotifications()}}
            >
                <div style={{padding: "5px"}}>
                        <Typography variant="h5">{content.chatScreenContent[localStorage.getItem("siteLanguage") || "en-US"].notificationsModalHeader}</Typography>
                </div>
                <DisplayNotifications />
            </Drawer>

            <Drawer
                PaperProps={{
                    sx: { maxWidth: "400px", width: "100%", height: "500px", maxHeight: "100%", marginTop: "45px", borderRadius: "3px"},
                }}
                anchor={"left"}
                open={drawerOpen}
                onClose={() => {toggleLeftDrawer("left", false, "")}}
            >
                <div style={{padding: "5px"}}>

                    {drawerSessionType === "conversation" ?
                        <Typography variant="h5">{content.chatScreenContent[localStorage.getItem("siteLanguage") || "en-US"].conversationModalHeader} <IconButton aria-describedby={id} variant="contained" onClick={() => {
                            setChatPreselect("conversation")
                            setCreateChatOpen(true)

                        }}>
                            <AddCircleIcon style={{color: "#444"}} />
                        </IconButton>
                        </Typography> :
                        null
                    }
                    {drawerSessionType === "translate" ?
                        <Typography variant="h5">{content.chatScreenContent[localStorage.getItem("siteLanguage") || "en-US"].translateModalHeader} <IconButton aria-describedby={id} variant="contained" onClick={() => {
                            setChatPreselect("translate")
                            setCreateChatOpen(true)

                        }}>
                            <AddCircleIcon style={{color: "#444"}} />
                        </IconButton> </Typography> :
                        null
                    }
                    {drawerSessionType === "shared" ?
                        <Typography variant="h5">{content.chatScreenContent[localStorage.getItem("siteLanguage") || "en-US"].sharedModalHeader} <IconButton aria-describedby={id} variant="contained" onClick={() => {
                            setChatPreselect("shared")
                            setCreateChatOpen(true)

                        }}>
                            <AddCircleIcon style={{color: "#444"}} />
                        </IconButton></Typography>:
                        null
                    }
                    {drawerSessionType === "quickConnect" ?
                        <Typography variant="h5">{content.chatScreenContent[localStorage.getItem("siteLanguage") || "en-US"].quickConnectModalHeader}
                            <IconButton aria-describedby={id} variant="contained" onClick={() => {
                                setChatPreselect("quickConnect")
                                setCreateChatOpen(true)

                            }}>
                                <AddCircleIcon style={{color: "#444"}} />
                            </IconButton>
                        </Typography> :
                        null
                    }
                </div>
                <SessionSelector sessionType={drawerSessionType} />
            </Drawer>

        </div>
    )
}
