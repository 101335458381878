import React, {Component} from "react";
import {
    Button, Dialog,
    DialogActions,
    DialogContent, DialogContentText,
    DialogTitle,
    TextField
} from "@mui/material";
import {content} from "../../globals/content";
import {req_put_update_session_name} from "../../helpers/requests";
import eventBus from "../../helpers/eventBus";
import {createChatSessionObject} from "../../helpers/session";


export default class EditChatName extends Component {

    state = {
        updName: this.props.chatObj.name || ""
    }

    handleClose = () => {
        this.props.close()
    }

    updateName = () => {
        let self = this;
        console.log("do name update", this.state.updName)
        let body = {
            name: this.state.updName
        }
        //console.log(body)
        req_put_update_session_name(body, this.props.chatObj.id).then(function (result) {
            if (result.status === 200) {
                console.log("name updated")
                createChatSessionObject(self.props.chatObj.id)
                eventBus.dispatch("updateSessions", {});

                self.props.close()

            } else {

            }
        })
    }

    render() {
        //console.log(this.props)
        return (<Dialog
            open={this.props.open}
            onClose={this.handleClose}
            PaperProps={{
                component: 'form',
                onSubmit: (event) => {
                    event.preventDefault();
                    this.handleClose();
                },
            }}
        >
            <DialogTitle>{content.chatNameEditScreenContent[localStorage.getItem("siteLanguage") || "en-US"].title}</DialogTitle>
            <DialogContent style={{padding: "10px"}}>
                <DialogContentText>
                    {content.chatNameEditScreenContent[localStorage.getItem("siteLanguage") || "en-US"].description}
                </DialogContentText>
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="chatName"
                    value={this.state.updName}
                    label={content.chatNameEditScreenContent[localStorage.getItem("siteLanguage") || "en-US"].chatNameField}
                    name="chatName"
                    autoFocus
                    onChange={(e) => this.setState({updName: e.target.value})}

                />
            </DialogContent>
            <DialogActions>
                <Button variant={"outlined"} onClick={this.handleClose}>{content.chatNameEditScreenContent[localStorage.getItem("siteLanguage") || "en-US"].cancelButtonText}</Button>
                <Button variant={"outlined"} onClick={this.updateName}>{content.chatNameEditScreenContent[localStorage.getItem("siteLanguage") || "en-US"].saveButtonText}</Button>
            </DialogActions>
        </Dialog>)
    }

}
