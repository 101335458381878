import React, {Component} from "react";
import {IconButton, ListItemIcon, MenuItem} from "@mui/material";
import {clearLogout} from "../../helpers/settings";
import {clearChatSession, clearChatSessionObject, clearChatSessionUser, clearNotifications} from "../../helpers/session";
import LogoutIcon from '@mui/icons-material/Logout';
import SettingsIcon from "@mui/icons-material/Settings";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import PersonIcon from '@mui/icons-material/Person';
import Menu from '@mui/material/Menu';
import LanguageSelector from "../embeddedSelectors/languageSelector";
import {content} from "../../globals/content";
import Badge from "@mui/material/Badge";
import NotificationsIcon from "@mui/icons-material/Notifications";
import Divider from "@mui/material/Divider";
import eventBus from "../../helpers/eventBus";
import {req_get_notifications, req_get_updView} from "../../helpers/requests";

export default class UserWidgetsTopRight extends Component {

    state = {
        open: false,
        anchorEl: null,
        redirect: false,
        notificationsOpen: false,
        accounts: [],
        notifications: [],
        notificationCount: 0,
        isAnonymous: localStorage.getItem("wstt") === "68245"
    };

    logOut() {
        clearLogout();
        clearChatSession();
        clearChatSessionUser();
        clearChatSessionObject();
        clearNotifications();
        window.location = '/';
    }

    handleMenuClick = (event) => {
        event.preventDefault();
        this.setState({ anchorEl: event.currentTarget });
    };

    handleMenuClose = () => { this.setState({ anchorEl: null }); };


    loadNotificationsFromLocalStorage = () => {
        this.setState({loading: true})
        let notifications = localStorage.getItem("notifications") || "[]";
        notifications = JSON.parse(notifications)



        if (notifications.length === 0) {

            this.setState({notificationCount: 0})
        } else {

            const count = notifications.reduce((total, notification) => {
                if (notification.status === 0) {
                    return total + 1;
                } else {
                    return total;
                }
            }, 0);

            this.setState({notificationCount: count})

        }
    }


    showNotificationsPanel = () => {

        let self = this;

        this.setState({notificationCount: 0})
        eventBus.dispatch("showNotifications", {})

        req_get_updView()
            .then((response) => {
                if (response.status === 200) {

                }
            })
    }


    componentDidMount() {

        eventBus.on("updateNotifications",
            (uObj) => {
                this.loadNotificationsFromLocalStorage()
            });
        eventBus.remove("updateNotifications")
        this.loadNotificationsFromLocalStorage()
    }


        render() {
        return (
            <div style={{display: "flex", marginRight: "10px"}}>

                <div style={{display: localStorage.getItem("rtoken") ? "none" : ""}}>
                <LanguageSelector context={"siteLanguage"} setLanguage={this.setLanguage} size={"compact"} />
                </div>
                {(!localStorage.getItem("rtoken") || localStorage.getItem("rtoken") === "undefined") ?
                    null :

                    <div style={{display: "flex", marginLeft: "10px"}}>

                        <div style={{}}>
                            <IconButton aria-describedby={"tm_1"} variant="contained"
                                        onClick={() => {this.showNotificationsPanel()}}
                            >

                                {this.state.notificationCount > 0 ?
                                    <Badge color="secondary" badgeContent={this.state.notificationCount}>
                                        <NotificationsIcon style={{color: "#94a9c3"}}/>
                                    </Badge> : <NotificationsIcon />
                                }
                            </IconButton>
                        </div>

                            <div style={{}}><IconButton

                                aria-controls={this.state.open ? 'account-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={Boolean(this.state.anchorEl) ? 'true' : undefined}
                                onClick={this.handleMenuClick}
                            ><PersonIcon /></IconButton></div>


                            <Menu
                                anchorEl={this.state.anchorEl}
                                id="account-menu"
                                open={Boolean(this.state.anchorEl)}
                                onClose={this.handleMenuClose}
                                onClick={this.handleMenuClose}
                                PaperProps={{
                                    elevation: 0,
                                    sx: {
                                        overflow: 'visible',
                                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                        mt: 1.5,
                                        '& .MuiAvatar-root': {
                                            width: 32,
                                            height: 32,
                                            ml: -0.5,
                                            mr: 1,
                                        },
                                        '&:before': {
                                            content: '""',
                                            display: 'block',
                                            position: 'absolute',
                                            top: 0,
                                            right: 14,
                                            width: 10,
                                            height: 10,
                                            bgcolor: 'background.paper',
                                            transform: 'translateY(-50%) rotate(45deg)',
                                            zIndex: 0,
                                        },
                                    },
                                }}
                                transformOrigin={{horizontal: 'right', vertical: 'top'}}
                                anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
                            >

                                {(!this.state.isAnonymous) ?

                                    <MenuItem key={"menu_account"} onClick={() => {
                                        eventBus.dispatch("showAccountPanel", {})
                                    }}>
                                        <ListItemIcon><ManageAccountsIcon/></ListItemIcon>{content.userWidgetsTopRight[localStorage.getItem("siteLanguage") || "en-US"].accountDescription}
                                    </MenuItem> : null
                                }

                                {(!this.state.isAnonymous) ?
                                    <MenuItem key={"menu_settings"} onClick={() => {
                                        eventBus.dispatch("showSettingsPanel", {})
                                    }}>
                                        <ListItemIcon><SettingsIcon/></ListItemIcon>{content.userWidgetsTopRight[localStorage.getItem("siteLanguage") || "en-US"].settingsDescription}
                                    </MenuItem> : null
                                }



                                <Divider />
                                <MenuItem  key={"menu_logout"}
                                    onClick={this.logOut}><ListItemIcon><LogoutIcon/></ListItemIcon>{content.userWidgetsTopRight[localStorage.getItem("siteLanguage") || "en-US"].logoutButtonText}
                                </MenuItem>

                            </Menu>
                        </div>
                        }
                    </div>

                    )
                }
}
