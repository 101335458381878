import React, {Component} from "react";
import {
    Dialog, DialogContent,
    DialogTitle, IconButton,
} from "@mui/material";

import {content} from "../../globals/content";
import CloseIcon from "@mui/icons-material/Close";
import {NotificationCard} from "../widgets/notificationCard";
import {req_get_all_sessions, req_get_notifications} from "../../helpers/requests";
import eventBus from "../../helpers/eventBus";
import {loadNotifications} from "../../helpers/session";

export default class Notifications extends Component {

    state = {
        open: true,
        loading: true,
        notificationsArray: []
    };


    handleClose = () => {
        this.setState({open: false});
        this.props.close()
    }



    loadNotificationsFromLocalStorage = () => {
        this.setState({loading: true})
        let self = this;

        //let notifications = JSON.parse(localStorage.getItem("notifications")) || [];
        let notifications = localStorage.getItem("notifications") || "[]";
        notifications = JSON.parse(notifications)
        this.setState({notificationsArray: notifications})

        if (this.state.notificationsArray.length === 0) {
            this.loadNotificationsFromDatabase()
        } else {
            this.setState({loading: false})
        }

    }


    loadNotificationsFromDatabase = () => {
        let self = this;
        this.setState({loading: true})
        loadNotifications(false).then((response) => {
            self.setState({loading: false})
        });
    }

    refreshNotifications = () => {
        console.log("refreshing notifications")
        let notifications = JSON.parse(localStorage.getItem("notifications")) || [];
        //console.log("notifications", notifications)

        this.setState({notificationsArray: notifications, loading: false})
    }

    componentDidMount() {

        eventBus.on("updateNotifications",
            (uObj) => {
                this.refreshNotifications(uObj)
            });
        eventBus.remove("updateNotifications")
        this.loadNotificationsFromLocalStorage()
    }

    setConversationInvoke = (ctype) => {
        this.setState({conversationType: ctype});
    }

    render() {

        return (
            <Dialog
                fullWidth={true}
                maxWidth={"sm"}
                scroll="paper"
                disablePortal={true}
                fullScreen
                PaperProps={{ sx: { verticalAlign: "top", maxWidth: 600 } }}
                style={{minWidth: 340, margin: "45px 0 10px 0"}}
                open={this.props.open}
                onClose={this.handleClose}

            >
                <DialogTitle style={{textAlign: "left"}}>{content.notificationsPanel[localStorage.getItem("siteLanguage") || "en-US"].title}
                    <IconButton
                        aria-label="close"
                        onClick={this.handleClose}
                        sx={(theme) => ({
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: theme.palette.grey[500],
                        })}
                    >
                        <CloseIcon />
                    </IconButton>

                </DialogTitle>
                <DialogContent style={{padding: "10px"}}>

                    {!this.state.loading ?
                        <div style={{
                            display: "flex",
                            flexDirection: "column",
                            flexWrap: "wrap",
                            gap: 10,
                            justifyContent: "space-around"
                        }}>

                            {this.state.notificationsArray.map((notification, i) => (
                                <NotificationCard key={i}
                                                  notificationType={notification.notification_type}
                                                  setSessionType={this.setConversationInvoke}
                                                  notificationObj={notification}
                                                  close={this.handleClose}
                                />
                            ))
                            }
                        </div> :
                        <div style={{
                            display: "flex",
                            flexDirection: "column",
                            flexWrap: "wrap",
                            gap: 10,
                            justifyContent: "space-around"
                        }}>
                            loading...
                        </div>
                    }

                    {!this.state.loading && this.state.notificationsArray.length === 0 ?
                        <div style={{textAlign: "center", fontSize: ".8rem", color: "#333", border: "1px solid #ddd", padding: 10, borderRadius: 5}}>
                            {content.notificationsPanel[localStorage.getItem("siteLanguage") || "en-US"].noNotifications}
                        </div>
                        : null
                    }


                </DialogContent>

            </Dialog>
        );
    }
}
