import React, {Component} from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    TextField
} from "@mui/material";
import CopyAllIcon from '@mui/icons-material/CopyAll';

import Link from "@mui/material/Link";
import {content} from "../../globals/content";
import QRCode from "react-qr-code";

export default class InviteMember extends Component {

    state = {
        open: true,
        conversationType: "",
        sessionCode: "",
        language: "",
        peerLanguage: "",
        loading: false,
        chatSessionId: "",
    };

    handleClose = () => {
        this.setState({open: false});
        this.props.close()
    }

    handleCopy = () => {
        console.log("copy")

        if (this.props.chatObj.sessionType === "conversation") {
            navigator.clipboard.writeText("https://earthlingual.com/i/" + this.props.chatObj.inviteId);
        }

        if (this.props.chatObj.sessionType === "quickConnect") {
            navigator.clipboard.writeText("https://earthlingual.com/qc/" + this.props.chatObj.inviteId);
        }

    }


    render() {
        console.log(this.props.chatObj.inviteId)
        return (
            <Dialog
                open={this.props.open}
                onClose={this.handleClose}
                PaperProps={{
                    component: 'form',
                    onSubmit: (event) => {
                        event.preventDefault();
                        const formData = new FormData(event.currentTarget);
                        const formJson = Object.fromEntries(formData.entries());
                        const email = formJson.email;
                        console.log(email);
                        this.handleClose();
                    },
                }}
            >
                <DialogTitle>{content.chatInvitationPanel[localStorage.getItem("siteLanguage") || "en-US"].title}</DialogTitle>
                <DialogContent style={{padding: "10px"}}>
                    <DialogContentText>
                        {content.chatInvitationPanel[localStorage.getItem("siteLanguage") || "en-US"].copyInstructions}
                    </DialogContentText>

                    {this.props.chatObj.sessionType === "conversation" ?
                        <div style={{fontSize: ".8rem", marginTop: "15px"}}>
                            <div style={{textAlign: "center"}}>
                                <QRCode value={"https://earthlingual.com/i/" + this.props.chatObj.inviteId}/>
                            </div>
                            <b>{content.chatInvitationPanel[localStorage.getItem("siteLanguage") || "en-US"].chatId}:</b> {this.props.chatObj.inviteId}<br/>
                            <b>{content.chatInvitationPanel[localStorage.getItem("siteLanguage") || "en-US"].link}:</b>
                            <Link
                                onClick={this.handleCopy}>https://earthlingual.com/i/{this.props.chatObj.inviteId}</Link>
                            <IconButton onClick={this.handleCopy}><CopyAllIcon/></IconButton>
                        </div>
                        : null
                    }

                    {this.props.chatObj.sessionType === "quickConnect" ?
                        <div style={{fontSize: ".8rem", marginTop: "15px"}}>

                            <div style={{textAlign: "center"}}>
                                <QRCode value={"https://earthlingual.com/qc/" + this.props.chatObj.inviteId}/>
                            </div>
                            <b>{content.chatInvitationPanel[localStorage.getItem("siteLanguage") || "en-US"].link}:</b> <Link onClick={this.handleCopy}>https://earthlingual.com/qc/{this.props.chatObj.inviteId}</Link>
                            <IconButton onClick={this.handleCopy}><CopyAllIcon /></IconButton>
                        </div>
                        : null
                    }


                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleClose} variant={"outlined"}>{content.chatInvitationPanel[localStorage.getItem("siteLanguage") || "en-US"].closeButtonText}</Button>
                    <Button style={{display: "none"}} onClick={this.createSession}>Send</Button>
                </DialogActions>
            </Dialog>
        );
    }
}
