import React, {Component} from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField} from "@mui/material";

import Link from "@mui/material/Link";
import {req_delete_avatar, req_delete_session} from "../../helpers/requests";
import eventBus from "../../helpers/eventBus";
import {content} from "../../globals/content";

export default class DeleteAvatarImage extends Component {

    state = {
        open: true,
        conversationType: "",
        sessionCode: "",
        language: "",
        peerLanguage: "",
        loading: false,
        chatSessionId: "",
    };

    handleClose = () => {
        this.setState({open: false});
        this.props.close()
    }

    deleteAvatar = () => {
        console.log("delete avatar")
        eventBus.dispatch("showLoader", {showLoader: true})

        req_delete_avatar().then((result) => {
            if (result.status === 200) {
                eventBus.dispatch("updateSessions", {});
                this.props.resetData();
                this.handleClose();
                eventBus.dispatch("showLoader", {showLoader: false})
            } else {
                this.handleClose();
                eventBus.dispatch("showLoader", {showLoader: false})
            }
        })
    }

    render() {
        return (
            <Dialog
                open={this.props.open}
                onClose={this.handleClose}

            >
                <DialogTitle>{content.avatarDeletePanel[localStorage.getItem("siteLanguage") || "en-US"].title}</DialogTitle>
                <DialogContent style={{padding: "10px"}}>
                    <DialogContentText>
                        {content.avatarDeletePanel[localStorage.getItem("siteLanguage") || "en-US"].textContent}
                    </DialogContentText>



                </DialogContent>
                <DialogActions>
                    <Button variant={"outlined"} onClick={this.handleClose}>{content.avatarDeletePanel[localStorage.getItem("siteLanguage") || "en-US"].cancelButtonText}</Button>
                    <Button variant={"outlined"} onClick={this.deleteAvatar}>{content.avatarDeletePanel[localStorage.getItem("siteLanguage") || "en-US"].deleteButtonText}</Button>
                </DialogActions>
            </Dialog>
        );
    }
}
