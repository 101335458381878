import React, {useEffect, useState} from 'react';
import './home.css';

import {
    clearChatSession,
    clearChatSessionObject,
    clearChatSessionUser,
    createChatSession,
    createChatSessionUser,
    createSelectedAccount
} from "../../helpers/session";
import {
    req_get_display_name_lookup,
    req_join_session,
    req_post_display_name, req_user_account_get,
    req_get_all_sessions
} from "../../helpers/requests";
import LoginConsole from "../login/loginConsole";
import Chat from "./chat";

import BackdropAppear from "../staticDisplays/backdropAppear";
import AlertAppear from "../staticDisplays/alertAppear";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import {content} from "../../globals/content";
import eventBus from "../../helpers/eventBus";
import Listener from "../../helpers/listener";
import AccountPanel from "../dialogs/accountPanel";
import Drawer from "@mui/material/Drawer";
import SettingsPanel from "../dialogs/settingsPanel";
import Notifications from "../dialogs/notifications";


export default function Home() {
    const [language, setLanguage] = useState("");
    const [loading, setLoading] = useState(false);
    const [chatSessionId, setChatSessionId] = useState(localStorage.getItem("cSessionId"));
    const [location, setLocation] = React.useState({latitude: "", longitude: ""});
    const [conversationType, setConversationType] = React.useState("");
    const [displayNameValue, setDisplayNameValue] = React.useState("");
    const [displayNameError, setDisplayNameError] = React.useState(false);
    const [sessionSpur, setSessionSpur] = React.useState(true);
    const [alertOpen, setAlertOpen] = React.useState(false);
    const [alertObject, setAlertObject] = React.useState({open: false, message: "", type: "", vertical: "", horizontal: "", timeout: 5000});
    const [accountDrawerOpen, setAccountDrawerOpen] = React.useState(false);
    const [settingsDrawerOpen, setSettingsDrawerOpen] = React.useState(false);

    const [notificationsOpen, setNotificationsOpen] = React.useState(false);


    const updateSession = () => {
        if (localStorage.getItem("cSessionId") && localStorage.getItem("cSessionId") !== "undefined") {
            setChatSessionId(localStorage.getItem("cSessionId"));
        } else {
            setChatSessionId(undefined);
        }
    }

    const handleSubmit = (event) => {
        event.preventDefault();


        if (!displayNameError) {
            setDisplayNameError(false);
            setLoading(true);

            let body = {
                displayName: displayNameValue,
            }

            req_post_display_name(body)
                .then(function (result) {
                    if (result.status === 200) {
                        req_user_account_get(localStorage.getItem("uid")).then(function (result) {
                            if (result.status === 200) {
                                localStorage.setItem("displayName", result.data.data.displayName ? result.data.data.displayName : "");
                                window.location = '/';
                            } else {
                                //self.setState({loading: false, alert:{...{ "open": true, "message": GENERIC_LOAD_ERROR, "type": "error", "timeout": 5000 }}});
                            }
                        })
                        setLoading(false)
                        // redirect to home

                    } else {
                        //setShowError(true);
                        setLoading(false)
                    }
                })
        } else {
            setDisplayNameError(true);
            setLoading(false)
        }
    };



    const loadSessions = async () => {
        req_get_all_sessions()
            .then(function (result) {
                if (result.status === 200) {
                    localStorage.setItem("chatSessions", JSON.stringify(result.data.sessions));
                    //eventBus.dispatch("triggerChatRefresh", {});
                    //console.log("before updateHeader in home")
                    //eventBus.dispatch("updateHeader", {rerun: false});
                    //eventBus.dispatch("updateLanguages", {rerun: false});
                    //eventBus.dispatch("updateSubmissionContainer", {rerun: false});
                } else {
                    JSON.stringify([])
                    setSessionSpur(!sessionSpur);
                }
            })
    }



    const loadNotifications = () => {}

    useEffect(() => {
        //console.log("load all data")
        if (
            localStorage.getItem("rtoken") &&  localStorage.getItem("rtoken") !== "undefined" &&
            localStorage.getItem("uid") && localStorage.getItem("uid") !== "undefined"
        ) {
            //loadSessions();
            loadNotifications();
            eventBus.on("showAccountPanel", () => {
                setAccountDrawerOpen(true);
            })

            eventBus.on("showSettingsPanel", () => {
                setSettingsDrawerOpen(true);
            })

            eventBus.on("showNotifications", () => {
                setNotificationsOpen(true);
            })

        }
    }, []);

    useEffect(() => {

        eventBus.on("updateSessions", (uObj) => {
            console.log("bus update sessions")
            loadSessions();


        });

        eventBus.on("showLoader", (uObj) => {
            setLoading( uObj.showLoader );
        });

        eventBus.on("showAlert", (uObj) => {
            console.log("in show alert")
            setAlertObject(uObj)
            setAlertOpen( true );
        });


    }, [localStorage.getItem("siteLanguage")]);


    const toggleAccountDrawer = (anchor, open) => { setAccountDrawerOpen(open); }
    const toggleSettingsDrawer = (anchor, open) => { setSettingsDrawerOpen(open); }

    const clearSession = () => {
        setConversationType("");
        clearChatSession();
        //clearChatSessionObject();
        clearChatSessionUser();
        updateSession();
    }

    const confirmAvailability = (handle) => {
        req_get_display_name_lookup(handle).then(function (result) {
            if (result.status === 200) {
                if (result.data.inUse > 0){
                    setDisplayNameError(true);
                } else {
                    setDisplayNameError(false);
                }
            }
        })
    }

    const purgeAlert = () => {
        console.log("in purge")
        setAlertOpen(false);
        setAlertObject({ open: false, type: "info", message: "", horizontal: "", vertical: "" })
    }

    return (
    <div className="home">
        <BackdropAppear loading={loading || false} />
        <AlertAppear open={alertOpen} alertObj={alertObject} purgeAlert={purgeAlert}/>

        {(!localStorage.getItem("rtoken") || localStorage.getItem("rtoken") === "undefined"
            || !localStorage.getItem("uid") || localStorage.getItem("uid") === "undefined"
        ) ?
            <div>
                <div style={{marginTop: 20}}><b>{content.homeScreenContent[localStorage.getItem("siteLanguage") || "en-US"].subTitle}</b></div>
                <LoginConsole selectLogin={true}/>
            </div>:
            <>
            { !localStorage.getItem("displayName")  ?
            <div style={{marginTop: "30px"}}>
                <div style={{margin: "0 auto", width: 300}}>
                    <Typography variant="h3" >One last thing!</Typography><br />
                    Create a display name. This will be what others
                see when you chat with them and cannot be changed, so choose carefully.
                </div>

                <Box component="form" onSubmit={handleSubmit} validate sx={{ mt: 1 }}>

                    <div style={{width: 300, margin: "0 auto"}}>

                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="displayName"
                            label="Display Name"
                            id="displayName"
                            autoComplete="off"
                            error={displayNameError}
                            helperText={(displayNameError ? " The selected handle is taken or invalid." : "")}
                            onChange={(e) => {
                                let newDisplayName = e.target.value.toLowerCase()
                                    .replace(/ /g, '-')
                                    .replace(/[^\w-]+/g, '');
                                setDisplayNameValue(newDisplayName)
                                confirmAvailability(newDisplayName)}
                            }
                        />

                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 2, mb: 2, backgroundColor: "#424242" }}
                        >
                            Set Display Name
                        </Button>
                    </div>

                </Box>

            </div> :
            <Chat selLang={language}
            conversationType={conversationType}
            chatSessionId={chatSessionId}
            clearSession={clearSession}
            updSession={sessionSpur}/>
            }
            </>
        }

        {notificationsOpen ?
            <Notifications open={notificationsOpen} updateSession={updateSession} close={() => {setNotificationsOpen(false)}} /> : null }

        <Drawer
            PaperProps={{
                sx: { maxWidth: "400px", width: "100%", maxHeight: "100%", marginTop: "46px", textAlign: "left"},
            }}
            anchor={"right"}
            open={accountDrawerOpen}
            onClose={() => {toggleAccountDrawer("right", false)}}
        >
            <AccountPanel toggleAccountDrawer={toggleAccountDrawer} />
        </Drawer>

        <Drawer
            PaperProps={{
                sx: { maxWidth: "400px", width: "100%", maxHeight: "100%", marginTop: "46px", textAlign: "left"},
            }}
            anchor={"right"}
            open={settingsDrawerOpen}
            onClose={() => {toggleSettingsDrawer("right", false)}}
        >
            <SettingsPanel toggleSettingsDrawer={toggleSettingsDrawer} />
        </Drawer>
        <Listener />
    </div>
    )
}


