import React, { useState, useEffect, useRef } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import ForumIcon from '@mui/icons-material/Forum';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {req_get_all_sessions, req_join_session} from "../../helpers/requests";
import {
    clearChatSession, clearChatSessionObject, clearChatSessionUser,
    createChatSession,
    createChatSessionObject,
    createChatSessionUser,
    createSelectedAccount, setListeningState, setRecordingState, setSuppressRestartState
} from "../../helpers/session";
import LanguageSelector from "../embeddedSelectors/languageSelector";
import {content} from "../../globals/content";
import eventBus from "../../helpers/eventBus";
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Popover} from "@mui/material";


const theme = createTheme();

export default function ChatInvitation() {

    const [language, setLanguage] = React.useState("");
    const [chatCode, setChatCode] = React.useState("");
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [showPendingMessage, setShowPendingMessage] = React.useState(false);

    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        console.log(data.get('inviteCode'))

        if (chatCode){
            eventBus.dispatch("showLoader", {showLoader: true})
            req_join_session(data.get('inviteCode'), language).then((response) => {
                console.log("req_join_session response", response);
                if (response.status === 200) {
                    console.log(response.data)

                    if (response.data.autoApprove){

                    } else {
                        console.log("show waiting message")
                        setShowPendingMessage(true)
                        clearChatSession();
                        clearChatSessionObject();
                        clearChatSessionUser();
                        setSuppressRestartState(true);
                        setListeningState(false);
                        setRecordingState(false);
                    }

                    //createChatSession(response.data.newSession);
                    //createChatSessionObject(response.data.newSession);
                    //createChatSessionUser(response.data.userId);
                    //createSelectedAccount(response.data.sessionAccount)
                    //updateSession();


                } else {
                    alert("Invalid session code", data.get('inviteCode'));
                    eventBus.dispatch("showLoader", {showLoader: false})
                }
            })
        }
    };

    const handleClose = () => {
        console.log("continue button")
        setShowPendingMessage(false)
        window.location = '/';
    }

    const updateSession = () => {
        //if (localStorage.getItem("cSessionId") && localStorage.getItem("cSessionId") !== "undefined") {

            req_get_all_sessions()
                .then(function (result) {
                    if (result.status === 200) {
                        localStorage.setItem("chatSessions", JSON.stringify(result.data.sessions));
                        eventBus.dispatch("showLoader", {showLoader: false})
                        window.location = '/';
                    }
                })
        //}
    }

    useEffect(() => {

        let path = window.location.pathname;
        if (path.split("/").length > 1) {
            setChatCode(path.split("/")[2])
        }
        setLanguage(localStorage.getItem("siteLanguage") || "en-US");

    }, []);



    return (
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Avatar sx={{ m: 1, bgcolor: '#FF2F00' }}>
                        <ForumIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        {content.chatInviteScreenContent[localStorage.getItem("siteLanguage") || "en-US"].title}
                    </Typography>
                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="inviteCode"
                            value={chatCode}
                            label={content.chatInviteScreenContent[localStorage.getItem("siteLanguage") || "en-US"].chatCodeField}
                            name="inviteCode"
                            autoFocus

                        />
                        <div style={{textAlign: "center", margin: "10px auto", width: "300px"}}>
                            <LanguageSelector language={language} setLanguage={setLanguage}
                                              label={content.chatInviteScreenContent[localStorage.getItem("siteLanguage") || "en-US"].languageField}
                                              mutliSelect={false}/>
                        </div>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 1, mb: 2, backgroundColor: "#424242", textTransform: "none"}}
                        >
                            {content.chatInviteScreenContent[localStorage.getItem("siteLanguage") || "en-US"].joinButton}
                        </Button>

                    </Box>
                </Box>

            </Container>

            <Dialog id={"pending_approval_popover"}
                     open={showPendingMessage}
                    style={{minWidth: 370}}
                    onClose={handleClose}
            >
                <DialogTitle>{content.chatInviteScreenContent[localStorage.getItem("siteLanguage") || "en-US"].pendingTitle}</DialogTitle>
                <DialogContent style={{padding: "10px"}}>
                    <DialogContentText>
                        <div>
                            {content.chatInviteScreenContent[localStorage.getItem("siteLanguage") || "en-US"].pendingText}
                        </div>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant={"contained"}
                            style={{color: "#fff",
                                backgroundColor: "#444",
                                margin: "10px auto"
                            }}
                            onClick={handleClose} >{content.chatInviteScreenContent[localStorage.getItem("siteLanguage") || "en-US"].dashboardButton}</Button>

                </DialogActions>


            </Dialog>

        </ThemeProvider>
    );
}
