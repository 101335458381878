import {useEffect} from "react";
import Card from "@mui/material/Card";
import {Button} from "@mui/material";


export function ExplanationCard(props) {


    useEffect(() => {
        //addListener('reloadDashboardCards', showUpdated);



    }, []);

    return (

        <Card key={"card_" + props.title} style={{minWidth: 280, maxWidth: 335, flexGrow: 1, backgroundColor: "#f7f7f7", borderRadius: 5, padding: 5}}>
            <div style={{fontSize: "1.2rem", fontWeight: "bold"}}>
                {props.title}
            </div>
            <div>
                {props.text}
            </div>
            <div>
                <Button
                    style={{borderWidth: 1, borderColor: "#ddd", borderStyle: "solid", color: "#fff",
                        borderTop: 0, borderRadius: 5, backgroundColor: props.typeProps.color
                    }}

                    onClick={() => { props.setSessionType(props.sessionType) }}
                    contentStyle={{padding: 0, margin: 0}}
                    size={"small"}>{props.createButton}</Button>
            </div>
        </Card>

    )





}
