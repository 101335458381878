import React, {Component, useEffect, useState} from "react";
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import TranslateIcon from '@mui/icons-material/Translate';
import InterpreterModeIcon from '@mui/icons-material/InterpreterMode';
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import Avatar from "@mui/material/Avatar";
import CloseIcon from '@mui/icons-material/Close';
import {AvatarGroup, IconButton, Tooltip} from "@mui/material";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import EditIcon from '@mui/icons-material/Edit';
import eventBus from "../../helpers/eventBus";
import "./chatHeader.css";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import {req_get_session} from "../../helpers/requests";
import {CircleFlag} from "react-circle-flags";

export default function ChatHeader(props) {


    const [sessionObject, setSessionObject] = useState({});
    const [currentSessionId, setCurrentSessionId] = useState("");
    const [editNameOpen, setEditNameOpen] = useState(false);
    const [spur, setSpur] = useState(false);
    const [isOwner, setIsOwner] = useState(false);




    useEffect(() => {

        //console.log("header load", props)
        loadSession()
        //this.setState({remount: true})
        //this.setState({isOwner: false})
        setIsOwner(false);
        eventBus.on("updateHeader", (uObj) => {
            //console.log("bus update header in header")
            setIsOwner(false);
            loadSession()
        });
        eventBus.remove("updateHeader")
    }, [props.detailObj, props.chatId])


    const loadSession = () => {
        //console.log("load session header")
        //let self = this;
        req_get_session(localStorage.getItem("cSessionId"))
            .then(function (result) {
                if (result.status === 200) {
                    localStorage.setItem("cSession", JSON.stringify(result.data.session));



                        //console.log("session loaded", result)

                    //console.log(this.props.detailObj)

                    if (result.data.session.hasOwnProperty("sessionMembers")) {
                        //self.setState({isOwner: false})
                        setIsOwner(false);
                        result.data.session.sessionMembers.forEach(
                            (member) => {
                                if (member.userId === localStorage.getItem("uid")) {
                                    if (member.role === "owner") {
                                        //self.setState({isOwner: true})
                                        setIsOwner(true);
                                    }
                                }
                            }
                        )
                    }
                    //console.log("call updateSubmissionContainer in chat header")
                    //eventBus.dispatch("updateSubmissionContainer", {})
                    //eventBus.dispatch("updateLanguages", {})
                    if (!currentSessionId) {
                        //self.setState({currentSessionId: localStorage.getItem("cSessionId")})
                        setCurrentSessionId(localStorage.getItem("cSessionId"))
                    }
                    //self.setState({sessionObject: result.data.session, currentSessionId: localStorage.getItem("cSessionId")})
                    setCurrentSessionId(localStorage.getItem("cSessionId"))
                    setSessionObject(result.data.session)

                }
            })

    }

    const GetSessionTypeImage = (sessionType) => {
        if (sessionObject) {
            if (sessionObject.sessionType === "conversation") {
                return (<QuestionAnswerIcon />)
            } else if (sessionObject.sessionType === "translate") {
                return (<TranslateIcon />)
            } else if (sessionObject.sessionType === "shared") {
                return (<InterpreterModeIcon />)
            } else if (sessionObject.sessionType === "quickConnect") {
                return (<QrCodeScannerIcon />)
            }
        }
    }

    const FormatLanguages = () => {

        //sessLanguages, sessMembers
        // (sessionObject.languages, sessionObject.sessionMembers)
        //console.log("formatting languages", sessionObject)
        try {
            const uid = localStorage.getItem("uid");
            const userRec = sessionObject.sessionMembers.find((member) => member.userId === uid);
            const userLang = userRec?.language;

            return sessionObject.languages.map((lang, index) => {
                const isLast = index === sessionObject.languages.length - 1;
                const isUserLang = lang === userLang;

                return (
                    <div
                        key={lang}
                        style={{
                            fontWeight: isUserLang ? "bold" : null,
                            color: isUserLang ? "#ee4400" : "inherit",
                            display: "flex", alignItems: "center", marginLeft: "3px"
                        }}
                    >
                        <CircleFlag countryCode={lang.split("-")[1].toLowerCase()} height="15" style={{marginRight: "2px"}} />({lang.split("-")[0]})

                        {!isLast && ", "}
                    </div>
                );
            });
        } catch (error) {
            console.error("Error formatting languages:", error);
            return null;
        }
    }




    //render() {

        return (
            <div style={{display: "flex", justifyContent: "space-between"}}>
                { sessionObject.id ?
                    <div style={{display: "flex", justifyContent: "left", minHeight: "49px"}}>
                        <div style={{paddingRight: "10px", paddingTop: "10px"}}><GetSessionTypeImage /></div>

                        {sessionObject.sessionType === "quickConnect" &&
                        !isOwner ?
                            <div style={{paddingRight: "10px", paddingTop: "10px"}}>
                                <IconButton aria-describedby={"autoplay_switch"} variant="contained"
                                            onClick={props.toggleAutoplay}>
                                    {props.autoplay === "true" || props.autoplay === true ?
                                        <VolumeUpIcon style={{color: "#758a00", fontSize: "22px"}}/> :
                                        <VolumeOffIcon style={{color: "#8a0000", fontSize: "22px"}}/>
                                    }
                                </IconButton>
                            </div> : null
                        }

                        <div style={{fontSize: ".9rem"}}>{sessionObject.name}
                            {isOwner ?
                                <IconButton onClick={() => {
                                    props.openChatRename(sessionObject)
                                }}>
                                    <EditIcon style={{fontSize: "16px"}} />
                                </IconButton>
                                    : null
                            }
                            <br />
                            <div style={{fontSize: ".7rem", display: "flex", flexDirection: "row", alignItems: "center"}}>
                                { sessionObject && sessionObject.hasOwnProperty("languages") ?
                                    <FormatLanguages />
                                    : "" }
                             <span style={{display: "none"}}>Created: {sessionObject ? new Date(sessionObject.startTime).toLocaleString('en-US') : ""}</span>
                            </div>

                        </div>
                        {(sessionObject && sessionObject.hasOwnProperty("sessionType") &&
                            (sessionObject.sessionType === "conversation" || sessionObject.sessionType === "quickConnect")) ?
                            <div style={{
                                marginLeft: "10px",
                                paddingLeft: "10px",
                                paddingTop: "7px",
                                borderLeft: "1px solid #ddd"
                            }}>
                                <AvatarGroup sx={{
                                    '& .MuiAvatar-root': {
                                        boxSizing: "border-box",
                                        width: 24,
                                        height: 24,
                                        fontSize: ".7rem",
                                    },
                                }}
                                             max={3}>
                                {sessionObject.sessionMembers.map((member) => {
                                return (<Tooltip key={member.userId} title={member.displayName}>
                                    <Avatar
                                    key={member.userId}
                                    sx={{

                                        bgcolor: props.detailObj.hasOwnProperty("userProps")
                                            && props.detailObj.userProps.hasOwnProperty(member.userId)
                                            && props.detailObj.userProps[member.userId].hasOwnProperty("avatarColor")
                                            ? props.detailObj.userProps[member.userId].avatarColor : "#ddd",
                                        width: 24,
                                        height: 24,
                                        fontSize: ".7rem",
                                        color: "#fff",
                                        textAlign: "center",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        display: "flex"
                                    }}

                                    alt={member.displayName}
                                    src={props.detailObj.hasOwnProperty("userProps")
                                    && props.detailObj.userProps.hasOwnProperty(member.userId)
                                    && props.detailObj.userProps[member.userId].hasOwnProperty("avatarUrl")
                                    ?
                                        "https://firebasestorage.googleapis.com/v0/b/simpledataset-prod.appspot.com/o/" + props.detailObj.userProps[member.userId].avatarUrl.replace("/", "%2F") + "?alt=media"
                                         : null}
                                >{member.displayName[0]}</Avatar></Tooltip>)
                            })
                                }</AvatarGroup></div> : null
                        }

                        {(sessionObject && ["conversation", "quickConnect"].includes(sessionObject.sessionType) ) ?
                        <div style={{marginLeft: "5px"}}>
                            <IconButton
                                    onClick={() => {props.openChatInvite(sessionObject)}}
                                    ><GroupAddIcon /></IconButton>
                        </div> : null
                        }

                        {isOwner ?
                            <div style={{marginLeft: "5px", borderLeft: "1px solid #ddd"}}>
                                <IconButton
                                    onClick={() => {
                                        props.openChatDelete(sessionObject)
                                    }}
                                ><DeleteForeverIcon/></IconButton>
                            </div>
                            : null
                        }

                    </div>
                    : null
                }

                {!props.isAnonymous ?
                    <div><IconButton onClick={props.clearSession}><CloseIcon/></IconButton></div>
                    : null
                }

            </div>
        )
    //}
}
