export const content = {
    homeScreenContent: {
        "en-US": {
            title: "",
            subTitle: "The chat platform that breaks the language barrier."
        },
        "en-GB": {
            title: "",
            subTitle: "The chat platform that breaks the language barrier."
        },
        "es-ES": {
            title: "",
            subTitle: "La plataforma de chat que rompe la barrera del idioma."
        },
        "es-MX": {
            title: "",
            subTitle: "La plataforma de chat que rompe la barrera del idioma."
        },
        "fr-FR": {
            title: "",
            subTitle: "La plateforme de chat qui brise la barrière de la langue."
        },
        "de-DE": {
            title: "",
            subTitle: "Die Chat-Plattform, die die Sprachbarriere durchbricht."
        },
        "it-IT": {
            title: "",
            subTitle: "La piattaforma di chat che abbatte la barriera linguistica."
        },
        "hi-IN": {
            title: "",
            subTitle: "वह चैट प्लेटफ़ॉर्म जो भाषा की बाधा तोड़ता है"
        },
        "cmn-CN": {
            title: "",
            subTitle: "打破语言障碍的聊天平台。"
        },
        "so-SO": {
            title: "Goobta lagu sheekeysto ee jebisa caqabada luqadda.",
            subTitle: ""
        },
        "pt-PT": {
            title: "A plataforma de chat que quebra a barreira linguística.",
            subTitle: ""
        },
        "pt-BR": {
            title: "A plataforma de chat que quebra a barreira linguística.",
            subTitle: ""
        },
        "id-ID": {
            title: "",
            subTitle: "Platform obrolan yang memecahkan hambatan bahasa.",
        },
        "vi-VN": {
            title: "",
            subTitle: "Nền tảng trò chuyện phá vỡ rào cản ngôn ngữ."
        },
        "ko-KR": {
            title: "",
            subTitle: "언어 장벽을 허무는 채팅 플랫폼입니다."
        },
        "ja-JP": {
            title: "",
            subTitle: "言語の壁を打ち破るチャットプラットフォーム。"
        },
        "ar-IQ": {
            title: "",
            subTitle: "إنها منصة دردشة تكسر حواجز اللغة."
        },
        "fil-PH": {
            title: "",
            subTitle: "Ito ay isang chat platform na sumisira sa mga hadlang sa wika."
        },
        "ne-NP": {
            title: "",
            subTitle: "यो एक च्याट प्लेटफर्म हो जसले भाषा अवरोधहरू तोड्छ।"
        },
        "ar-SA": {
            title: "",
            subTitle: "إنها منصة دردشة تكسر حواجز اللغة."
        },
        "am-ET": {
            title: "",
            subTitle: "ቋንቋ ማልሰልን እንደሚችል የቻት ፕላቫል።"
        }

    },
    loginScreenContent: {
        "en-US": {
            title: "Log In",
            loginButton: "Log In",
            googleLoginButton: "Log In with Google",
            googleRegistrationError: "This Google account has not been registered. Register here:",
            errorMessage: "Login failed. Email or password are incorrect.",
            orText: "Or",
            email: "Email Address",
            password: "Password",
            passwordResetLinkText: "Request Password Reset",
            possibleSocialErrorMessage: "Login failed. Email or password are incorrect. If you registered with Google, please login using the Google authentication button."
        },
        "en-GB": {
            title: "Log In",
            loginButton: "Log In",
            googleLoginButton: "Log In with Google",
            googleRegistrationError: "This Google account has not been registered. Register here:",
            errorMessage: "Login failed. Email or password are incorrect.",
            orText: "Or",
            email: "Email Address",
            password: "Password",
            passwordResetLinkText: "Request Password Reset",
            possibleSocialErrorMessage: "Login failed. Email or password are incorrect. If you registered with Google, please login using the Google authentication button."
        },
        "es-ES": {
            title: "Inicie Sesión",
            loginButton: "Inicie Sesión",
            googleLoginButton: "Inicia sesión con Google",
            googleRegistrationError: "Esta cuenta de Google no está registrada. Regístrate aquí:",
            errorMessage: "Inicio de sesión fallido. Correo electrónico o contraseña incorrectos.",
            orText: "O",
            email: "Correo Electrónico",
            password: "Contraseña",
            passwordResetLinkText: "Restablecer contraseña",
            possibleSocialErrorMessage: "Inicio de sesión fallido. Correo electrónico o contraseña incorrectos. Si te registraste con Google, inicia sesión utilizando el botón de autenticación de Google."
        },
        "es-MX": {
            title: "Inicie Sesión",
            loginButton: "Inicie Sesión",
            googleLoginButton: "Inicia sesión con Google",
            googleRegistrationError: "Esta cuenta de Google no está registrada. Regístrate aquí:",
            errorMessage: "Inicio de sesión fallido. Correo electrónico o contraseña incorrectos.",
            orText: "O",
            email: "Correo Electrónico",
            password: "Contraseña",
            passwordResetLinkText: "Restablecer contraseña",
            possibleSocialErrorMessage: "Inicio de sesión fallido. Correo electrónico o contraseña incorrectos. Si te registraste con Google, inicia sesión utilizando el botón de autenticación de Google."
        },
        "fr-FR": {
            title: "Connectez-vous",
            loginButton: "Connectez-vous",
            googleLoginButton: "Connectez-vous avec Google",
            googleRegistrationError: "Ce compte Google n'a pas été enregistré. Inscrivez-vous ici :",
            errorMessage: "Échec de la connexion. L'adresse e-mail ou le mot de passe est incorrect.",
            orText: "Ou",
            email: "Adresse électronique",
            password: "Mot de Passe",
            passwordResetLinkText: "Demande de réinitialisation du mot de passe",
            possibleSocialErrorMessage: "Échec de la connexion. L'adresse e-mail ou le mot de passe est incorrect. Si vous vous êtes inscrit avec Google, veuillez vous connecter en utilisant le bouton d'authentification Google."
        },
        "de-DE": {
            title: "Anmelden",
            loginButton: "Anmelden",
            googleLoginButton: "Mit Google anmelden",
            googleRegistrationError: "Dieses Google-Konto wurde nicht registriert. Hier registrieren:",
            errorMessage: "Anmeldung fehlgeschlagen. E-Mail oder Passwort sind falsch.",
            orText: "Oder",
            email: "E-Mail-Adresse",
            password: "Passwort",
            passwordResetLinkText: "Passwort zurücksetzen",
            possibleSocialErrorMessage: "Anmeldung fehlgeschlagen. E-Mail oder Passwort sind falsch. Wenn Sie sich mit Google registriert haben, melden Sie sich bitte mit der Google-Authentifizierungsschaltfläche an."
        },
        "it-IT": {
            title: "Accedi",
            loginButton: "Accedi",
            googleLoginButton: "Accedi con Google",
            googleRegistrationError: "Questo account Google non è stato registrato. Registrati qui:",
            errorMessage: "Accesso non riuscito. Email o password errate.",
            orText: "O",
            email: "Indirizzo Email",
            password: "Password",
            passwordResetLinkText: "Richiesta di reimpostazione della password",
            possibleSocialErrorMessage: "Accesso non riuscito. Email o password errate. Se ti sei registrato con Google, accedi utilizzando il pulsante di autenticazione di Google."
        }
        ,
        "hi-IN": {
            title: "लॉग इन करें",
            loginButton: "लॉग इन करें",
            googleLoginButton: "Google से लॉग इन करें",
            googleRegistrationError: "यह Google खाता पंजीकृत नहीं है। यहां पंजीकरण करें:",
            errorMessage: "लॉगिन विफल हुआ। ईमेल या पासवर्ड गलत है।",
            orText: "या",
            email: "ईमेल पता",
            password: "पासवर्ड",
            passwordResetLinkText: "पासवर्ड रीसेट का अनुरोध करें",
            possibleSocialErrorMessage: "लॉगिन विफल हुआ। ईमेल या पासवर्ड गलत है। यदि आपने Google के साथ पंजीकरण किया है, तो कृपया Google प्रमाणीकरण बटन का उपयोग करके लॉगिन करें।"
        },
        "cmn-CN": {
            title: "登录",
            loginButton: "登录",
            googleLoginButton: "使用Google登录",
            googleRegistrationError: "此Google帐户尚未注册。在此注册：",
            errorMessage: "登录失败。 电子邮件或密码不正确。",
            orText: "要么",
            email: "电子邮件地址",
            password: "密码",
            passwordResetLinkText: "请求密码重置",
            possibleSocialErrorMessage: "登录失败。 电子邮件或密码不正确。 如果您使用Google注册，请使用Google身份验证按钮登录。"
        },
        "so-SO": {
            title: "Soo gal",
            loginButton: "Soo gal",
            googleLoginButton: "Soo gal Google",
            googleRegistrationError: "Tilmaamaha Google-kaan waa la la wareegay. Halkan wax ku qor:",
            errorMessage: "Soo gal aan la aqbali karin. Iimayl ama pin la xiriir.",
            email: "Iimayl",
            password: "Furaha",
            passwordResetLinkText: "Codso dib-u-eegista furaha",
            possibleSocialErrorMessage: "Soo gal aan la aqbali karin. Iimayl ama pin la xiriir. Haddii aad la wareegtid Google, fadlan soo gal iyadoo adeegsanaya badhanka Google."
        },
        "pt-PT": {
            title: "Conecte-se",
            loginButton: "Conecte-se",
            googleLoginButton: "Conecte-se com o Google",
            googleRegistrationError: "Esta conta do Google não foi registrada. Registre-se aqui:",
            errorMessage: "Falha no login. Email ou senha incorretos.",
            email: "Endereço de Email",
            password: "Senha",
            passwordResetLinkText: "Solicitar Redefinição de Senha",
            possibleSocialErrorMessage: "Falha no login. Email ou senha incorretos. Se você se registrou com o Google, faça login usando o botão de autenticação do Google."
        },
        "pt-BR": {
            title: "Entrar",
            loginButton: "Entrar",
            googleLoginButton: "Entrar com Google",
            googleRegistrationError: "Esta conta do Google não foi registrada. Registre-se aqui:",
            errorMessage: "Falha no login. Email ou senha incorretos.",
            email: "Endereço de Email",
            password: "Senha",
            passwordResetLinkText: "Solicitar Redefinição de Senha",
            possibleSocialErrorMessage: "Falha no login. Email ou senha incorretos. Se você se registrou com o Google, faça login usando o botão de autenticação do Google."
        },
        "id-ID": {
            title: "Masuk",
            loginButton: "Masuk",
            googleLoginButton: "Masuk dengan Google",
            googleRegistrationError: "Akun Google ini belum terdaftar. Daftar di sini:",
            errorMessage: "Login gagal. Email atau kata sandi salah.",
            email: "Alamat Email",
            password: "Kata Sandi",
            passwordResetLinkText: "Minta Pengaturan Ulang Kata Sandi",
            possibleSocialErrorMessage: "Login gagal. Email atau kata sandi salah. Jika Anda mendaftar dengan Google, silakan masuk menggunakan tombol otentikasi Google."
        },
        "vi-VN": {
            title: "Đăng nhập",
            loginButton: "Đăng nhập",
            googleLoginButton: "Đăng nhập bằng Google",
            googleRegistrationError: "Tài khoản Google này chưa được đăng ký. Đăng ký tại đây:",
            errorMessage: "Đăng nhập thất bại. Email hoặc mật khẩu không đúng.",
            email: "Địa chỉ Email",
            password: "Mật khẩu",
            passwordResetLinkText: "Yêu cầu Đặt lại Mật khẩu",
            possibleSocialErrorMessage: "Đăng nhập thất bại. Email hoặc mật khẩu không đúng. Nếu bạn đã đăng ký bằng Google, vui lòng đăng nhập bằng nút xác thực Google."
        },
        "ko-KR": {
            title: "로그인",
            loginButton: "로그인",
            googleLoginButton: "Google로 로그인",
            googleRegistrationError: "이 Google 계정은 등록되지 않았습니다. 여기에서 등록하십시오:",
            errorMessage: "로그인에 실패했습니다. 이메일 또는 비밀번호가 잘못되었습니다.",
            email: "이메일 주소",
            password: "비밀번호",
            passwordResetLinkText: "비밀번호 재설정 요청",
            possibleSocialErrorMessage: "로그인에 실패했습니다. 이메일 또는 비밀번호가 잘못되었습니다. Google로 등록했다면 Google 인증 버튼을 사용하여 로그인하십시오."
        },
        "ja-JP": {
            title: "ログイン",
            loginButton: "ログイン",
            googleLoginButton: "Googleでログイン",
            googleRegistrationError: "このGoogleアカウントは登録されていません。こちらで登録してください：",
            errorMessage: "ログインに失敗しました。メールアドレスまたはパスワードが間違っています。",
            email: "メールアドレス",
            password: "パスワード",
            passwordResetLinkText: "パスワードリセットリクエスト",
            possibleSocialErrorMessage: "ログインに失敗しました。メールアドレスまたはパスワードが間違っています。Googleで登録した場合は、Google認証ボタンを使用してログインしてください。"
        },
        "ar-IQ": {
            title: "تسجيل الدخول",
            loginButton: "تسجيل الدخول",
            googleLoginButton: "تسجيل الدخول باستخدام Google",
            googleRegistrationError: "لم يتم تسجيل هذا الحساب على Google. سجل هنا:",
            errorMessage: "فشل تسجيل الدخول. البريد الإلكتروني أو كلمة المرور غير صحيحة.",
            email: "عنوان البريد الإلكتروني",
            password: "كلمة المرور",
            passwordResetLinkText: "طلب إعادة تعيين كلمة المرور",
            possibleSocialErrorMessage: "فشل تسجيل الدخول. البريد الإلكتروني أو كلمة المرور غير صحيحة. إذا قمت بالتسجيل باستخدام Google، يرجى تسجيل الدخول باستخدام زر المصادقة على Google."
        },
        "fil-PH": {
            title: "Mag-log in",
            loginButton: "Mag-log in",
            googleLoginButton: "Mag-log in gamit ang Google",
            googleRegistrationError: "Ang Google account na ito ay hindi pa naka-rehistro. Magrehistro dito:",
            errorMessage: "Nabigo ang pag-log in. Mali ang email o password.",
            email: "Email Address",
            password: "Password",
            passwordResetLinkText: "Humiling ng Pag-reset ng Password",
            possibleSocialErrorMessage: "Nabigo ang pag-log in. Mali ang email o password. Kung nagrehistro ka gamit ang Google, mag-log in gamit ang Google authentication button."

        },
        "ne-NP": {
            title: "लगइन गर्नुहोस्",
            loginButton: "लगइन गर्नुहोस्",
            googleLoginButton: "Google बाट लगइन गर्नुहोस्",
            googleRegistrationError: "यो Google खाता अझै दर्ता गरिएको छैन। यहाँ दर्ता गर्नुहोस्:",
            errorMessage: "लगइन गर्न असफल भयो। इमेल वा पासवर्ड गलत छ।",
            email: "इमेल ठेगाना",
            password: "पासवर्ड",
            passwordResetLinkText: "पासवर्ड पुनः सेट गर्नको लागि अनुरोध गर्नुहोस्",
            possibleSocialErrorMessage: "लगइन गर्न असफल भयो। इमेल वा पासवर्ड गलत छ। यदि तपाईंले Google बाट दर्ता गरेको छ भने, Google प्रमाणीकरण बटन प्रयोग गरेर लगइन गर्नुहोस्।"
        },
        "ar-SA": {
            title: "تسجيل الدخول",
            loginButton: "تسجيل الدخول",
            googleLoginButton: "تسجيل الدخول باستخدام Google",
            googleRegistrationError: "لم يتم تسجيل هذا الحساب على Google. سجل هنا:",
            errorMessage: "فشل تسجيل الدخول. البريد الإلكتروني أو كلمة المرور غير صحيحة.",
            email: "عنوان البريد الإلكتروني",
            password: "كلمة المرور",
            passwordResetLinkText: "طلب إعادة تعيين كلمة المرور",
            possibleSocialErrorMessage: "فشل تسجيل الدخول. البريد الإلكتروني أو كلمة المرور غير صحيحة. إذا قمت بالتسجيل باستخدام Google، يرجى تسجيل الدخول باستخدام زر المصادقة على Google."
        },
        "am-ET": {
            title: "ይህን ይጠቀሙ",
            loginButton: "ይህን ይጠቀሙ",
            googleLoginButton: "Google በመጠቀም ይጠቀሙ",
            googleRegistrationError: "ይህ Google አካውንት እንደምን ሊመዘገብ ነው። እዚህ ይመዝገቡ።",
            errorMessage: "ግባት አልተቻለም። ኢሜል ወይም የይለፍ ቃል በማድረግ አልተሳካም።",
            email: "ኢሜል አድራሻ",
            password: "የይለፍ ቃል",
            passwordResetLinkText: "የይለፍ ቃል አግኝቷል",
            possibleSocialErrorMessage: "ግባት አልተቻለም። ኢሜል ወይም የይለፍ ቃል በማድረግ አልተሳካም። እንደምን ሊመዘገብ ነው። እባኮት Google በመጠቀም ይጠቀሙ።"

        }
    },
    registerScreenContent: {
        "en-US": {
            title: "Register",
            registerButton: "Register",
            googleRegisterButton: "Register with Google",
            orText: "Or",
            registrationError: "Registration failed. Please review for missing or invalid information.",
            socialRegistrationError: "To register, review and acknowledge the Privacy Policy and Terms of Service confirmation.",
            registrationSuccess: "Excellent! You will receive an email with a one-time password to confirm your account creation. Place the one-time password below.",
            email: "Email Address",
            password: "Password",
            displayName: "Display Name",
            privacyPolicyLinkText: "Privacy Policy",
            termsOfServiceLinkText: "Terms of Service",
            agreementStatement: "By creating an account, I agree to the Privacy Policy and Terms of Service.",
            EMAIL_ALREADY_EXISTS: "This email address is already in use. Please use a different email address or attempt to reset your password here:",
            validateButtonText: "Validate Account",
            otpError: "The provided one-time password was incorrect or expired. Please try again."

        },
        "en-GB": {
            title: "Register",
            registerButton: "Register",
            googleRegisterButton: "Register with Google",
            orText: "Or",
            registrationError: "Registration failed. Please review for missing or invalid information.",
            socialRegistrationError: "To register, review and acknowledge the Privacy Policy and Terms of Service confirmation.",
            registrationSuccess: "Excellent! You will receive an email with a one-time password to confirm your account creation. Place the one-time password below.",
            email: "Email Address",
            password: "Password",
            displayName: "Display Name",
            privacyPolicyLinkText: "Privacy Policy",
            termsOfServiceLinkText: "Terms of Service",
            agreementStatement: "By creating an account, I agree to the Privacy Policy and Terms of Service.",
            EMAIL_ALREADY_EXISTS: "This email address is already in use. Please use a different email address or attempt to reset your password here:",
            validateButtonText: "Validate Account",
            otpError: "The provided one-time password was incorrect or expired. Please try again."
        },
        "es-ES": {
            title: "Registrarse",
            registerButton: "Registrarse",
            googleRegisterButton: "Regístrate con Google",
            orText: "O",
            registrationError: "El registro ha fallado. Por favor, revisa si falta o es inválida alguna información.",
            socialRegistrationError: "Para registrarte, revisa y confirma que has leído la Política de Privacidad y los Términos de Servicio.",
            registrationSuccess: "¡Excelente! Recibirás un correo electrónico con una contraseña de un solo uso para confirmar la creación de tu cuenta. Introduce la contraseña de un solo uso a continuación.",
            email: "Correo Electrónico",
            password: "Contraseña",
            displayName: "Nombre para Mostrar",
            privacyPolicyLinkText: "Política de privacidad",
            termsOfServiceLinkText: "Términos de servicio",
            agreementStatement: "Al crear una cuenta, acepto la Política de Privacidad y los Términos del Servicio.",
            EMAIL_ALREADY_EXISTS: "Esta dirección de correo electrónico ya está en uso. Por favor, utiliza una dirección de correo electrónico diferente o intenta restablecer tu contraseña aquí:",
            validateButtonText: "Validar Cuenta",
            otpError: "La contraseña de un solo uso proporcionada era incorrecta o ha caducado. Por favor, inténtalo de nuevo."

        },
        "es-MX": {
            title: "Registrarse",
            registerButton: "Registrarse",
            googleRegisterButton: "Regístrate con Google",
            orText: "O",
            registrationError: "El registro falló. Por favor revisa que no falte o que la información sea inválida.",
            socialRegistrationError: "Para registrarte, revisa y confirma que has leído la Política de Privacidad y los Términos de Servicio.",
            registrationSuccess: "¡Excelente! Recibirás un correo electrónico con una contraseña de un solo uso para confirmar la creación de tu cuenta. Introduce la contraseña de un solo uso a continuación.",
            email: "Correo Electrónico",
            password: "Contraseña",
            displayName: "Nombre para Mostrar",
            privacyPolicyLinkText: "Política de privacidad",
            termsOfServiceLinkText: "Términos de servicio",
            agreementStatement: "Al crear una cuenta, acepto la Política de Privacidad y los Términos del Servicio.",
            EMAIL_ALREADY_EXISTS: "Esta dirección de correo electrónico ya está en uso. Por favor, utiliza una dirección de correo electrónico diferente o intenta restablecer tu contraseña aquí:",
            validateButtonText: "Validar Cuenta",
            otpError: "La contraseña de un solo uso proporcionada era incorrecta o ha caducado. Por favor, inténtalo de nuevo."
        },
        "fr-FR": {
            title: "S'inscrire",
            registerButton: "S'inscrire",
            googleRegisterButton: "S'inscrire avec Google",
            orText: "Ou",
            registrationError: "Inscription échouée. Veuillez vérifier les informations manquantes ou incorrectes.",
            socialRegistrationError: "Pour vous inscrire, veuillez lire et accepter la politique de confidentialité et les conditions générales d'utilisation.",
            registrationSuccess: "Excellent! Vous recevrez un email avec un mot de passe à usage unique pour confirmer la création de votre compte. Entrez le mot de passe à usage unique ci-dessous.",
            email: "Adresse électronique",
            password: "Mot de Passe",
            displayName: "Nom d'affichage",
            privacyPolicyLinkText: "Politique de confidentialité",
            termsOfServiceLinkText: "Conditions d'utilisation",
            agreementStatement: "En créant un compte, j'accepte la politique de confidentialité et les conditions d'utilisation.",
            EMAIL_ALREADY_EXISTS: "Cette adresse email est déjà utilisée. Veuillez utiliser une autre adresse email ou essayer de réinitialiser votre mot de passe ici :",
            validateButtonText: "Valider le Compte",
            otpError: "Le mot de passe à usage unique fourni était incorrect ou a expiré. Veuillez réessayer."

        },
        "de-DE": {
            title: "Registrieren",
            registerButton: "Registrieren",
            googleRegisterButton: "Mit Google registrieren",
            orText: "Oder",
            registrationError: "Die Registrierung ist fehlgeschlagen. Bitte überprüfen Sie die Angaben auf fehlende oder ungültige Informationen.",
            socialRegistrationError: "Um sich zu registrieren, lesen und bestätigen Sie die Datenschutzrichtlinien und Nutzungsbedingungen.",
            registrationSuccess: "Ausgezeichnet! Sie erhalten eine E-Mail mit einem einmaligen Passwort, um die Erstellung Ihres Kontos zu bestätigen. Geben Sie das einmalige Passwort unten ein.",
            email: "E-Mail-Adresse",
            password: "Passwort",
            displayName: "Anzeigename",
            privacyPolicyLinkText: "Datenschutzrichtlinie",
            termsOfServiceLinkText: "Nutzungsbedingungen",
            agreementStatement: "Durch die Erstellung eines Kontos stimme ich der Datenschutzrichtlinie und den Nutzungsbedingungen zu.",
            EMAIL_ALREADY_EXISTS: "Diese E-Mail-Adresse wird bereits verwendet. Bitte verwenden Sie eine andere E-Mail-Adresse oder versuchen Sie, Ihr Passwort hier zurückzusetzen:",
            validateButtonText: "Konto validieren",
            otpError: "Das bereitgestellte einmalige Passwort war falsch oder ist abgelaufen. Bitte versuchen Sie es erneut."

        },
        "it-IT": {
            title: "Registrarsi",
            registerButton: "Registrarsi",
            googleRegisterButton: "Registrati con Google",
            orText: "O",
            registrationError: "Registrazione fallita. Si prega di controllare le informazioni mancanti o non valide.",
            socialRegistrationError: "Per registrarti, rivedi e accetta la conferma dell'Informativa sulla privacy e dei Termini di servizio.",
            registrationSuccess: "Fantastico! Riceverai un'email con una password monouso per confermare la creazione del tuo account. Inserisci la password monouso qui sotto.",
            email: "Indirizzo Email",
            password: "Password",
            displayName: "Nome visualizzato",
            privacyPolicyLinkText: "Informativa sulla privacy",
            termsOfServiceLinkText: "Termini di servizio",
            agreementStatement: "Creando un account, accetto l'Informativa sulla privacy e i Termini di servizio.",
            EMAIL_ALREADY_EXISTS: "Questo indirizzo email è già in uso. Si prega di utilizzare un altro indirizzo email o provare a reimpostare la password qui:",
            validateButtonText: "Convalida Account",
            otpError: "La password monouso fornita era errata o è scaduta. Si prega di riprovare."
        }
        ,
        "hi-IN": {
            title: "पंजीकरण करें",
            registerButton: "पंजीकरण करें",
            googleRegisterButton: "Google से पंजीकरण करें",
            orText: "या",
            registrationError: "पंजीकरण विफल हुआ। कृपया गायब या अमान्य जानकारी के लिए समीक्षा करें।",
            socialRegistrationError: "पंजीकरण के लिए, कृपया गोपनीयता नीति और सेवा की शर्तों को पढ़ें और स्वीकार करें।",
            registrationSuccess: "बहुत बढ़िया! आपको अपने खाते की सृष्टि की पुष्टि करने के लिए एक एकबार प्रयोग के लिए पासवर्ड के साथ एक ईमेल मिलेगा। नीचे एकबार प्रयोग के लिए पासवर्ड दर्ज करें।",
            email: "ईमेल पता",
            password: "पासवर्ड",
            displayName: "प्रदर्शन नाम",
            privacyPolicyLinkText: "गोपनीयता नीति",
            termsOfServiceLinkText: "सेवा की शर्तें",
            agreementStatement: "खाता बनाते समय, मैं गोपनीयता नीति और सेवा की शर्तों से सहमत हूं।",
            EMAIL_ALREADY_EXISTS: "यह ईमेल पता पहले से ही उपयोग में है। कृपया एक अन्य ईमेल पता उपयोग करें या यहाँ पासवर्ड रीसेट करने का प्रयास करें:",
            validateButtonText: "खाता सत्यापित करें",
            otpError: "प्रदान किया गया एकबार प्रयोग का पासवर्ड गलत था या समाप्त हो गया था। कृपया पुनः प्रयास करें।"
        },
        "cmn-CN": {
            title: "注册",
            registerButton: "注册",
            googleRegisterButton: "使用 Google 注册",
            orText: "或",
            registrationError: "注册失败。请检查缺失或无效的信息。",
            socialRegistrationError: "要注册，请阅读并接受隐私政策和服务条款。",
            registrationSuccess: "太棒了！您将收到一封带有一次性密码的电子邮件，以确认您的帐户创建。在下面输入一次性密码。",
            email: "电子邮件地址",
            password: "密码",
            displayName: "显示名称",
            privacyPolicyLinkText: "隐私政策",
            termsOfServiceLinkText: "服务条款",
            agreementStatement: "创建帐户即表示我同意隐私政策和服务条款。",
            EMAIL_ALREADY_EXISTS: "此电子邮件地址已在使用中。请使用其他电子邮件地址或尝试在此处重置密码：",
            validateButtonText: "验证帐户",
            otpError: "提供的一次性密码无效或已过期。请重试。"
        },
        "so-SO": {
            title: "Is diwaangeli",
            registerButton: "Diwaangeli",
            googleRegisterButton: "Diwaangeli Google",
            orText: "ama",
            registrationError: "Diwaangelinta waxaa lama filaan ah. Fadlan eeg waxyaabaha aan lahayn ama aan sax ahayn.",
            socialRegistrationError: "Si aad diwaangeli ugu sameysid, fadlan akhriso iyo aqbal shuruudaha xogta gaarka ah iyo Xeerka adeega.",
            registrationSuccess: "Waa wanaagsan! Waxaad helayso emayl oo ah furan oo ah furaha, si aad u aqbalid diyaarinta akaawntaada. Fadlan geli furaha hoose.",
            email: "Emayl",
            password: "Furaha",
            displayName: "Magaca muujinaya",
            privacyPolicyLinkText: "Shuruudaha Xogta Gaarka ah",
            termsOfServiceLinkText: "Xeerka Adeega",
            agreementStatement: "Diyaarinta akaawnta waxaa lama filaan ah, waxaan aqbalay Shuruudaha Xogta Gaarka ah iyo Xeerka Adeega.",
            EMAIL_ALREADY_EXISTS: "Emaylkan waa la isticmaalay. Fadlan isticmaal emayl kale ama isku day inaad dib ugu furto halkan:",
            validateButtonText: "Diyaarinta",
            otpError: "Furaha furan ama waqtiga la dhamaystiray ee la bixiyay ma aha. Fadlan isku day inaad dib ugu furto."
        },
        "pt-PT": {
            title: "Registar",
            registerButton: "Registar",
            googleRegisterButton: "Registar com o Google",
            orText: "ou",
            registrationError: "O registo falhou. Verifique as informações em falta ou inválidas.",
            socialRegistrationError: "Para se registar, leia e aceite a política de privacidade e os termos de serviço.",
            registrationSuccess: "Excelente! Receberá um e-mail com uma palavra-passe única para confirmar a criação da sua conta. Introduza a palavra-passe única abaixo.",
            email: "Endereço de e-mail",
            password: "Palavra-passe",
            displayName: "Nome de apresentação",
            privacyPolicyLinkText: "Política de Privacidade",
            termsOfServiceLinkText: "Termos de Serviço",
            agreementStatement: "Ao criar uma conta, concordo com a política de privacidade e os termos de serviço.",
            EMAIL_ALREADY_EXISTS: "Este endereço de e-mail já está em utilização. Utilize outro endereço de e-mail ou tente redefinir a palavra-passe aqui:",
            validateButtonText: "Validar conta",
            otpError: "A palavra-passe única fornecida era inválida ou expirou. Tente novamente."
        },
        "pt-BR": {
            title: "Registrar",
            registerButton: "Registrar",
            googleRegisterButton: "Registrar com o Google",
            orText: "ou",
            registrationError: "O registro falhou. Verifique as informações ausentes ou inválidas.",
            socialRegistrationError: "Para se registrar, leia e aceite a política de privacidade e os termos de serviço.",
            registrationSuccess: "Ótimo! Você receberá um e-mail com uma senha única para confirmar a criação da sua conta. Insira a senha única abaixo.",
            email: "Endereço de e-mail",
            password: "Senha",
            displayName: "Nome de exibição",
            privacyPolicyLinkText: "Política de Privacidade",
            termsOfServiceLinkText: "Termos de Serviço",
            agreementStatement: "Ao criar uma conta, concordo com a política de privacidade e os termos de serviço.",
            EMAIL_ALREADY_EXISTS: "Este endereço de e-mail já está em uso. Use outro endereço de e-mail ou tente redefinir a senha aqui:",
            validateButtonText: "Validar conta",
            otpError: "A senha única fornecida era inválida ou expirou. Tente novamente."
        },
        "id-ID": {
            title: "Daftar",
            registerButton: "Daftar",
            googleRegisterButton: "Daftar dengan Google",
            orText: "atau",
            registrationError: "Pendaftaran gagal. Periksa informasi yang hilang atau tidak valid.",
            socialRegistrationError: "Untuk mendaftar, baca dan terima kebijakan privasi dan persyaratan layanan.",
            registrationSuccess: "Hebat! Anda akan menerima email dengan kata sandi unik untuk mengonfirmasi pembuatan akun Anda. Masukkan kata sandi unik di bawah.",
            email: "Alamat Email",
            password: "Kata Sandi",
            displayName: "Nama Tampilan",
            privacyPolicyLinkText: "Kebijakan Privasi",
            termsOfServiceLinkText: "Persyaratan Layanan",
            agreementStatement: "Dengan membuat akun, saya menyetujui kebijakan privasi dan persyaratan layanan.",
            EMAIL_ALREADY_EXISTS: "Alamat email ini sudah digunakan. Gunakan alamat email lain atau coba reset kata sandi di sini:",
            validateButtonText: "Validasi Akun",
            otpError: "Kata sandi unik yang diberikan tidak valid atau sudah kedaluwarsa. Coba lagi."
        },
        "vi-VN": {
            title: "Đăng ký",
            registerButton: "Đăng ký",
            googleRegisterButton: "Đăng ký bằng Google",
            orText: "hoặc",
            registrationError: "Đăng ký không thành công. Vui lòng kiểm tra thông tin bị thiếu hoặc không hợp lệ.",
            socialRegistrationError: "Để đăng ký, hãy đọc và chấp nhận Chính sách bảo mật và Điều khoản dịch vụ.",
            registrationSuccess: "Tuyệt vời! Bạn sẽ nhận được một email với mật khẩu duy nhất để xác nhận việc tạo tài khoản của bạn. Vui lòng nhập mật khẩu duy nhất dưới đây.",
            email: "Địa chỉ Email",
            password: "Mật khẩu",
            displayName: "Tên hiển thị",
            privacyPolicyLinkText: "Chính sách Bảo mật",
            termsOfServiceLinkText: "Điều khoản Dịch vụ",
            agreementStatement: "Bằng cách tạo tài khoản, tôi đồng ý với Chính sách bảo mật và Điều khoản dịch vụ.",
            EMAIL_ALREADY_EXISTS: "Địa chỉ email này đã được sử dụng. Sử dụng địa chỉ email khác hoặc thử đặt lại mật khẩu tại đây:",
            validateButtonText: "Xác minh tài khoản",
            otpError: "Mật khẩu duy nhất cung cấp không hợp lệ hoặc đã hết hạn. Thử lại."
        },
        "ko-KR": {
            title: "등록",
            registerButton: "등록",
            googleRegisterButton: "Google로 등록",
            orText: "또는",
            registrationError: "등록에 실패했습니다. 누락 된 정보 또는 잘못된 정보를 확인하십시오.",
            socialRegistrationError: "등록하려면 개인 정보 보호 정책 및 서비스 약관을 읽고 수락하십시오.",
            registrationSuccess: "좋아요! 계정 생성을 확인하기 위해 고유 한 비밀번호가 포함 된 이메일을 받게됩니다. 아래에 고유 한 비밀번호를 입력하십시오.",
            email: "이메일 주소",
            password: "암호",
            displayName: "표시 이름",
            privacyPolicyLinkText: "개인 정보 보호 정책",
            termsOfServiceLinkText: "서비스 약관",
            agreementStatement: "계정을 만들면 개인 정보 보호 정책 및 서비스 약관에 동의합니다.",
            EMAIL_ALREADY_EXISTS: "이 이메일 주소는 이미 사용 중입니다. 다른 이메일 주소를 사용하거나 여기에서 비밀번호를 재설정하십시오:",
            validateButtonText: "계정 확인",
            otpError: "제공된 고유 한 비밀번호가 잘못되었거나 만료되었습니다. 다시 시도하십시오."
        },
        "ja-JP": {
            title: "登録",
            registerButton: "登録",
            googleRegisterButton: "Googleで登録",
            orText: "または",
            registrationError: "登録に失敗しました。不足している情報または無効な情報を確認してください。",
            socialRegistrationError: "登録するには、プライバシーポリシーとサービス規約を読んで受け入れてください。",
            registrationSuccess: "素晴らしい！アカウント作成を確認するために、一意のパスワードが含まれたメールが届きます。以下に一意のパスワードを入力してください。",
            email: "メールアドレス",
            password: "パスワード",
            displayName: "表示名",
            privacyPolicyLinkText: "プライバシーポリシー",
            termsOfServiceLinkText: "利用規約",
            agreementStatement: "アカウントを作成することで、プライバシーポリシーとサービス規約に同意します。",
            EMAIL_ALREADY_EXISTS: "このメールアドレスはすでに使用されています。別のメールアドレスを使用するか、ここでパスワードをリセットしてください：",
            validateButtonText: "アカウントを確認",
            otpError: "提供された一意のパスワードが無効または期限切れです。もう一度お試しください。"
        },
        "ar-IQ": {
            title: "التسجيل",
            registerButton: "التسجيل",
            googleRegisterButton: "التسجيل باستخدام Google",
            orText: "أو",
            registrationError: "فشل التسجيل. يرجى التحقق من المعلومات المفقودة أو غير الصحيحة.",
            socialRegistrationError: "للتسجيل ، يرجى قراءة وقبول سياسة الخصوصية وشروط الخدمة.",
            registrationSuccess: "رائع! ستتلقى بريدًا إلكترونيًا يحتوي على كلمة مرور فريدة لتأكيد إنشاء حسابك. يرجى إدخال كلمة المرور الفريدة أدناه.",
            email: "عنوان البريد الإلكتروني",
            password: "كلمة المرور",
            displayName: "اسم العرض",
            privacyPolicyLinkText: "سياسة الخصوصية",
            termsOfServiceLinkText: "شروط الخدمة",
            agreementStatement: "من خلال إنشاء حساب ، أوافق على سياسة الخصوصية وشروط الخدمة.",
            EMAIL_ALREADY_EXISTS: "هذا البريد الإلكتروني مستخدم بالفعل. استخدم عنوان بريد إلكتروني آخر أو حاول إعادة تعيين كلمة المرور هنا:",
            validateButtonText: "تحقق من الحساب",
            otpError: "كلمة المرور الفريدة المقدمة غير صحيحة أو منتهية الصلاحية. حاول مرة أخرى."
        },
        "fil-PH": {
            title: "Magparehistro",
            registerButton: "Magparehistro",
            googleRegisterButton: "Magparehistro gamit ang Google",
            orText: "o",
            registrationError: "Nabigo ang pagpaparehistro. Mangyaring suriin ang nawawalang impormasyon o hindi wastong impormasyon.",
            socialRegistrationError: "Upang magparehistro, mangyaring basahin at tanggapin ang Patakaran sa Privacy at Mga Tuntunin ng Serbisyo.",
            registrationSuccess: "Astig! Makakatanggap ka ng email na naglalaman ng isang natatanging password upang kumpirmahin ang paglikha ng iyong account. Mangyaring maglagay ng natatanging password sa ibaba.",
            email: "Email Address",
            password: "Password",
            displayName: "Display Name",
            privacyPolicyLinkText: "Patakaran sa Privacy",
            termsOfServiceLinkText: "Mga Tuntunin ng Serbisyo",
            agreementStatement: "Sa paglikha ng account, sumasang-ayon ako sa Patakaran sa Privacy at Mga Tuntunin ng Serbisyo.",
            EMAIL_ALREADY_EXISTS: "Ang email address na ito ay ginagamit na. Gamitin ang ibang email address o subukang i-reset ang password dito:",
            validateButtonText: "I-validate ang Account",
            otpError: "Ang ibinigay na natatanging password ay hindi wasto o expired na. Subukan muli."
        },
        "ne-NP": {
            title: "रेजिस्टर",
            registerButton: "रेजिस्टर",
            googleRegisterButton: "Google बाट रेजिस्टर गर्नुहोस्",
            orText: "वा",
            registrationError: "रेजिस्ट्रेसन असफल भयो। गुम्एको वा अमान्य जानकारीलाई जाँच गर्नुहोस्।",
            socialRegistrationError: "रेजिस्टर गर्नका लागि कृपया गोपनीयता नीति र सेवा शर्तहरू पढ्नुहोस् र स्वीकृत गर्नुहोस्।",
            registrationSuccess: "जबरजस्त! तपाईंको खाता सिर्जना गर्नका लागि एक अनौपचारिक पासवर्ड भएको ईमेल प्राप्त गर्नुहुनेछ। कृपया तलको अनौपचारिक पासवर्ड प्रविष्ट गर्नुहोस्।",
            email: "ईमेल ठेगाना",
            password: "पासवर्ड",
            displayName: "प्रदर्शन नाम",
            privacyPolicyLinkText: "गोपनीयता नीति",
            termsOfServiceLinkText: "सेवा शर्तहरू",
            agreementStatement: "खाता सिर्जना गर्दा, म गोपनीयता नीति र सेवा शर्तहरूमा सहमत छु।",
            EMAIL_ALREADY_EXISTS: "यो ईमेल ठेगाना पहिले नै प्रयोग गरिएको छ। अर्को ईमेल ठेगाना प्रयोग गर्नुहोस् वा यहाँ पासवर्ड रिसेट गर्नुहोस्:",
            validateButtonText: "खाता मान्यता गर्नुहोस्",
            otpError: "दिइएको अनौपचारिक पासवर्ड गलत वा समाप्त भएको छ। पुन: प्रयास गर्नुहोस्।"
        },
        "ar-SA": {
            title: "التسجيل",
            registerButton: "التسجيل",
            googleRegisterButton: "التسجيل باستخدام Google",
            orText: "أو",
            registrationError: "فشل التسجيل. يرجى التحقق من المعلومات المفقودة أو غير الصحيحة.",
            socialRegistrationError: "للتسجيل ، يرجى قراءة وقبول سياسة الخصوصية وشروط الخدمة.",
            registrationSuccess: "رائع! ستتلقى بريدًا إلكترونيًا يحتوي على كلمة مرور مؤقتة لتأكيد إنشاء حسابك. يرجى إدخال كلمة مرور مؤقتة أدناه.",
            email: "عنوان البريد الإلكتروني",
            password: "كلمة المرور",
            displayName: "اسم العرض",
            privacyPolicyLinkText: "سياسة الخصوصية",
            termsOfServiceLinkText: "شروط الخدمة",
            agreementStatement: "من خلال إنشاء حساب ، أوافق على سياسة الخصوصية وشروط الخدمة.",
            EMAIL_ALREADY_EXISTS: "هذا البريد الإلكتروني قيد الاستخدام بالفعل. استخدم عنوان بريد إلكتروني آخر أو حاول إعادة تعيين كلمة المرور هنا:",
            validateButtonText: "تحقق من الحساب",
            otpError: "كلمة المرور المؤقتة التي تم إدخالها غير صحيحة أو منتهية. يرجى المحاولة مرة أخرى."
        },
        "am-ET": {
            title: "ምዝገባ",
            registerButton: "ምዝገባ",
            googleRegisterButton: "Google በመዝገብ ምዝገባ",
            orText: "ወይም",
            registrationError: "ምዝገባ አልተሳካም። አስተካክል ወይም ስህተት አልተሳካም።",
            socialRegistrationError: "ምዝገባ ለመመዝገብ እባኮት የግል ፍላጎትን እና የአገልግሎት ግንኙነትን አካል አድርገናል።",
            registrationSuccess: "እንኳን ደህና መጡ! እባኮት እርስዎ መልዕክት ለመረዳት እባኮት እዚህ ይጠቀሙ።",
            email: "ኢሜል አድራሻ",
            password: "የይለፍ ቃል",
            displayName: "ስም ማህበረሰብ",
            privacyPolicyLinkText: "ፍላጎት መረጃ",
            termsOfServiceLinkText: "አገልግሎት ሁኔታ",
            agreementStatement: "እኔ አንድ ነኝ! እባኮት እርስዎ መልዕክት ለመረዳት እባኮት እዚህ ይጠቀሙ።",
            EMAIL_ALREADY_EXISTS: "ይህ ኢሜል አድራሻ በተጠቃሚ ነው። እባኮት ኢሜል አድራሻ ሌላ እና እባኮት የይለፍ ቃልዎን እንደገና ይሞክሩ:",
            validateButtonText: "እርስዎን ማረጋገጥ",
            otpError: "የሚከተለው የይለፍ ቃል በተሳካ ሰው አይነት ነው ወይም በትክክል ነው። እባኮት ይሞክሩ።"
        }
    },
    resetPasswordScreenContent: {
        "en-US": {
            title: "Password Reset",
            resetButton: "Request Password Reset",
            email: "Email Address",
            loginLinkText: "Return to login page",
            successText: "Your password reset request has been received. If we have your email address on file, you will receive an email with instructions on how to reset your password.",
            returnToLoginText: "You will be redirected to the login page in 5 seconds.",
        },
        "en-GB": {
            title: "Password Reset",
            resetButton: "Request Password Reset",
            email: "Email Address",
            loginLinkText: "Return to login page",
            successText: "Your password reset request has been received. If we have your email address on file, you will receive an email with instructions on how to reset your password.",
            returnToLoginText: "You will be redirected to the login page in 5 seconds.",
        },
        "es-ES": {
            title: "Restablecimiento de contraseña",
            resetButton: "Restablecer contraseña",
            email: "Correo Electrónico",
            loginLinkText: "Regresar a la página de inicio de sesión",
            successText: "Su solicitud de restablecimiento de contraseña ha sido recibida. Si tenemos su dirección de correo electrónico en el archivo, recibirá un correo electrónico con instrucciones sobre cómo restablecer su contraseña.",
            returnToLoginText: "Será redirigido a la página de inicio de sesión en 5 segundos.",
        },
        "es-MX": {
            title: "Restablecimiento de contraseña",
            resetButton: "Restablecer contraseña",
            email: "Correo Electrónico",
            loginLinkText: "Regresar a la página de inicio de sesión",
            successText: "Su solicitud de restablecimiento de contraseña ha sido recibida. Si tenemos su dirección de correo electrónico en el archivo, recibirá un correo electrónico con instrucciones sobre cómo restablecer su contraseña.",
            returnToLoginText: "Será redirigido a la página de inicio de sesión en 5 segundos.",
        },
        "fr-FR": {
            title: "Réinitialisation du mot de passe",
            resetButton: "Demande de réinitialisation du mot de passe",
            email: "Adresse électronique",
            loginLinkText: "Retourner à la page de connexion",
            successText: "Votre demande de réinitialisation de mot de passe a été reçue. Si nous avons votre adresse électronique dans nos dossiers, vous recevrez un courriel avec des instructions sur la façon de réinitialiser votre mot de passe.",
            returnToLoginText: "Vous serez redirigé vers la page de connexion dans 5 secondes.",
        },
        "de-DE": {
            title: "Passwort zurücksetzen",
            resetButton: "Passwort zurücksetzen",
            email: "E-Mail-Adresse",
            loginLinkText: "Zurück zur Anmeldeseite",
            successText: "Ihr Passwortzurücksetzungsantrag wurde erhalten. Wenn wir Ihre E-Mail-Adresse in unseren Unterlagen haben, erhalten Sie eine E-Mail mit Anweisungen, wie Sie Ihr Passwort zurücksetzen können.",
            returnToLoginText: "Sie werden in 5 Sekunden zur Anmeldeseite weitergeleitet.",
        },
        "it-IT": {
            title: "Reimposta password",
            resetButton: "Richiesta di reimpostazione della password",
            email: "Indirizzo Email",
            loginLinkText: "Torna alla pagina di accesso",
            successText: "La tua richiesta di reimpostazione della password è stata ricevuta. Se abbiamo il tuo indirizzo email nei nostri file, riceverai un'email con le istruzioni su come reimpostare la tua password.",
            returnToLoginText: "Verrai reindirizzato alla pagina di accesso in 5 secondi.",
        }
        ,
        "hi-IN": {
            title: "पासवर्ड रीसेट",
            resetButton: "पासवर्ड रीसेट का अनुरोध करें",
            email: "ईमेल पता",
            loginLinkText: "लॉगिन पेज पर वापस जाएँ",
            successText: "आपका पासवर्ड रीसेट अनुरोध प्राप्त हो गया है। यदि हमारे पास आपका ईमेल पता है, तो आपको एक ईमेल मिलेगा जिसमें आपको अपना पासवर्ड रीसेट करने के निर्देश मिलेंगे।",
            returnToLoginText: "आपको 5 सेकंड में लॉगिन पेज पर रीडायरेक्ट किया जाएगा।",
        },
        "cmn-CN": {
            title: "重置密码",
            resetButton: "请求重置密码",
            email: "电子邮件地址",
            loginLinkText: "返回登录页面",
            successText: "您的密码重置请求已收到。如果我们有您的电子邮件地址，您将收到一封电子邮件，其中包含有关如何重置密码的说明。",
            returnToLoginText: "您将在5秒钟内被重定向到登录页面。",
        },
        "so-SO": {
            title: "Isbeddel Furaha",
            resetButton: "Codsi Furaha Isbeddel",
            email: "Emaylka",
            loginLinkText: "Ku soo noqo bogga gudaha",
            successText: "Codsigayga furaha isbeddel waxaa la helay. Haddii aanu leenahay emaylkaaga in xogtaaga, waxaad hesho emayl oo ku saabsan sida aad furahaaga isbeddesho.",
            returnToLoginText: "Waxaad ku soo noqon doontaa bogga gudaha 5 ilaa 5 daqiiqo.",
        },
        "pt-PT": {
            title: "Redefinição de palavra-passe",
            resetButton: "Solicitar redefinição de palavra-passe",
            email: "Endereço de e-mail",
            loginLinkText: "Voltar à página de início de sessão",
            successText: "Recebemos o seu pedido de redefinição de palavra-passe. Se tivermos o seu endereço de e-mail nos nossos registos, receberá um e-mail com instruções sobre como redefinir a sua palavra-passe.",
            returnToLoginText: "Será redirecionado para a página de início de sessão em 5 segundos.",
        },
        "pt-BR": {
            title: "Redefinição de senha",
            resetButton: "Solicitar redefinição de senha",
            email: "Endereço de e-mail",
            loginLinkText: "Voltar à página de login",
            successText: "Recebemos sua solicitação de redefinição de senha. Se tivermos seu endereço de e-mail em nossos registros, você receberá um e-mail com instruções sobre como redefinir sua senha.",
            returnToLoginText: "Você será redirecionado para a página de login em 5 segundos.",
        },
        "id-ID": {
            title: "Reset Kata Sandi",
            resetButton: "Minta Reset Kata Sandi",
            email: "Alamat Email",
            loginLinkText: "Kembali ke halaman login",
            successText: "Permintaan reset kata sandi Anda telah diterima. Jika kami memiliki alamat email Anda dalam catatan kami, Anda akan menerima email dengan petunjuk tentang cara mereset kata sandi Anda.",
            returnToLoginText: "Anda akan diarahkan ke halaman login dalam 5 detik.",
        },
        "vi-VN": {
            title: "Đặt lại mật khẩu",
            resetButton: "Yêu cầu đặt lại mật khẩu",
            email: "Địa chỉ Email",
            loginLinkText: "Quay lại trang đăng nhập",
            successText: "Yêu cầu đặt lại mật khẩu của bạn đã được nhận. Nếu chúng tôi có địa chỉ email của bạn trong hồ sơ của chúng tôi, bạn sẽ nhận được một email chứa hướng dẫn về cách đặt lại mật khẩu của bạn.",
            returnToLoginText: "Bạn sẽ được chuyển hướng đến trang đăng nhập trong 5 giây.",
        },
        "ko-KR": {
            title: "비밀번호 재설정",
            resetButton: "비밀번호 재설정 요청",
            email: "이메일 주소",
            loginLinkText: "로그인 페이지로 돌아가기",
            successText: "비밀번호 재설정 요청이 접수되었습니다. 당사의 기록에 이메일 주소가 있으면 비밀번호 재설정 방법에 대한 안내가 포함된 이메일을 받게 됩니다.",
            returnToLoginText: "5초 후에 로그인 페이지로 이동됩니다.",
        },
        "ja-JP": {
            title: "パスワードリセット",
            resetButton: "パスワードリセットのリクエスト",
            email: "メールアドレス",
            loginLinkText: "ログインページに戻る",
            successText: "パスワードリセットリクエストを受け付けました。弊社の記録にメールアドレスがある場合、パスワードをリセットする方法についての説明が含まれたメールを受信します。",
            returnToLoginText: "5秒後にログインページにリダイレクトされます。",
        },
        "ar-IQ": {
            title: "إعادة تعيين كلمة المرور",
            resetButton: "طلب إعادة تعيين كلمة المرور",
            email: "عنوان البريد الإلكتروني",
            loginLinkText: "العودة إلى صفحة تسجيل الدخول",
            successText: "تم استلام طلب إعادة تعيين كلمة المرور الخاص بك. إذا كان لدينا عنوان بريدك الإلكتروني في سجلاتنا، فستتلقى بريدًا إلكترونيًا يحتوي على تعليمات حول كيفية إعادة تعيين كلمة المرور الخاصة بك.",
            returnToLoginText: "سيتم توجيهك إلى صفحة تسجيل الدخول خلال 5 ثوانٍ.",
        },
        "fil-PH": {
            title: "I-reset ang Password",
            resetButton: "Humiling ng I-reset ang Password",
            email: "Email Address",
            loginLinkText: "Bumalik sa pahina ng login",
            successText: "Natanggap namin ang iyong kahilingan na i-reset ang password. Kung mayroon kaming iyong email address sa aming mga talaan, makakatanggap ka ng email na may mga tagubilin kung paano i-reset ang iyong password.",
            returnToLoginText: "Ikaw ay ililipat pabalik sa pahina ng login sa loob ng 5 segundo.",
        },
        "ne-NP": {
            title: "पासवर्ड रिसेट",
            resetButton: "पासवर्ड रिसेटको अनुरोध",
            email: "ईमेल ठेगाना",
            loginLinkText: "लगइन पेजमा फर्किनुहोस्",
            successText: "तपाईंको पासवर्ड रिसेटको अनुरोध स्वीकृत भएको छ। हाम्रो रेकर्डमा तपाईंको ईमेल ठेगाना छ भने, तपाईंले आफ्नो पासवर्ड रिसेट गर्ने तरिकाको जानकारी समेत भएको ईमेल प्राप्त गर्नुहुनेछ।",
            returnToLoginText: "तपाईंलाई 5 सेकेण्डमा लगइन पेजमा पुनः पठाइनेछ।",
        },
        "ar-SA": {
            title: "إعادة تعيين كلمة المرور",
            resetButton: "طلب إعادة تعيين كلمة المرور",
            email: "عنوان البريد الإلكتروني",
            loginLinkText: "العودة إلى صفحة تسجيل الدخول",
            successText: "تم استلام طلب إعادة تعيين كلمة المرور الخاص بك. إذا كان لدينا عنوان بريدك الإلكتروني في سجلاتنا، فستتلقى بريدًا إلكترونيًا يحتوي على تعليمات حول كيفية إعادة تعيين كلمة المرور الخاصة بك.",
            returnToLoginText: "سيتم توجيهك إلى صفحة تسجيل الدخول خلال 5 ثوانٍ.",
        },
        "am-ET": {
            title: "የይለፍ ቃል አድርግ",
            resetButton: "የይለፍ ቃል አድርገኛል",
            email: "ኢሜል አድራሻ",
            loginLinkText: "ወደ ግባት ገብተው ይሄን ገጽ ይምረጡ",
            successText: "የይለፍ ቃል አድርገኛል በሚባለው ማሳያ እንደሚሆን እናመሰግናለን። እስካሁን ኢሜል አድራሻዎን እንደሚያስተዳደር እባኮት ኢሜል አድራሻ እናደርሳለን።",
            returnToLoginText: "እስከ 5 ሰከንድ ወደ ግባት ገብተው ይመለሱ።",
        }
    },
    leftSidebarContent: {
        "en-US": {
            notificationsSubtext: "Notifications",
            groupHoverDescription: "Group chat allows you chat with multiple people from many locations.",
            translateHoverDescription: "Translate chats are personal translators that quickly translate words and phrases just for you.",
            sharedScreenHoverDescription: "Shared screen chats are multi-lingual chats with another person on the same device.",
            quickConnectHoverDescription: "Quick-connect chats are short chats that allow you to invite and communicate with non-registered users.",
            autoplaySubtext: "Autoplay",
            groupSubtext: "Group",
            translateSubtext: "Translate",
            sharedSubtext: "Shared Screen",
            addSubtext: "New Chat",
            accountSubtext: "Account",
            settingsSubtext: "Settings",
            siteInfoSubtext: "Site Info",
            quickConnectSubtext: "Quick Connect",
        },
        "en-GB": {
            notificationsSubtext: "Notifications",
            groupHoverDescription: "Group chat allows you chat with multiple people from many locations.",
            translateHoverDescription: "Translate chats are personal translators that quickly translate words and phrases just for you.",
            sharedScreenHoverDescription: "Shared screen chats are multi-lingual chats with another person on the same device.",
            quickConnectHoverDescription: "Quick-connect chats are short chats that allow you to invite and communicate with non-registered users.",
            autoplaySubtext: "Autoplay",
            groupSubtext: "Group",
            translateSubtext: "Translate",
            sharedSubtext: "Shared Screen",
            addSubtext: "New Chat",
            accountSubtext: "Account",
            settingsSubtext: "Settings",
            siteInfoSubtext: "Site Info",
            quickConnectSubtext: "Quick Connect",
        },
        "es-ES": {
            notificationsSubtext: "Notificaciones",
            groupHoverDescription: "El chat grupal te permite chatear con varias personas de diferentes lugares.",
            translateHoverDescription: "Los chats de traducción son traductores personales que traducen rápidamente palabras y frases solo para ti.",
            sharedScreenHoverDescription: "Los chats de pantalla compartida son chats multilingües con otra persona en el mismo dispositivo.",
            quickConnectHoverDescription: "Los chats de conexión rápida son chats cortos que te permiten invitar y comunicarte con usuarios no registrados.",
            autoplaySubtext: "Reproducción automática",
            groupSubtext: "Grupo",
            translateSubtext: "Traducir",
            sharedSubtext: "Pantalla compartida",
            addSubtext: "Chat Nuevo",
            accountSubtext: "Cuenta",
            settingsSubtext: "Configuración",
            siteInfoSubtext: "Información del sitio",
            quickConnectSubtext: "Conexión rápida",

        },
        "es-MX": {
            notificationsSubtext: "Notificaciones",
            groupHoverDescription: "El chat grupal te permite chatear con varias personas de diferentes lugares.",
            translateHoverDescription: "Los chats de traducción son traductores personales que traducen rápidamente palabras y frases solo para ti.",
            sharedScreenHoverDescription: "Los chats de pantalla compartida son chats multilingües con otra persona en el mismo dispositivo.",
            quickConnectHoverDescription: "Los chats de conexión rápida son chats cortos que te permiten invitar y comunicarte con usuarios no registrados.",
            autoplaySubtext: "Reproducción automática",
            groupSubtext: "Grupo",
            translateSubtext: "Traducir",
            sharedSubtext: "Pantalla compartida",
            addSubtext: "Chat Nuevo",
            accountSubtext: "Cuenta",
            settingsSubtext: "Configuración",
            siteInfoSubtext: "Información del sitio",
            quickConnectSubtext: "Conexión rápida",
        },
        "fr-FR": {
            notificationsSubtext: "Notifications",
            groupHoverDescription: "Le chat de groupe vous permet de discuter avec plusieurs personnes de différents endroits.",
            translateHoverDescription: "Les chats de traduction sont des traducteurs personnels qui traduisent rapidement des mots et des phrases uniquement pour vous.",
            sharedScreenHoverDescription: "Les chats d'écran partagé sont des chats multilingues avec une autre personne sur le même appareil.",
            quickConnectHoverDescription: "Les chats de connexion rapide sont des chats courts qui vous permettent d'inviter et de communiquer avec des utilisateurs non enregistrés.",
            autoplaySubtext: "Lecture automatique",
            groupSubtext: "Groupe",
            translateSubtext: "Traduire",
            sharedSubtext: "Écran partagé",
            addSubtext: "Nouveau Chat",
            accountSubtext: "Compte",
            settingsSubtext: "Paramètres",
            siteInfoSubtext: "Informations sur le site",
            quickConnectSubtext: "Connexion rapide",
        },
        "de-DE": {
            notificationsSubtext: "Benachrichtigungen",
            groupHoverDescription: "Gruppenchat ermöglicht es Ihnen, mit mehreren Personen aus verschiedenen Orten zu chatten.",
            translateHoverDescription: "Übersetzen Chats sind persönliche Übersetzer, die Wörter und Phrasen schnell nur für Sie übersetzen.",
            sharedScreenHoverDescription: "Geteilte Bildschirm-Chats sind mehrsprachige Chats mit einer anderen Person auf demselben Gerät.",
            quickConnectHoverDescription: "Schnellverbindungschats sind kurze Chats, die es Ihnen ermöglichen, nicht registrierte Benutzer einzuladen und mit ihnen zu kommunizieren.",
            autoplaySubtext: "Automatische Wiedergabe",
            groupSubtext: "Gruppe",
            translateSubtext: "Übersetzen",
            sharedSubtext: "Gemeinsamer Bildschirm",
            addSubtext: "Neuer Chat",
            accountSubtext: "Konto",
            settingsSubtext: "Einstellungen",
            siteInfoSubtext: "Website-Informationen",
            quickConnectSubtext: "Schnellverbindung",
        },
        "it-IT": {
            notificationsSubtext: "Notifiche",
            groupHoverDescription: "La chat di gruppo ti consente di chattare con più persone da luoghi diversi.",
            translateHoverDescription: "Le chat di traduzione sono traduttori personali che traducono rapidamente parole e frasi solo per te.",
            sharedScreenHoverDescription: "Le chat dello schermo condiviso sono chat multilingue con un'altra persona sullo stesso dispositivo.",
            quickConnectHoverDescription: "Le chat di connessione rapida sono chat brevi che ti consentono di invitare e comunicare con utenti non registrati.",
            autoplaySubtext: "Riproduzione automatica",
            groupSubtext: "Gruppo",
            translateSubtext: "Traduci",
            sharedSubtext: "Schermo condiviso",
            addSubtext: "Chat Nuova",
            accountSubtext: "Conto",
            settingsSubtext: "Impostazioni",
            siteInfoSubtext: "Informazioni sul sito",
            quickConnectSubtext: "Connessione rapida",
        },
        "hi-IN": {
            notificationsSubtext: "सूचनाएँ",
            groupHoverDescription: "समूह चैट आपको विभिन्न स्थानों से कई लोगों के साथ चैट करने की सुविधा प्रदान करता है।",
            translateHoverDescription: "अनुवाद चैट व्यक्तिगत अनुवादक हैं जो शब्द और वाक्य आपके लिए केवल तेजी से अनुवाद करते हैं।",
            sharedScreenHoverDescription: "साझा स्क्रीन चैट एक दूसरे व्यक्ति के साथ एक ही उपकरण पर बहुभाषी चैट हैं।",
            quickConnectHoverDescription: "त्वरित कनेक्ट चैट छोटे चैट हैं जो आपको अपने साथ ना रजिस्टर उपयोगकर्ताओं को आमंत्रित करने और उनके साथ संवाद करने की अनुमति देते हैं।",
            autoplaySubtext: "स्वतः चलना",
            groupSubtext: "समूह",
            translateSubtext: "अनुवाद करें",
            sharedSubtext: "साझा स्क्रीन",
            addSubtext: "नया चैट",
            accountSubtext: "खाता",
            settingsSubtext: "सेटिंग्स",
            siteInfoSubtext: "साइट जानकारी",
            quickConnectSubtext: "त्वरित कनेक्ट",
        },
        "cmn-CN": {
            notificationsSubtext: "通知",
            groupHoverDescription: "群组聊天使您能够与来自不同地方的多人聊天。",
            translateHoverDescription: "翻译聊天是个人翻译器，可以快速为您翻译单词和短语。",
            sharedScreenHoverDescription: "共享屏幕聊天是与另一个人在同一设备上进行的多语言聊天。",
            quickConnectHoverDescription: "快速连接聊天是短暂的聊天，允许您邀请未注册用户并与他们交流。",
            autoplaySubtext: "自动播放",
            groupSubtext: "组",
            translateSubtext: "翻译",
            sharedSubtext: "共享屏幕",
            addSubtext: "新聊天",
            accountSubtext: "帐户",
            settingsSubtext: "设置",
            siteInfoSubtext: "网站信息",
            quickConnectSubtext: "快速连接",
        },
        "so-SO": {
            notificationsSubtext: "Ogeysiisyada",
            groupHoverDescription: "Group chat waxaa kuu siinaysaa fursad aad kula wadaagto dad badan oo ka yimid meelaha kala duwan.",
            translateHoverDescription: "Translate chat waxaa ah turjubaan shakhsi ah oo turjumaaya ereyo iyo qoraalo si aad u turjumaato.",
            sharedScreenHoverDescription: "Shared screen chat waxaa ah chat luuqadooda ah oo la wadaagayo qof kale ee qalabka kale.",
            quickConnectHoverDescription: "Quick connect chat waxaa ah chat yar oo kuu oggolaanaya inaad ku dhiirigelinaysid iyo kula wadaagtid isticmaaleyaasha aan la daabacin.",
            autoplaySubtext: "Autoplay",
            groupSubtext: "Koox",
            translateSubtext: "Turjuma",
            sharedSubtext: "Goobta La Wadaagay",
            addSubtext: "Casho Cusub",
            accountSubtext: "Xisaab",
            settingsSubtext: "Go'aan",
            siteInfoSubtext: "Macluumaadka Bogga",
            quickConnectSubtext: "Ku Xidhnow",
        },
        "pt-PT": {
            notificationsSubtext: "Notificações",
            groupHoverDescription: "O chat em grupo permite-lhe conversar com várias pessoas de diferentes locais.",
            translateHoverDescription: "O chat de tradução é um tradutor pessoal que traduz rapidamente palavras e frases para si.",
            sharedScreenHoverDescription: "O chat de ecrã partilhado é um chat multilingue com outra pessoa no mesmo dispositivo.",
            quickConnectHoverDescription: "O chat de ligação rápida é um chat breve que lhe permite convidar utilizadores não registados e comunicar com eles.",
            autoplaySubtext: "Reprodução automática",
            groupSubtext: "Grupo",
            translateSubtext: "Traduzir",
            sharedSubtext: "Tela compartilhada",
            addSubtext: "Nova Conversa",
            accountSubtext: "Conta",
            settingsSubtext: "Configurações",
            siteInfoSubtext: "Informações do site",
            quickConnectSubtext: "Conexão rápida",
        },
        "pt-BR": {
            notificationsSubtext: "Notificações",
            groupHoverDescription: "O chat em grupo permite-lhe conversar com várias pessoas de diferentes locais.",
            translateHoverDescription: "O chat de tradução é um tradutor pessoal que traduz rapidamente palavras e frases para si.",
            sharedScreenHoverDescription: "O chat de ecrã partilhado é um chat multilingue com outra pessoa no mesmo dispositivo.",
            quickConnectHoverDescription: "O chat de ligação rápida é um chat breve que lhe permite convidar utilizadores não registados e comunicar com eles.",
            autoplaySubtext: "Reprodução automática",
            groupSubtext: "Grupo",
            translateSubtext: "Traduzir",
            sharedSubtext: "Tela compartilhada",
            addSubtext: "Nova Conversa",
            accountSubtext: "Conta",
            settingsSubtext: "Configurações",
            siteInfoSubtext: "Informações do site",
            quickConnectSubtext: "Conexão rápida",
        },
        "id-ID": {
            notificationsSubtext: "Pemberitahuan",
            groupHoverDescription: "Obrolan grup memungkinkan Anda berbicara dengan banyak orang dari lokasi yang berbeda.",
            translateHoverDescription: "Obrolan terjemahan adalah penerjemah pribadi yang dapat menerjemahkan kata dan frasa dengan cepat untuk Anda.",
            sharedScreenHoverDescription: "Obrolan layar bersama adalah obrolan multibahasa dengan orang lain di perangkat yang sama.",
            quickConnectHoverDescription: "Obrolan koneksi cepat adalah obrolan singkat yang memungkinkan Anda mengundang pengguna yang tidak terdaftar dan berkomunikasi dengan mereka.",
            autoplaySubtext: "Putar Otomatis",
            groupSubtext: "Grup",
            translateSubtext: "Terjemahkan",
            sharedSubtext: "Layar Bersama",
            addSubtext: "Obrolan Baru",
            accountSubtext: "Akun",
            settingsSubtext: "Pengaturan",
            siteInfoSubtext: "Informasi Situs",
            quickConnectSubtext: "Koneksi Cepat",
        },
        "vi-VN": {
            notificationsSubtext: "Thông báo",
            groupHoverDescription: "Trò chuyện nhóm cho phép bạn trò chuyện với nhiều người từ nhiều địa điểm khác nhau.",
            translateHoverDescription: "Trò chuyện dịch là một trình dịch cá nhân có thể dịch từng từ và cụm từ nhanh chóng cho bạn.",
            sharedScreenHoverDescription: "Trò chuyện trên màn hình được chia sẻ là cuộc trò chuyện đa ngôn ngữ với người khác trên cùng một thiết bị.",
            autoplaySubtext: "Trò chuyện kết nối nhanh là những cuộc trò chuyện ngắn cho phép bạn mời và liên lạc với những người dùng chưa đăng ký.",
            groupSubtext: "Nhóm",
            translateSubtext: "Dịch",
            sharedSubtext: "Màn hình chia sẻ",
            addSubtext: "Trò chuyện mới",
            accountSubtext: "Tài khoản",
            settingsSubtext: "Cài đặt",
            siteInfoSubtext: "Thông tin trang web",
            quickConnectSubtext: "Kết nối nhanh",
        },
        "ko-KR": {
            notificationsSubtext: "알림",
            groupHoverDescription: "그룹 채팅을 통해 여러 위치의 여러 사람들과 대화할 수 있습니다.",
            translateHoverDescription: "번역 채팅은 단어와 구문을 빠르게 번역해주는 개인 번역기입니다.",
            sharedScreenHoverDescription: "공유 화면 채팅은 동일한 장치에서 다른 사람과의 다국어 채팅입니다.",
            quickConnectHoverDescription: "빠른 연결 채팅은 등록되지 않은 사용자를 초대하고 그들과 통신할 수 있는 간단한 채팅입니다.",
            autoplaySubtext: "자동 재생",
            groupSubtext: "그룹",
            translateSubtext: "번역",
            sharedSubtext: "공유 화면",
            addSubtext: "새 채팅",
            accountSubtext: "계정",
            settingsSubtext: "설정",
            siteInfoSubtext: "사이트 정보",
            quickConnectSubtext: "빠른 연결",
        },
        "ja-JP": {
            notificationsSubtext: "通知",
            groupHoverDescription: "グループチャットを使用すると、さまざまな場所から多くの人とチャットできます。",
            translateHoverDescription: "翻訳チャットは、個人用の翻訳機で、単語やフレーズを素早く翻訳します。",
            sharedScreenHoverDescription: "共有画面チャットは、同じデバイス上で他の人との多言語チャットです。",
            quickConnectHoverDescription: "クイック接続チャットは、登録されていないユーザーを招待し、彼らとコミュニケーションを取るための短いチャットです。",
            autoplaySubtext: "自動再生",
            groupSubtext: "グループ",
            translateSubtext: "翻訳",
            sharedSubtext: "共有画面",
            addSubtext: "新しいチャット",
            accountSubtext: "アカウント",
            settingsSubtext: "設定",
            siteInfoSubtext: "サイト情報",
            quickConnectSubtext: "クイック接続",
        },
        "ar-IQ": {
            notificationsSubtext: "الإشعارات",
            groupHoverDescription: "يتيح لك الدردشة الجماعية التحدث مع العديد من الأشخاص من أماكن مختلفة.",
            translateHoverDescription: "الدردشة الترجمة هي مترجم شخصي يمكنه ترجمة الكلمات والعبارات بسرعة لك.",
            sharedScreenHoverDescription: "الدردشة على الشاشة المشتركة هي دردشة متعددة اللغات مع الآخرين على نفس الجهاز.",
            quickConnectHoverDescription: "الدردشة السريعة هي دردشة قصيرة تتيح لك دعوة المستخدمين غير المسجلين والتواصل معهم.",
            autoplaySubtext: "التشغيل التلقائي",
            groupSubtext: "مجموعة",
            translateSubtext: "ترجمة",
            sharedSubtext: "شاشة مشتركة",
            addSubtext: "دردشة جديدة",
            accountSubtext: "حساب",
            settingsSubtext: "الإعدادات",
            siteInfoSubtext: "معلومات الموقع",
            quickConnectSubtext: "الاتصال السريع",
        },
        "fil-PH": {
            notificationsSubtext: "Mga Abiso",
            groupHoverDescription: "Sa pamamagitan ng Group Chat, maaari kang makipag-usap sa maraming tao mula sa iba't ibang mga lugar.",
            translateHoverDescription: "Ang Translate Chat ay isang personal na tagasalin na maaaring mag-translate ng mga salita at mga parirala nang mabilis para sa iyo.",
            sharedScreenHoverDescription: "Ang Shared Screen Chat ay isang multi-lingual chat kasama ang iba sa parehong device.",
            quickConnectHoverDescription: "Ang Quick Connect Chat ay isang maikling chat na nagbibigay-daan sa iyo na imbitahan ang mga hindi rehistradong gumagamit at makipag-ugnayan sa kanila.",
            autoplaySubtext: "Auto-play",
            groupSubtext: "Grupo",
            translateSubtext: "Isalin",
            sharedSubtext: "Bahagi ang Screen",
            addSubtext: "Bagong Usapan",
            accountSubtext: "Account",
            settingsSubtext: "Mga Setting",
            siteInfoSubtext: "Impormasyon ng Site",
            quickConnectSubtext: "Mabilis na Koneksyon",
        },
        "ne-NP": {
            notificationsSubtext: "सूचनाहरू",
            groupHoverDescription: "समूह च्याटले विभिन्न स्थानबाट धेरै व्यक्तिहरूसँग च्याट गर्न अनुमति दिन्छ।",
            translateHoverDescription: "अनुवाद च्याट व्यक्तिगत अनुवादक हो जसले शब्द र वाक्यहरूलाई तपाईंको लागि तेजीले अनुवाद गर्न सक्दछ।",
            sharedScreenHoverDescription: "साझा स्क्रिन च्याट एक बहुभाषिक च्याट हो जसमा अन्यहरूसँग एउटै उपकरणमा छ।",
            quickConnectHoverDescription: "तेजीले कनेक्ट गर्नुहोस् च्याट एक छोटो च्याट हो जसले तपाईंलाई अनर्जिस्टर गर्ने प्रयोगकर्ताहरूलाई आमंत्रित गर्न र उनीहरूसँग संवाद गर्न अनुमति दिन्छ।",
            autoplaySubtext: "स्वत: चलाउनुहोस्",
            groupSubtext: "समूह",
            translateSubtext: "अनुवाद",
            sharedSubtext: "साझा स्क्रिन",
            addSubtext: "नयाँ च्याट",
            accountSubtext: "खाता",
            settingsSubtext: "सेटिङ्गहरू",
            siteInfoSubtext: "साइट जानकारी",
            quickConnectSubtext: "तेजीले कनेक्ट गर्नुहोस्",
        },
        "ar-SA": {
            notificationsSubtext: "الإشعارات",
            groupHoverDescription: "مع دردشة المجموعة، يمكنك التحدث مع العديد من الأشخاص من أماكن مختلفة.",
            translateHoverDescription: "تعتبر دردشة الترجمة مترجمًا شخصيًا يمكنه ترجمة الكلمات والعبارات بسرعة بالنسبة لك.",
            sharedScreenHoverDescription: "تعتبر دردشة الشاشة المشتركة دردشة متعددة اللغات مع الآخرين على نفس الجهاز.",
            quickConnectHoverDescription: "تعتبر دردشة الاتصال السريع دردشة قصيرة تتيح لك دعوة المستخدمين غير المسجلين والتواصل معهم.",
            autoplaySubtext: "التشغيل التلقائي",
            groupSubtext: "مجموعة",
            translateSubtext: "ترجمة",
            sharedSubtext: "شاشة مشتركة",
            addSubtext: "دردشة جديدة",
            accountSubtext: "حساب",
            settingsSubtext: "الإعدادات",
            siteInfoSubtext: "معلومات الموقع",
            quickConnectSubtext: "الاتصال السريع",
        },
        "am-ET": {
            notificationsSubtext: "ማሳወቅያዊ",
            groupHoverDescription: "ቡድን የተለያዩ ሰዎችን ከተለያዩ ቦታዎች ጋር እንደምንቀጥል እናቀርባለን።",
            translateHoverDescription: "ቻት ተርጉም ቃላትን እና ሀረጎችን ለእርስዎ ብቻ የሚተረጉሙ የግል ተርጓሚዎች ናቸው።",
            sharedScreenHoverDescription: "አካባቢ አገናኝ ቡድን በአካባቢ አገናኝ ላይ ሌሎችን ከሚከተለው በተለያዩ መልኩ እንደምንቀጥል እናቀርባለን።",
            quickConnectHoverDescription: "ፈጣን ግንኙነት ያላቸው ቻቶች ላልተመዘገቡ ተጠቃሚዎች እንዲጋብዙ እና እንዲገናኙ የሚያስችልዎ አጫጭር ቻቶች ናቸው።",
            autoplaySubtext: "እንደምንቀጥል",
            groupSubtext: "ቡድን",
            translateSubtext: "ተርምሶ",
            sharedSubtext: "አካባቢ አገናኝ",
            addSubtext: "አዲስ ውይይት",
            accountSubtext: "መለያ",
            settingsSubtext: "ቅንብሮች",
            siteInfoSubtext: "የድረ-ገጽ መረጃ",
            quickConnectSubtext: "በትክክል ይቀጥሉ",

        }

    },
    chatScreenContent: {
        "en-US": {
            notificationsModalHeader: "Notifications",
            conversationModalHeader: "Group Conversations",
            translateModalHeader: "Personal Translations",
            sharedModalHeader: "Shared Screen",
            quickConnectModalHeader: "Quick Connect",
            noMessagesGroup: "There are no messages yet. Send a message below or add new members here:",
            noMessagesTranslation: "There are no translations yet. Submit some text to translate below.",
            noMessagesQuickConnect: "There no are messages yet. Send an opening message below.",
            noMessagesSharedScreen: "There no are messages yet. Send an opening message below.",
            myLanguageTabLabel: "My Language",
            responseFailure: "Hmm. Something went wrong. Please repeat that.",
            couldNotUnderstand: "Sorry, I could not understand. Please repeat that."
        },
        "en-GB": {
            notificationsModalHeader: "Notifications",
            conversationModalHeader: "Group Conversations",
            translateModalHeader: "Personal Translations",
            sharedModalHeader: "Shared Screen",
            quickConnectModalHeader: "Quick Connect",
            noMessagesGroup: "There are no messages yet. Send a message below or add new members here:",
            noMessagesTranslation: "There are no translations yet. Submit some text to translate below.",
            noMessagesQuickConnect: "There are no messages yet. Send an opening message below.",
            noMessagesSharedScreen: "There are no messages yet. Send an opening message below.",
            myLanguageTabLabel: "My Language",
            responseFailure: "Hmm. Something went wrong. Please repeat that.",
            couldNotUnderstand: "Sorry, I could not understand. Please repeat that."
        },
        "es-ES": {
            notificationsModalHeader: "Notificaciones",
            conversationModalHeader: "Conversaciones Grupales",
            translateModalHeader: "Traducciones Personales",
            sharedModalHeader: "Pantalla Compartida",
            quickConnectModalHeader: "Conexión Rápida",
            noMessagesGroup: "Aún no hay mensajes. Envía un mensaje a continuación o añade nuevos miembros aquí:",
            noMessagesTranslation: "No hay traducciones aún. Envía un texto para traducir a continuación.",
            noMessagesQuickConnect: "No hay mensajes aún. Envía un mensaje de apertura a continuación.",
            noMessagesSharedScreen: "No hay mensajes aún. Envía un mensaje de apertura a continuación.",
            myLanguageTabLabel: "Mi Idioma",
            responseFailure: "Hmm. Algo salió mal. Por favor, repite eso.",
            couldNotUnderstand: "Lo siento, no pude entender. Por favor, repite eso."
        },
        "es-MX": {
            notificationsModalHeader: "Notificaciones",
            conversationModalHeader: "Conversaciones Grupales",
            translateModalHeader: "Traducciones Personales",
            sharedModalHeader: "Pantalla Compartida",
            quickConnectModalHeader: "Conexión Rápida",
            noMessagesGroup: "Aún no hay mensajes. Envía un mensaje a continuación o añade nuevos miembros aquí:",
            noMessagesTranslation: "No hay traducciones aún. Envía un texto para traducir a continuación.",
            noMessagesQuickConnect: "No hay mensajes aún. Envía un mensaje de apertura a continuación.",
            noMessagesSharedScreen: "No hay mensajes aún. Envía un mensaje de apertura a continuación.",
            myLanguageTabLabel: "Mi Idioma",
            responseFailure: "Hmm. Algo salió mal. Por favor, repite eso.",
            couldNotUnderstand: "Lo siento, no pude entender. Por favor, repite eso."

        },
        "fr-FR": {
            notificationsModalHeader: "Notifications",
            conversationModalHeader: "Conversations de Groupe",
            translateModalHeader: "Traductions Personnelles",
            sharedModalHeader: "Affichage Partagé",
            quickConnectModalHeader: "Connexion Rapide",
            noMessagesGroup: "Il n'y a pas encore de messages. Envoyez un message ci-dessous ou ajoutez de nouveaux membres ici:",
            noMessagesTranslation: "Il n'y a pas encore de traductions. Envoyez un texte à traduire ci-dessous.",
            noMessagesQuickConnect: "Il n'y a pas encore de messages. Envoyez un message d'ouverture ci-dessous.",
            noMessagesSharedScreen: "Il n'y a pas encore de messages. Envoyez un message d'ouverture ci-dessous.",
            myLanguageTabLabel: "Ma Langue",
            responseFailure: "Hmm. Quelque chose s'est mal passé. Veuillez répéter cela.",
            couldNotUnderstand: "Désolé, je n'ai pas compris. Veuillez répéter cela."

        },
        "de-DE": {
            notificationsModalHeader: "Benachrichtigungen",
            conversationModalHeader: "Gemeinsamer Bildschirm",
            translateModalHeader: "Persönliche Übersetzungen",
            sharedModalHeader: "Gemeinsamer Bildschirm",
            quickConnectModalHeader: "Schnellverbindung",
            noMessagesGroup: "Es gibt noch keine Nachrichten. Senden Sie unten eine Nachricht oder fügen Sie hier neue Mitglieder hinzu:",
            noMessagesTranslation: "Es gibt noch keine Übersetzungen. Senden Sie unten einen Text zur Übersetzung.",
            noMessagesQuickConnect: "Es gibt noch keine Nachrichten. Senden Sie unten eine Eröffnungsnachricht.",
            noMessagesSharedScreen: "Es gibt noch keine Nachrichten. Senden Sie unten eine Eröffnungsnachricht.",
            myLanguageTabLabel: "Meine Sprache",
            responseFailure: "Hmm. Etwas ist schief gelaufen. Bitte wiederholen Sie das.",
            couldNotUnderstand: "Entschuldigung, ich konnte das nicht verstehen. Bitte wiederholen Sie das."
        },
        "it-IT": {
            notificationsModalHeader: "Notifiche",
            conversationModalHeader: "Conversazioni di Gruppo",
            translateModalHeader: "Traduzioni Personali",
            sharedModalHeader: "Schermo Condiviso",
            quickConnectModalHeader: "Connessione Veloce",
            noMessagesGroup: "Non ci sono ancora messaggi. Invia un messaggio qui sotto o aggiungi nuovi membri qui:",
            noMessagesTranslation: "Non ci sono ancora traduzioni. Invia un testo da tradurre qui sotto.",
            noMessagesQuickConnect: "Non ci sono ancora messaggi. Invia un messaggio di apertura qui sotto.",
            noMessagesSharedScreen: "Non ci sono ancora messaggi. Invia un messaggio di apertura qui sotto.",
            myLanguageTabLabel: "La Mia Lingua",
            responseFailure: "Hmm. Qualcosa è andato storto. Ripeti per favore.",
            couldNotUnderstand: "Scusa, non ho capito. Ripeti per favore."
        },
        "hi-IN": {
            notificationsModalHeader: "सूचनाएँ",
            conversationModalHeader: "समूह वार्तालाप",
            translateModalHeader: "व्यक्तिगत अनुवाद",
            sharedModalHeader: "साझा स्क्रीन",
            quickConnectModalHeader: "त्वरित कनेक्ट",
            noMessagesGroup: "अब तक कोई संदेश नहीं है। नीचे एक संदेश भेजें या यहां नए सदस्य जोड़ें:",
            noMessagesTranslation: "अब तक कोई अनुवाद नहीं है। नीचे अनुवाद करने के लिए कुछ पाठ भेजें।",
            noMessagesQuickConnect: "अब तक कोई संदेश नहीं है। नीचे एक उद्घाटन संदेश भेजें।",
            noMessagesSharedScreen: "अब तक कोई संदेश नहीं है। नीचे एक उद्घाटन संदेश भेजें।",
            myLanguageTabLabel: "मेरी भाषा",
            responseFailure: "हम्म। कुछ गलत हो गया। कृपया यह दोहराएं।",
            couldNotUnderstand: "क्षमा करें, मैं समझ नहीं पाया। कृपया यह दोहराएं।"
        },
        "cmn-CN": {
            notificationsModalHeader: "通知",
            conversationModalHeader: "群组对话",
            translateModalHeader: "个人翻译",
            sharedModalHeader: "共享屏幕",
            quickConnectModalHeader: "快速连接",
            noMessagesGroup: "还没有消息。在下面发送消息或在此处添加新成员：",
            noMessagesTranslation: "还没有翻译。在下面提交要翻译的文本。",
            noMessagesQuickConnect: "还没有消息。在下面发送开场白。",
            noMessagesSharedScreen: "还没有消息。在下面发送开场白。",
            myLanguageTabLabel: "我的语言",
            responseFailure: "嗯。出了点问题。请重复一遍。",
            couldNotUnderstand: "抱歉，我无法理解。请重复一遍。"
        },
        "so-SO": {
            notificationsModalHeader: "Ogeysiisyada",
            conversationModalHeader: "Falalka Guriga",
            translateModalHeader: "Turjumaadka Shaqsiga ah",
            sharedModalHeader: "Goobta La Wadaagay",
            quickConnectModalHeader: "Kuwa Iska Horreeya",
            noMessagesGroup: "Waxba kama jiraan. Dir farriin hoose ama ku dar qoraal cusub halkan:",
            noMessagesTranslation: "Waxba kama jiraan turjumaad. Dir qoraal turjumaad ah halkan hoose.",
            noMessagesQuickConnect: "Waxba kama jiraan. Dir farriin hoose.",
            noMessagesSharedScreen: "Waxba kama jiraan. Dir farriin hoose.",
            myLanguageTabLabel: "Luqadaaga",
            responseFailure: "Hmm. Waxaa dhacay waxba kama jiraan. Fadlan dib u eeg.",
            couldNotUnderstand: "Dhib. Ma fahmi karo. Fadlan dib u eeg."
        },
        "pt-PT": {
            notificationsModalHeader: "Notificações",
            conversationModalHeader: "Conversas de Grupo",
            translateModalHeader: "Traduções Pessoais",
            sharedModalHeader: "Ecrã Partilhado",
            quickConnectModalHeader: "Conexão Rápida",
            noMessagesGroup: "Ainda não há mensagens. Envie uma mensagem abaixo ou adicione novos membros aqui:",
            noMessagesTranslation: "Ainda não há traduções. Envie um texto para traduzir abaixo.",
            noMessagesQuickConnect: "Ainda não há mensagens. Envie uma mensagem de abertura abaixo.",
            noMessagesSharedScreen: "Ainda não há mensagens. Envie uma mensagem de abertura abaixo.",
            myLanguageTabLabel: "Minha Língua",
            responseFailure: "Hmm. Algo correu mal. Por favor, repita isso.",
            couldNotUnderstand: "Desculpe, não consegui entender. Por favor, repita."
        },
        "pt-BR": {
            notificationsModalHeader: "Notificações",
            conversationModalHeader: "Conversas em Grupo",
            translateModalHeader: "Traduções Pessoais",
            sharedModalHeader: "Tela Compartilhada",
            quickConnectModalHeader: "Conexão Rápida",
            noMessagesGroup: "Ainda não há mensagens. Envie uma mensagem abaixo ou adicione novos membros aqui:",
            noMessagesTranslation: "Ainda não há traduções. Envie um texto para traduzir abaixo.",
            noMessagesQuickConnect: "Ainda não há mensagens. Envie uma mensagem de abertura abaixo.",
            noMessagesSharedScreen: "Ainda não há mensagens. Envie uma mensagem de abertura abaixo.",
            myLanguageTabLabel: "Meu Idioma",
            responseFailure: "Hmm. Algo deu errado. Por favor, repita.",
            couldNotUnderstand: "Desculpe, não entendi. Por favor, repita."
        },
        "id-ID": {
            notificationsModalHeader: "Pemberitahuan",
            conversationModalHeader: "Obrolan Grup",
            translateModalHeader: "Terjemahan Pribadi",
            sharedModalHeader: "Layar Bersama",
            quickConnectModalHeader: "Koneksi Cepat",
            noMessagesGroup: "Belum ada pesan. Kirim pesan di bawah atau tambahkan anggota baru di sini:",
            noMessagesTranslation: "Belum ada terjemahan. Kirim teks untuk diterjemahkan di bawah.",
            noMessagesQuickConnect: "Belum ada pesan. Kirim pesan pembuka di bawah.",
            noMessagesSharedScreen: "Belum ada pesan. Kirim pesan pembuka di bawah.",
            myLanguageTabLabel: "Bahasa Saya",
            responseFailure: "Hmm. Ada yang salah. Silakan ulangi.",
            couldNotUnderstand: "Maaf, saya tidak mengerti. Silakan ulangi."
        },
        "vi-VN": {
            notificationsModalHeader: "Thông Báo",
            conversationModalHeader: "Cuộc Trò Chuyện Nhóm",
            translateModalHeader: "Dịch Cá Nhân",
            sharedModalHeader: "Màn hình chia sẻ",
            quickConnectModalHeader: "Kết Nối Nhanh",
            noMessagesGroup: "Chưa có tin nhắn. Gửi tin nhắn dưới đây hoặc thêm thành viên mới ở đây:",
            noMessagesTranslation: "Chưa có bản dịch. Gửi văn bản cần dịch dưới đây.",
            noMessagesQuickConnect: "Chưa có tin nhắn. Gửi tin nhắn mở đầu dưới đây.",
            noMessagesSharedScreen: "Chưa có tin nhắn. Gửi tin nhắn mở đầu dưới đây.",
            myLanguageTabLabel: "Ngôn Ngữ Của Tôi",
            responseFailure: "Hmm. Có vấn đề xảy ra. Vui lòng thử lại.",
            couldNotUnderstand: "Xin lỗi, tôi không hiểu. Vui lòng thử lại."
        },
        "ko-KR": {
            notificationsModalHeader: "알림",
            conversationModalHeader: "그룹 대화",
            translateModalHeader: "개인 번역",
            sharedModalHeader: "공유 화면",
            quickConnectModalHeader: "빠른 연결",
            noMessagesGroup: "아직 메시지가 없습니다. 아래에 메시지를 보내거나 여기에 새 멤버를 추가하세요:",
            noMessagesTranslation: "아직 번역이 없습니다. 번역할 텍스트를 아래에 보내세요.",
            noMessagesQuickConnect: "아직 메시지가 없습니다. 아래에 시작 메시지를 보내세요.",
            noMessagesSharedScreen: "아직 메시지가 없습니다. 아래에 시작 메시지를 보내세요.",
            myLanguageTabLabel: "내 언어",
            responseFailure: "음. 문제가 발생했습니다. 다시 시도하세요.",
            couldNotUnderstand: "죄송합니다. 이해할 수 없습니다. 다시 말해주세요."
        },
        "ja-JP": {
            notificationsModalHeader: "通知",
            conversationModalHeader: "グループチャット",
            translateModalHeader: "個人翻訳",
            sharedModalHeader: "共有画面",
            quickConnectModalHeader: "クイック接続",
            noMessagesGroup: "まだメッセージがありません。以下にメッセージを送信するか、ここに新しいメンバーを追加してください：",
            noMessagesTranslation: "まだ翻訳がありません。翻訳するテキストを以下に送信してください。",
            noMessagesQuickConnect: "まだメッセージがありません。以下にオープニングメッセージを送信してください。",
            noMessagesSharedScreen: "まだメッセージがありません。以下にオープニングメッセージを送信してください。",
            myLanguageTabLabel: "私の言語",
            responseFailure: "うーん。問題が発生しました。もう一度やり直してください。",
            couldNotUnderstand: "すみません、理解できませんでした。もう一度言ってください。"
        },
        "ar-IQ": {
            notificationsModalHeader: "الإشعارات",
            conversationModalHeader: "محادثات المجموعة",
            translateModalHeader: "الترجمة الشخصية",
            sharedModalHeader: "الشاشة المشتركة",
            quickConnectModalHeader: "الاتصال السريع",
            noMessagesGroup: "لا توجد رسائل بعد. أرسل رسالة أدناه أو أضف أعضاء جدد هنا:",
            noMessagesTranslation: "لا توجد ترجمات بعد. أرسل نص لترجمته أدناه.",
            noMessagesQuickConnect: "لا توجد رسائل بعد. أرسل رسالة افتتاحية أدناه.",
            noMessagesSharedScreen: "لا توجد رسائل بعد. أرسل رسالة افتتاحية أدناه.",
            myLanguageTabLabel: "لغتي",
            responseFailure: "همم. حدث خطأ. يرجى إعادة المحاولة.",
            couldNotUnderstand: "عذرًا، لم أستطع فهم ذلك. يرجى إعادة المحاولة."
        },
        "fil-PH": {
            notificationsModalHeader: "Mga Abiso",
            conversationModalHeader: "Mga Usapan ng Grupo",
            translateModalHeader: "Mga Pagsasalin ng Personal",
            sharedModalHeader: "Bahagi ng Screen",
            quickConnectModalHeader: "Mabilis na Koneksyon",
            noMessagesGroup: "Walang mga mensahe pa. Magpadala ng mensahe sa ibaba o magdagdag ng mga bagong miyembro dito:",
            noMessagesTranslation: "Walang mga pagsasalin pa. Magpadala ng teksto na nais isalin sa ibaba.",
            noMessagesQuickConnect: "Walang mga mensahe pa. Magpadala ng mensahe ng pagsisimula sa ibaba.",
            noMessagesSharedScreen: "Walang mga mensahe pa. Magpadala ng mensahe ng pagsisimula sa ibaba.",
            myLanguageTabLabel: "Aking Wika",
            responseFailure: "Hmm. Nagkaproblema. Pakiulit yan.",
            couldNotUnderstand: "Pasensya na, hindi ko naintindihan. Pakiulit."
        },
        "ne-NP": {
            notificationsModalHeader: "सूचनाहरू",
            conversationModalHeader: "समूह च्याटहरू",
            translateModalHeader: "व्यक्तिगत अनुवाद",
            sharedModalHeader: "साझा स्क्रिन",
            quickConnectModalHeader: "तेजीले कनेक्ट गर्नुहोस्",
            noMessagesGroup: "अहिले सम्झौता छैन। तलको सन्देश पठाउनुहोस् वा नयाँ सदस्यहरू थप्नुहोस्:",
            noMessagesTranslation: "अहिले अनुवाद छैन। अनुवाद गर्नका लागि पाठ यहाँ पठाउनुहोस्।",
            noMessagesQuickConnect: "अहिले सम्झौता छैन। तलको सन्देश पठाउनुहोस्।",
            noMessagesSharedScreen: "अहिले सम्झौता छैन। तलको सन्देश पठाउनुहोस्।",
            myLanguageTabLabel: "मेरो भाषा",
            responseFailure: "हम्म। केहि गलती भयो। कृपया पुन: प्रयास गर्नुहोस्।",
            couldNotUnderstand: "माफ गर्नुहोस्, मैले सम्झिन सकेन। कृपया पुन: प्रयास गर्नुहोस्।"
        },
        "ar-SA": {
            notificationsModalHeader: "الإشعارات",
            conversationModalHeader: "محادثات المجموعة",
            translateModalHeader: "الترجمة الشخصية",
            sharedModalHeader: "الشاشة المشتركة",
            quickConnectModalHeader: "الاتصال السريع",
            noMessagesGroup: "لا توجد رسائل بعد. أرسل رسالة أدناه أو أضف أعضاء جدد هنا:",
            noMessagesTranslation: "لا توجد ترجمات بعد. أرسل نص لترجمته أدناه.",
            noMessagesQuickConnect: "لا توجد رسائل بعد. أرسل رسالة افتتاحية أدناه.",
            noMessagesSharedScreen: "لا توجد رسائل بعد. أرسل رسالة افتتاحية أدناه.",
            myLanguageTabLabel: "لغتي",
            responseFailure: "همم. حدث خطأ. يرجى إعادة المحاولة.",
            couldNotUnderstand: "عذرًا، لم أستطع فهم ذلك. يرجى إعادة المحاولة."
        },
        "am-ET": {
            notificationsModalHeader: "ማሳወቅያዊ",
            conversationModalHeader: "የቡድን ክፍል",
            translateModalHeader: "ሰማይ ተረት",
            sharedModalHeader: "አካባቢ አገናኝ",
            quickConnectModalHeader: "በትክክለኛ በመጠቀም",
            noMessagesGroup: "ምንም መልዕክት የለም። ከዚህ በታች ላይ መልዕክት ላክ ወይም አዲስ አባላት",
            noMessagesTranslation: "ምንም ተረት የለም። እባኮት በላይ የተረቱትን ጽሁፍ ላክ።",
            noMessagesQuickConnect: "ምንም መልዕክት የለም። ከዚህ በታች መልዕክት ላክ።",
            noMessagesSharedScreen: "ምንም መልዕክት የለም። ከዚህ በታች መልዕክት ላክ።",
            myLanguageTabLabel: "የኔ ቋንቋ",
            responseFailure: "እባኮት በማስተላለፍ ላይ ስለሚታይ ነው። እባኮት እንደገና እንደገና ይሞክሩ።",
            couldNotUnderstand: "ይቅርታው። እርስዎ አልተደረገም። እባኮት እንደገና እንደገና ይሞክሩ"

        }
    },
    accountPanelContent: {
        "en-US": {
            title: "My Account",
            email: "Email",
            location: "Location",
            changePassword: "Change Password",
        },
        "en-GB": {
            title: "My Account",
            email: "Email",
            location: "Location",
            changePassword: "Change Password",
        },
        "es-ES": {
            title: "Mi Cuenta",
            email: "Correo electrónico",
            location: "Ubicación",
            changePassword: "Cambiar Contraseña"
        },
        "es-MX": {
            title: "Mi Cuenta",
            email: "Correo electrónico",
            location: "Ubicación",
            changePassword: "Cambiar Contraseña"
        },
        "fr-FR": {
            title: "Mon compte",
            email: "Adresse électronique",
            location: "Emplacement",
            changePassword: "Changer le mot de passe"
        },
        "de-DE": {
            title: "Mein Konto",
            email: "E-Mail-Adresse",
            location: "Ort",
            changePassword: "Passwort ändern"
        },
        "it-IT": {
            title: "Il mio Account",
            email: "Indirizzo email",
            location: "Posizione",
            changePassword: "Cambia password"
        },
        "hi-IN": {
            title: "मेरा खाता",
            email: "ईमेल",
            location: "स्थान",
            changePassword: "पासवर्ड बदलें"
        },
        "cmn-CN": {
            title: "我的账户",
            email: "电子邮件",
            location: "位置",
            changePassword: "更改密码"
        },
        "so-SO": {
            title: "Xisaabtayda",
            email: "Iimayl",
            location: "Goobta",
            changePassword: "Beddel Fure"
        },
        "pt-PT": {
            title: "A minha conta",
            email: "Email",
            location: "Localização",
            changePassword: "Alterar a palavra-passe"
        },
        "pt-BR": {
            title: "Minha conta",
            email: "Email",
            location: "Localização",
            changePassword: "Alterar a senha"
        },
        "id-ID": {
            title: "Akun Saya",
            email: "Email",
            location: "Lokasi",
            changePassword: "Ubah Kata Sandi"
        },
        "vi-VN": {
            title: "Tài khoản của tôi",
            email: "Email",
            location: "Vị trí",
            changePassword: "Thay đổi mật khẩu"
        },
        "ko-KR": {
            title: "내 계정",
            email: "이메일",
            location: "위치",
            changePassword: "비밀번호 변경"
        },
        "ja-JP": {
            title: "私のアカウント",
            email: "Eメール",
            location: "ロケーション",
            changePassword: "パスワードを変更する"
        },
        "ar-IQ": {
            title: "حسابي",
            email: "البريد الإلكتروني",
            location: "الموقع",
            changePassword: "تغيير كلمة المرور"
        },
        "fil-PH": {
            title: "Aking Account",
            email: "Email",
            location: "Lokasyon",
            changePassword: "Baguhin ang Password"
        },
        "ne-NP": {
            title: "मेरो खाता",
            email: "ईमेल",
            location: "स्थान",
            changePassword: "पासवर्ड परिवर्तन"
        },
        "ar-SA": {
            title: "حسابي",
            email: "البريد الإلكتروني",
            location: "الموقع",
            changePassword: "تغيير كلمة المرور"
        },
        "am-ET": {
            title: "የኔ መለያ",
            email: "ኢሜል",
            location: "አካባቢ",
            changePassword: "ይህን ቃል ቀይር"

        }
    },
    settingsPanelContent: {
        "en-US": {
            title: "Settings",
            defaultLanguage: "My Default Language",
            voiceGender: "My Voice Gender",
            autoPlay: "Autoplay Audio Responses",
            male: "Male",
            female: "Female",
            yes: "Yes",
            no: "No",
            save: "Save",
            cancel: "Cancel"
        },
        "en-GB": {
            title: "Settings",
            defaultLanguage: "My Default Language",
            voiceGender: "My Voice Gender",
            autoPlay: "Autoplay Audio Responses",
            male: "Male",
            female: "Female",
            yes: "Yes",
            no: "No",
            save: "Save",
            cancel: "Cancel"
        },
        "es-ES": {
            title: "Configuración",
            defaultLanguage: "Mi Idioma por Defecto",
            voiceGender: "Mi tipo de voz",
            autoPlay: "Reproducción automática",
            male: "Hombre",
            female: "Mujer",
            yes: "Sí",
            no: "No",
            save: "Guardar",
            cancel: "Cancelar"
        },
        "es-MX": {
            title: "Configuración",
            defaultLanguage: "Mi Idioma por Defecto",
            voiceGender: "Mi tipo de voz",
            autoPlay: "Reproducción automática",
            male: "Hombre",
            female: "Mujer",
            yes: "Sí",
            no: "No",
            save: "Guardar",
            cancel: "Cancelar"
        },
        "fr-FR": {
            title: "Paramètres",
            defaultLanguage: "Ma langue par défaut",
            voiceGender: "Mon genre vocal",
            autoPlay: "Lecture automatique",
            male: "Homme",
            female: "Femme",
            yes: "Oui",
            no: "Non",
            save: "Commence",
            cancel: "Annule"
        },
        "de-DE": {
            title: "Einstellungen",
            defaultLanguage: "Meine Standardsprache",
            voiceGender: "Mein Sprachgeschlecht",
            autoPlay: "Automatische Wiedergabe",
            male: "Männlich",
            female: "Weiblich",
            yes: "Ja",
            no: "Nein",
            save: "Speichern",
            cancel: "Stornieren"
        },
        "it-IT": {
            title: "Impostazioni",
            defaultLanguage: "La mia lingua predefinita",
            voiceGender: "Il mio genere di voce",
            autoPlay: "Riproduzione automatica",
            male: "Maschio",
            female: "Femmina",
            yes: "Sì",
            no: "No",
            save: "Salva",
            cancel: "Annulla"
        },
        "hi-IN": {
            title: "सेटिंग्स",
            defaultLanguage: "मेरी डिफ़ॉल्ट भाषा",
            voiceGender: "मेरी आवाज का लिंग",
            autoPlay: "स्वतः चलना",
            male: "पुरुष",
            female: "महिला",
            yes: "हाँ",
            no: "नहीं",
            save: "बचाओ",
            cancel: "रद्द करें"
        },
        "cmn-CN": {
            title: "设置",
            defaultLanguage: "我的默认语言",
            voiceGender: "我的语音性别",
            autoPlay: "自动播放",
            male: "男性",
            female: "女性",
            yes: "是",
            no: "没有",
            save: "保存",
            cancel: "取消"
        },
        "so-SO": {
            title: "Dejinta",
            defaultLanguage: "Luqadaada Hooseeya",
            voiceGender: "Jinsiga Codkaaga",
            autoPlay: "Autoplay",
            male: "Wiil",
            female: "Gabar",
            yes: "Haa",
            no: "Maya",
            save: "Keydso",
            cancel: "Kaarka"
        },
        "pt-PT": {
            title: "Configurações",
            defaultLanguage: "Meu Idioma Padrão",
            voiceGender: "Meu Gênero de Voz",
            autoPlay: "Reprodução Automática",
            male: "Masculino",
            female: "Feminino",
            yes: "Sim",
            no: "Não",
            save: "Salvar",
            cancel: "Cancelar"
        },
        "pt-BR": {
            title: "Configurações",
            defaultLanguage: "Meu Idioma Padrão",
            voiceGender: "Meu Gênero de Voz",
            autoPlay: "Reprodução Automática",
            male: "Masculino",
            female: "Feminino",
            yes: "Sim",
            no: "Não",
            save: "Salvar",
            cancel: "Cancelar"
        },
        "id-ID": {
            title: "Pengaturan",
            defaultLanguage: "Bahasa Default Saya",
            voiceGender: "Jenis Kelamin Suara",
            autoPlay: "Putar Otomatis",
            male: "Laki-laki",
            female: "Perempuan",
            yes: "Ya",
            no: "Tidak",
            save: "Simpan",
            cancel: "Batal"
        },
        "vi-VN": {
            title: "Cài đặt",
            defaultLanguage: "Ngôn ngữ Mặc định của Tôi",
            voiceGender: "Giới Tính Giọng Nói của Tôi",
            autoPlay: "Tự động phát",
            male: "Nam",
            female: "Nữ",
            yes: "Có",
            no: "Không",
            save: "Lưu",
            cancel: "Hủy"
        },
        "ko-KR": {
            title: "설정",
            defaultLanguage: "내 기본 언어",
            voiceGender: "내 음성 성별",
            autoPlay: "자동 재생",
            male: "남성",
            female: "여성",
            yes: "예",
            no: "아니",
            save: "저장",
            cancel: "취소"
        },
        "ja-JP": {
            title: "設定",
            defaultLanguage: "私のデフォルト言語",
            voiceGender: "私の音声性別",
            autoPlay: "自動再生",
            male: "男性",
            female: "女性",
            yes: "はい",
            no: "いいえ",
            save: "セーブ",
            cancel: "キャンセル"
        },
        "ar-IQ": {
            title: "الإعدادات",
            defaultLanguage: "لغتي الافتراضية",
            voiceGender: "جنس صوتي",
            autoPlay: "تشغيل تلقائي",
            male: "ذكر",
            female: "أنثى",
            yes: "نعم",
            no: "لا",
            save: "حفظ",
            cancel: "إلغاء"
        },
        "fil-PH": {
            title: "Mga Setting",
            defaultLanguage: "Aking Default na Wika",
            voiceGender: "Aking Kasarian ng Boses",
            autoPlay: "Auto-play",
            male: "Lalaki",
            female: "Babae",
            yes: "Oo",
            no: "Hindi",
            save: "I-save",
            cancel: "Ikansela"
        },
        "ne-NP": {
            title: "सेटिङहरू",
            defaultLanguage: "मेरो डिफल्ट भाषा",
            voiceGender: "मेरो आवाज लिङ्ग",
            autoPlay: "स्वत: चलाउनुहोस्",
            male: "पुरुष",
            female: "महिला",
            yes: "हो",
            no: "होइन",
            save: "बचत गर्नुहोस्",
            cancel: "रद्द गर्नुहोस्"
        },
        "ar-SA": {
            title: "الإعدادات",
            defaultLanguage: "لغتي الافتراضية",
            voiceGender: "جنس صوتي",
            autoPlay: "تشغيل تلقائي",
            male: "ذكر",
            female: "أنثى",
            yes: "نعم",
            no: "لا",
            save: "حفظ",
            cancel: "إلغاء"
        },
        "am-ET": {
            title: "ቅንብሮች",
            defaultLanguage: "እኔን በቅደም ቋንቋ",
            voiceGender: "እኔን በድምጽ ጾታ",
            autoPlay: "እንደምን እንደም",
            male: "ወንድ",
            female: "ሴት",
            yes: "አዎ",
            no: "አይ",
            save: "አስቀምጥ",
            cancel: "ሰርዝ"
        }
    },
    sessionsTableContent: {
        "en-US": {
            nameHeader: "Name",
            memberHeader: "Members",
            languageHeader: "Languages",
        },
        "en-GB": {
            nameHeader: "Name",
            memberHeader: "Members",
            languageHeader: "Languages",
        },
        "es-ES": {
            nameHeader: "Nombre",
            memberHeader: "Miembros",
            languageHeader: "Idiomas",
        },
        "es-MX": {
            nameHeader: "Nombre",
            memberHeader: "Miembros",
            languageHeader: "Idiomas",
        },
        "fr-FR": {
            nameHeader: "Nom",
            memberHeader: "Membres",
            languageHeader: "Langues",
        },
        "de-DE": {
            nameHeader: "Name",
            memberHeader: "Mitglieder",
            languageHeader: "Sprachen",
        },
        "it-IT": {
            nameHeader: "Nome",
            memberHeader: "Membri",
            languageHeader: "Lingue",
        },
        "hi-IN": {
            nameHeader: "नाम",
            memberHeader: "सदस्य",
            languageHeader: "भाषाएँ",
        },
        "cmn-CN": {
            nameHeader: "名称",
            memberHeader: "成员",
            languageHeader: "语言",
        },
        "so-SO": {
            nameHeader: "Magaca",
            memberHeader: "Xubnaha",
            languageHeader: "Afafka",
        },
        "pt-PT": {
            nameHeader: "Nome",
            memberHeader: "Membros",
            languageHeader: "Idiomas",
        },
        "pt-BR": {
            nameHeader: "Nome",
            memberHeader: "Membros",
            languageHeader: "Idiomas",
        },
        "id-ID": {
            nameHeader: "Nama",
            memberHeader: "Anggota",
            languageHeader: "Bahasa",
        },
        "vi-VN": {
            nameHeader: "Tên",
            memberHeader: "Thành viên",
            languageHeader: "Ngôn ngữ",
        },
        "ko-KR": {
            nameHeader: "이름",
            memberHeader: "회원",
            languageHeader: "언어",
        },
        "ja-JP": {
            nameHeader: "名前",
            memberHeader: "メンバー",
            languageHeader: "言語",
        },
        "ar-IQ": {
            nameHeader: "اسم",
            memberHeader: "أعضاء",
            languageHeader: "لغات",
        },
        "fil-PH": {
            nameHeader: "Pangalan",
            memberHeader: "Mga Miyembro",
            languageHeader: "Mga Wika",
        },
        "ne-NP": {
            nameHeader: "नाम",
            memberHeader: "सदस्यहरू",
            languageHeader: "भाषाहरू",
        },
        "ar-SA": {
            nameHeader: "اسم",
            memberHeader: "أعضاء",
            languageHeader: "لغات",
        },
        "am-ET": {
            nameHeader: "ስም",
            memberHeader: "አባላት",
            languageHeader: "ቋንቋዎች",
        }
    },
    chatInviteScreenContent: {
        "en-US": {
            title: "Join the Conversation!",
            languageField: "My Language",
            chatCodeField: "Chat Invite Code",
            joinButton: "Join",
            pendingTitle: "Pending Confirmation",
            pendingText: "This conversation requires confirmation from the owner. They " +
                "have been notified of your request. If approved, you will receive " +
                "a notification.",
            dashboardButton: "Continue to Dashboard"
        },
        "en-GB": {
            title: "Join the Conversation!",
            languageField: "My Language",
            chatCodeField: "Chat Invite Code",
            joinButton: "Join",
            pendingTitle: "Pending Confirmation",
            pendingText: "This conversation requires confirmation from the owner. They " +
                "have been notified of your request. If approved, you will receive " +
                "a notification.",
            dashboardButton: "Continue to Dashboard"
        },
        "es-ES": {
            title: "¡Únete a la conversación!",
            languageField: "Mi idioma",
            chatCodeField: "Código de invitación al chat",
            joinButton: "Únete",
            pendingTitle: "Confirmación pendiente",
            pendingText: "Esta conversación requiere confirmación del propietario. " +
                "Se les ha notificado su solicitud. Si se aprueba, recibirá una " +
                "notificación.",
            dashboardButton: "Continuar al tablero"
        },
        "es-MX": {
            title: "¡Únete a la conversación!",
            languageField: "Mi idioma",
            chatCodeField: "Código de invitación al chat",
            joinButton: "Únete",
            pendingTitle: "Confirmación pendiente",
            pendingText: "Esta conversación requiere confirmación del propietario. " +
                "Se les ha notificado su solicitud. Si se aprueba, recibirá una " +
                "notificación.",
            dashboardButton: "Continuar al tablero"
        },
        "fr-FR": {
            title: "Rejoignez la conversation !",
            languageField: "Ma langue",
            "chatCodeField": "Code d'invitation au chat",
            joinButton: "Rejoignez",
            pendingTitle: "Confirmation en attente",
            pendingText: "Cette conversation nécessite une confirmation du propriétaire. " +
                "Ils ont été informés de votre demande. Si elle est approuvée, vous " +
                "recevrez une notification.",
            dashboardButton: "Continuer vers le tableau de bord"
        },
        "de-DE": {
            title: "Mach mit beim Gespräch!",
            languageField: "Meine Sprache",
            chatCodeField: "Chat-Einladungscode",
            joinButton: "Tritt bei",
            pendingTitle: "Ausstehende Bestätigung",
            pendingText: "Dieses Gespräch erfordert eine Bestätigung des Eigentümers. " +
                "Sie wurden über Ihre Anfrage informiert. Wenn sie genehmigt wird, " +
                "erhalten Sie eine Benachrichtigung.",
            dashboardButton: "Weiter zum Dashboard"
        },
        "it-IT": {
            title: "Partecipa alla conversazione!",
            languageField: "La mia lingua",
            chatCodeField: "Codice invito chat",
            joinButton: "Unisciti",
            pendingTitle: "Conferma in sospeso",
            pendingText: "Questa conversazione richiede una conferma da parte del proprietario. " +
                "Sono stati informati della tua richiesta. Se approvata, riceverai " +
                "una notifica.",
            dashboardButton: "Continua alla dashboard"
        },
        "hi-IN": {
            title: "बातचीत में शामिल हों!",
            languageField: "मेरी भाषा",
            chatCodeField: "चैट आमंत्रण कोड",
            joinButton: "जुड़ें",
            pendingTitle: "पुष्टि लंबित",
            pendingText: "इस बातचीत के लिए मालिक की पुष्टि की आवश्यकता है। उन्हें " +
                "आपके अनुरोध की सूचना दी गई है। यदि मान्यता प्राप्त होती है, " +
                "तो आपको एक सूचना मिलेगी।",
            dashboardButton: "डैशबोर्ड पर जारी रहें"
        },
        "cmn-CN": {
            title: "加入对话！",
            languageField: "我的语言",
            chatCodeField: "聊天邀请码",
            joinButton: "加入",
            pendingTitle: "待确认",
            pendingText: "此对话需要所有者确认。他们已经收到了您的请求。如果批准，您将收到通知。",
            dashboardButton: "继续到仪表板"
        },
        "so-SO": {
            title: "Ku biir Wadahadalka!",
            languageField: "Luqadaaga",
            chatCodeField: "Koodhka Diiwaangelinta Chat",
            joinButton: "Ku biir",
            pendingTitle: "Xaqiijinta La Laaban",
            pendingText: "Wadahadalkan waxaa loo baahan yahay xaqiijinta milkiileyaasha. " +
                "Waxaa laguugu soo dhoweeyay codsigiin. Haddii la oggolaado, waxaad " +
                "hesho xogtan.",
            dashboardButton: "Ku sii wadajirka"
        },
        "pt-PT": {
            title: "Participe na conversa!",
            languageField: "Meu Idioma",
            chatCodeField: "Código de convite do chat",
            joinButton: "Participar",
            pendingTitle: "Confirmação Pendente",
            pendingText: "Esta conversa requer confirmação do proprietário. " +
                "Eles foram notificados do seu pedido. Se for aprovado, você " +
                "receberá uma notificação.",
            dashboardButton: "Continuar para o painel"
        },
        "pt-BR": {
            title: "Participe da Conversa!",
            languageField: "Meu Idioma",
            chatCodeField: "Código de Convite do Chat",
            joinButton: "Participar",
            pendingTitle: "Confirmação Pendente",
            pendingText: "Esta conversa requer confirmação do proprietário. " +
                "Eles foram notificados do seu pedido. Se for aprovado, você " +
                "receberá uma notificação.",
            dashboardButton: "Continuar para o painel"
        },
        "id-ID": {
            title: "Bergabung dalam Percakapan!",
            languageField: "Bahasa Saya",
            chatCodeField: "Kode Undangan Obrolan",
            joinButton: "Bergabung",
            pendingTitle: "Konfirmasi Tertunda",
            pendingText: "Percakapan ini memerlukan konfirmasi dari pemilik. " +
                "Mereka telah diberitahu tentang permintaan Anda. Jika disetujui, " +
                "Anda akan menerima pemberitahuan.",
            dashboardButton: "Lanjutkan ke Dasbor"
        },
        "vi-VN": {
            title: "Tham gia cuộc trò chuyện!",
            languageField: "Ngôn ngữ của tôi",
            chatCodeField: "Mã Mời Trò Chuyện",
            joinButton: "Tham gia",
            pendingTitle: "Chờ Xác Nhận",
            pendingText: "Cuộc trò chuyện này yêu cầu xác nhận từ chủ sở hữu. " +
                "Họ đã được thông báo về yêu cầu của bạn. Nếu được chấp thuận, " +
                "bạn sẽ nhận được thông báo.",
            dashboardButton: "Tiếp tục đến Bảng điều khiển"
        },
        "ko-KR": {
            title: "대화에 참여하세요!",
            languageField: "내 언어",
            chatCodeField: "채팅 초대 코드",
            joinButton: "참여",
            pendingTitle: "대기 중인 확인",
            pendingText: "이 대화는 소유자 확인이 필요합니다. " +
                "그들은 요청을 받았습니다. 승인되면 알림을 받게 됩니다.",
            dashboardButton: "대시 보드로 계속"
        },
        "ja-JP": {
            title: "会話に参加！",
            languageField: "私の言語",
            chatCodeField: "チャット招待コード",
            joinButton: "参加",
            pendingTitle: "保留中の確認",
            pendingText: "この会話には所有者の確認が必要です。 " +
                "彼らはあなたのリクエストを受け取りました。 承認されると通知が届きます。",
            dashboardButton: "ダッシュボードに進む"
        },
        "ar-IQ": {
            title: "انضم إلى المحادثة!",
            languageField: "لغتي",
            chatCodeField: "رمز دعوة الدردشة",
            joinButton: "انضم",
            pendingTitle: "تأكيد معلق",
            pendingText: "هذه المحادثة تتطلب تأكيدًا من المالك. " +
                "لقد تم إبلاغهم بطلبك. إذا تمت الموافقة، ستتلقى إشعارًا.",
            dashboardButton: "المتابعة إلى لوحة التحكم"
        },
        "fil-PH": {
            title: "Sumali sa Usapan!",
            languageField: "Aking Wika",
            chatCodeField: "Chat Invite Code",
            joinButton: "Sumali",
            pendingTitle: "Nakabinbin na Kumpirmasyon",
            pendingText: "Nangangailangan ang usapang ito ng kumpirmasyon mula sa may-ari. " +
                "Naabisuhan na sila ng iyong kahilingan. Kung ito ay aprubado, " +
                "makakatanggap ka ng abiso.",
            dashboardButton: "Magpatuloy sa Dashboard"
        },
        "ne-NP": {
            title: "वार्तामा सामेल हुनुहोस्!",
            languageField: "मेरो भाषा",
            chatCodeField: "च्याट आमंत्रण कोड",
            joinButton: "सामेल हुनुहोस्",
            pendingTitle: "पेन्डिङ पुष्टि",
            pendingText: "यो वार्तामा मालिकाबाट पुष्टि चाहिन्छ। " +
                "तपाईंको अनुरोध भेटिएको छ। यदि स्वीकृत गरिएमा, " +
                "तपाईंलाई सूचना प्राप्त हुनेछ।",
            dashboardButton: "ड्यासबोर्डमा जारी राख्नुहोस्"
        },
        "ar-SA": {
            title: "انضم إلى المحادثة!",
            languageField: "لغتي",
            chatCodeField: "رمز دعوة الدردشة",
            joinButton: "انضم",
            pendingTitle: "تأكيد معلق",
            pendingText: "هذه المحادثة تتطلب تأكيدًا من المالك. " +
                "لقد تم إبلاغهم بطلبك. إذا تمت الموافقة، ستتلقى إشعارًا.",
            dashboardButton: "المتابعة إلى لوحة التحكم"
        },
        "am-ET": {
            title: "እንዴት ተለያዩ!",
            languageField: "የኔ ቋንቋ",
            chatCodeField: "የድምፅ ኮድ",
            joinButton: "ተለያዩ",
            pendingTitle: "በተለያዩ ማረጋገጫ",
            pendingText: "ይህ ድምፅ ባለቤት እንዲሆን የተለያዩ ማረጋገጫ ይሆናል። " +
                "እንደሚለውን አስተያየት ለማስተካከል እናውቃለን።",
            dashboardButton: "ወደ ዳሽቦርድ ተመለስ"

        }
    },
    chatInvitationPanel: {
        "en-US": {
            title: "Invite Chat Members",
            copyInstructions: "Copy the invite link to share with invitees.",
            chatId: "Chat Id",
            link: "Link",
            closeButtonText: "Close"
        },
        "en-GB": {
            title: "Invite Chat Members",
            copyInstructions: "Copy the invite link to share with invitees.",
            chatId: "Chat Id",
            link: "Link",
            closeButtonText: "Close"
        },
        "es-ES": {
            title: "Invitar a miembros del chat",
            copyInstructions: "Copia el enlace de invitación para compartirlo con los invitados.",
            chatId: "Id. de chat",
            link: "Enlace",
            closeButtonText: "Cerrar"

        },
        "es-MX": {
            title: "Invitar a miembros del chat",
            copyInstructions: "Copia el enlace de invitación para compartirlo con los invitados.",
            chatId: "Id. de chat",
            link: "Enlace",
            closeButtonText: "Cerrar"
        },
        "fr-FR": {
            title: "Inviter des membres du chat",
            copyInstructions: "Copiez le lien d'invitation pour le partager avec les invités.",
            chatId: "ID de chat",
            link: "Lien",
            closeButtonText: "Fermer"
        },
        "de-DE": {
            title: "Chat-Mitglieder einladen",
            copyInstructions: "Kopieren Sie den Einladungslink, um ihn mit den Eingeladenen zu teilen.",
            chatId: "Chat-ID",
            link: "Link",
            closeButtonText: "Schließen"
        },
        "it-IT": {
            title: "Invita i membri della chat",
            copyInstructions: "Copia il link di invito per condividerlo con gli invitati.",
            chatId: "ID chat",
            link: "Link",
            closeButtonText: "Chiudi"
        },
        "hi-IN": {
            title: "चैट सदस्यों को आमंत्रित करें",
            copyInstructions: "आमंत्रितों के साथ साझा करने के लिए आमंत्रण लिंक कॉपी करें।",
            chatId: "चैट आईडी",
            link: "लिंक",
            closeButtonText: "बंद करें"
        },
        "cmn-CN": {
            title: "邀请聊天成员",
            copyInstructions: "复制邀请链接以与受邀者共享。",
            chatId: "聊天 ID",
            link: "链接",
            closeButtonText: "关闭"
        },
        "so-SO": {
            title: "Ku martiqaad Xubnaha Wada sheekaysiga",
            copyInstructions: "Kuugu qori xiriirka diiwaangelinta si aad ugu wadaagto diiwaangalinta.",
            chatId: "Chat Id",
            link: "Link",
            closeButtonText: "Dhagayso"
        },
        "pt-PT": {
            title: "Convidar membros do chat",
            copyInstructions: "Copie o link de convite para compartilhar com os convidados.",
            chatId: "ID do chat",
            link: "Link",
            closeButtonText: "Fechar"
        },
        "pt-BR": {
            title: "Convidar membros do chat",
            copyInstructions: "Copie o link de convite para compartilhar com os convidados.",
            chatId: "ID do chat",
            link: "Link",
            closeButtonText: "Fechar"
        },
        "id-ID": {
            title: "Bergabunglah dalam Percakapan!",
            copyInstructions: "Salin tautan undangan untuk dibagikan dengan undangan.",
            chatId: "ID Chat",
            link: "Tautan",
            closeButtonText: "Tutup"
        },
        "vi-VN": {
            title: "Mời thành viên tham gia cuộc trò chuyện!",
            copyInstructions: "Sao chép liên kết mời để chia sẻ với người được mời.",
            chatId: "ID Cuộc trò chuyện",
            link: "Liên kết",
            closeButtonText: "Đóng"
        },
        "ko-KR": {
            title: "채팅 멤버 초대",
            copyInstructions: "초대 링크를 복사하여 초대 대상과 공유하십시오.",
            chatId: "채팅 ID",
            link: "링크",
            closeButtonText: "닫기"
        },
        "ja-JP": {
            title: "チャットメンバーを招待",
            copyInstructions: "招待リンクをコピーして招待者と共有します。",
            chatId: "チャット ID",
            link: "リンク",
            closeButtonText: "閉じる"
        },
        "ar-IQ": {
            title: "دعوة أعضاء الدردشة",
            copyInstructions: "انسخ الرابط للمشاركة مع المدعوين.",
            chatId: "معرف الدردشة",
            link: "رابط",
            closeButtonText: "إغلاق"
        },
        "fil-PH": {
            title: "Mag-imbita ng mga miyembro ng chat",
            copyInstructions: "Kopyahin ang link ng imbitasyon upang ibahagi sa mga inimbita.",
            chatId: "Chat ID",
            link: "Link",
            closeButtonText: "Isara"
        },
        "ne-NP": {
            title: "च्याट सदस्यहरूलाई आमन्त्रण गर्नुहोस्",
            copyInstructions: "आमन्त्रितहरूसँग साझा गर्नका लागि आमन्त्रण लिंक कपि गर्नुहोस्।",
            chatId: "च्याट आईडी",
            link: "लिंक",
            closeButtonText: "बन्द गर्नुहोस्"
        },
        "ar-SA": {
            title: "دعوة أعضاء الدردشة",
            copyInstructions: "انسخ الرابط للمشاركة مع المدعوين.",
            chatId: "معرف الدردشة",
            link: "رابط",
            closeButtonText: "إغلاق"
        },
        "am-ET": {
            title: "የቤት አባላትን ጠቀም",
            copyInstructions: "አባላትን አጋልጠው ለአባላት አገልግሎት ይችላሉ።",
            chatId: "የድርጅት መለያ",
            link: "አገልግሎት",
            closeButtonText: "ዝጋ"

        }
    },
    chatNameEditScreenContent: {
        "en-US": {
            title: "Edit Chat Name",
            description: "Edit the chat name. The change will be reflected to all members of the chat.",
            chatNameField: "Chat Name",
            saveButtonText: "Save",
            cancelButtonText: "Cancel"

        },
        "en-GB": {
            title: "Edit Chat Name",
            description: "Edit the chat name. The change will be reflected to all members of the chat.",
            chatNameField: "Chat Name",
            saveButtonText: "Save",
            cancelButtonText: "Cancel"
        },
        "es-ES": {
            title: "Editar Nombre del Chat",
            description: "Edita el nombre del chat. El cambio se reflejará en todos los miembros del chat.",
            chatNameField: "Nombre del Chat",
            saveButtonText: "Guardar",
            cancelButtonText: "Cancelar"
        },
        "es-MX": {
            title: "Editar Nombre del Chat",
            description: "Edita el nombre del chat. El cambio se reflejará en todos los miembros del chat.",
            chatNameField: "Nombre del Chat",
            saveButtonText: "Guardar",
            cancelButtonText: "Cancelar"

        },
        "fr-FR": {
            title: "Nom du chat",
            description: "Modifiez le nom du chat. Le changement sera visible pour tous les membres du chat.",
            chatNameField: "Nom du chat",
            saveButtonText: "Commence",
            cancelButtonText: "Annule"

        },
        "de-DE": {
            title: "Chat-Namen bearbeiten",
            description: "Bearbeite den Chat-Namen. Die Änderung wird für alle Chat-Mitglieder sichtbar sein.",
            chatNameField: "Chat-Name",
            saveButtonText: "Speichern",
            cancelButtonText: "Stornieren"

        },
        "it-IT": {
            title: "Modifica nome chat",
            description: "Modifica il nome della chat. La modifica sarà visibile a tutti i membri della chat.",
            chatNameField: "Nome della chat",
            saveButtonText: "Salva",
            cancelButtonText: "Annulla"

        },
        "hi-IN": {
            title: "चैट का नाम बदलें",
            description: "चैट का नाम बदलें। बदलाव चैट के सभी सदस्यों को दिखाई देगा।",
            chatNameField: "चैट नाम",
            saveButtonText: "बचाओ",
            cancelButtonText: "रद्द करें"

        },
        "cmn-CN": {
            title: "编辑聊天名称",
            description: "编辑聊天名称。更改将反映在聊天的所有成员中。",
            chatNameField: "聊天名称",
            saveButtonText: "保存",
            cancelButtonText: "取消"

        },
        "so-SO": {
            title: "Wax ka beddel Magaca Wadahadalka",
            description: "Wax ka beddel magaca wadahadalka. Beddelka waxaa la muujinayaa dhammaan xubnaha wadahadalka.",
            chatNameField: "Magaca Wadahadalka",
            saveButtonText: "Keyd",
            cancelButtonText: "Kaarka"
        },
        "pt-PT": {
            title: "Editar nome do chat",
            description: "Edite o nome do chat. A alteração será refletida em todos os membros do chat.",
            chatNameField: "Nome do chat",
            saveButtonText: "Salvar",
            cancelButtonText: "Cancelar"
        },
        "pt-BR": {
            title: "Editar nome do chat",
            description: "Edite o nome do chat. A alteração será refletida em todos os membros do chat.",
            chatNameField: "Nome do chat",
            saveButtonText: "Salvar",
            cancelButtonText: "Cancelar"
        },
        "id-ID": {
            title: "Edit Nama Obrolan",
            description: "Edit nama obrolan. Perubahan akan tercermin pada semua anggota obrolan.",
            chatNameField: "Nama Obrolan",
            saveButtonText: "Simpan",
            cancelButtonText: "Batal"
        },
        "vi-VN": {
            title: "Chỉnh sửa tên trò chuyện",
            description: "Chỉnh sửa tên trò chuyện. Thay đổi sẽ được phản ánh đến tất cả các thành viên của trò chuyện.",
            chatNameField: "Tên trò chuyện",
            saveButtonText: "Lưu",
            cancelButtonText: "Hủy"
        },
        "ko-KR": {
            title: "채팅 이름 편집",
            description: "채팅 이름을 편집합니다. 변경 사항은 채팅의 모든 구성원에 반영됩니다.",
            chatNameField: "채팅 이름",
            saveButtonText: "저장",
            cancelButtonText: "취소"
        },
        "ja-JP": {
            title: "チャット名を編集",
            description: "チャット名を編集します。変更はチャットのすべてのメンバーに反映されます。",
            chatNameField: "チャット名",
            saveButtonText: "保存",
            cancelButtonText: "キャンセル"
        },
        "ar-IQ": {
            title: "تحرير اسم الدردشة",
            description: "قم بتحرير اسم الدردشة. سيتم عرض التغيير على جميع أعضاء الدردشة.",
            chatNameField: "اسم الدردشة",
            saveButtonText: "حفظ",
            cancelButtonText: "إلغاء"
        },
        "fil-PH": {
            title: "I-edit ang Pangalan ng Chat",
            description: "I-edit ang pangalan ng chat. Ang pagbabago ay magrereflect sa lahat ng miyembro ng chat.",
            chatNameField: "Pangalan ng Chat",
            saveButtonText: "I-save",
            cancelButtonText: "I-cancel"
        },
        "ne-NP": {
            title: "च्याट नाम सम्पादन गर्नुहोस्",
            description: "च्याट नाम सम्पादन गर्नुहोस्। परिवर्तन च्याटका सबै सदस्यहरूमा प्रतिबिम्बित हुनेछ।",
            chatNameField: "च्याट नाम",
            saveButtonText: "बचाउनुहोस्",
            cancelButtonText: "रद्द गर्नुहोस्"
        },
        "ar-SA": {
            title: "تحرير اسم الدردشة",
            description: "قم بتحرير اسم الدردشة. سيتم عرض التغيير على جميع أعضاء الدردشة.",
            chatNameField: "اسم الدردشة",
            saveButtonText: "حفظ",
            cancelButtonText: "إلغاء"
        },
        "am-ET": {
            title: "የውይይት ስምን አስተካክል",
            description: "የውይይት ስምን አስተካክል። በውይይት ስም ላይ ያሉ አባላት እንዲሁም አባላት በውይይት ስም ላይ ያሉ አባላት ለመቀየር ይህን ይጠቀሙ።",
            chatNameField: "ውይይት ስም",
            saveButtonText: "አስቀምጥ",
            cancelButtonText: "ሰርዝ"

        }
    },
    dashboardCardContent: {
        "en-US": {
            createChatTitle: "Create a Chat!",
            createChatDescription: "To create a chat session, select the type and choose your options.",
            createChatPrimaryButtonText: "Get Started",
            joinChatTitle: "Join a Chat!",
            joinChatDescription: "",
            joinChatPrimaryButtonText: "Join",
            recentGroupChatTitle: "Group Chats",
            recentGroupChatDescription: "",
            recentGroupChatPrimaryButtonText: "Show All",
            recentTranslatorChatTitle: "Translations",
            recentTranslatorChatDescription: "",
            recentTranslatorChatPrimaryButtonText: "Show All",
            recentSharedChatTitle: "Shared Screen Chats",
            recentQuickConnectsTitle: "Quick Connects",
            recentSharedChatDescription: "",
            recentSharedChatPrimaryButtonText: "Show All"

        },
        "en-GB": {
            createChatTitle: "Create a Chat!",
            createChatDescription: "To create a chat session, select the type and choose your options.",
            createChatPrimaryButtonText: "Get Started",
            joinChatTitle: "Join a Chat!",
            joinChatDescription: "",
            joinChatPrimaryButtonText: "Join",
            recentGroupChatTitle: "Group Chats",
            recentGroupChatDescription: "",
            recentGroupChatPrimaryButtonText: "Show All",
            recentTranslatorChatTitle: "Translations",
            recentTranslatorChatDescription: "",
            recentTranslatorChatPrimaryButtonText: "Show All",
            recentSharedChatTitle: "Shared Screen Chats",
            recentQuickConnectsTitle: "Quick Connects",
            recentSharedChatDescription: "",
            recentSharedChatPrimaryButtonText: "Show All"

        },
        "es-ES": {
            createChatTitle: "Crea un chat!",
            createChatDescription: "Para crear una sesión de chat, selecciona el tipo y elige tus opciones.",
            createChatPrimaryButtonText: "Empieza",
            joinChatTitle: "¡Únete a un chat!",
            joinChatDescription: "",
            joinChatPrimaryButtonText: "Empieza",
            recentGroupChatTitle: "Chats grupales",
            recentGroupChatDescription: "",
            recentGroupChatPrimaryButtonText: "Mostrar todo",
            recentTranslatorChatTitle: "Traducciones",
            recentTranslatorChatDescription: "",
            recentTranslatorChatPrimaryButtonText: "Mostrar todo",
            recentSharedChatTitle: "Chats de pantalla compartida",
            recentQuickConnectsTitle: "Conexiones rápidas",
            recentSharedChatDescription: "",
            recentSharedChatPrimaryButtonText: "Mostrar todo",
        },
        "es-MX": {
            createChatTitle: "Crea un chat!",
            createChatDescription: "Para crear una sesión de chat, selecciona el tipo y elige tus opciones.",
            createChatPrimaryButtonText: "Empieza",
            joinChatTitle: "¡Únete a un chat!",
            joinChatDescription: "",
            joinChatPrimaryButtonText: "Empieza",
            recentGroupChatTitle: "Chats grupales",
            recentGroupChatDescription: "",
            recentGroupChatPrimaryButtonText: "Mostrar todo",
            recentTranslatorChatTitle: "Traducciones",
            recentTranslatorChatDescription: "",
            recentTranslatorChatPrimaryButtonText: "Mostrar todo",
            recentSharedChatTitle: "Chats de pantalla compartida",
            recentQuickConnectsTitle: "Conexiones rápidas",
            recentSharedChatDescription: "",
            recentSharedChatPrimaryButtonText: "Mostrar todo",

        },
        "fr-FR": {
            createChatTitle: "Créez un chat !",
            createChatDescription: "Pour créer une session de chat, sélectionnez le type et choisissez vos options.",
            createChatPrimaryButtonText: "Commencez",
            joinChatTitle: "Rejoignez un chat !",
            joinChatDescription: "",
            joinChatPrimaryButtonText: "Commencez",
            recentGroupChatTitle: "Discussions de groupe",
            recentGroupChatDescription: "",
            recentGroupChatPrimaryButtonText: "Afficher tout",
            recentTranslatorChatTitle: "Traductions",
            recentTranslatorChatDescription: "",
            recentTranslatorChatPrimaryButtonText: "Afficher tout",
            recentSharedChatTitle: "Discussions sur écran partagé",
            recentQuickConnectsTitle: "Connexions rapides",
            recentSharedChatDescription: "",
            recentSharedChatPrimaryButtonText: "Afficher tout",

        },
        "de-DE": {
            createChatTitle: "Erstelle einen Chat!",
            createChatDescription: "Um einen Chat zu starten, wähle den Typ und deine Optionen aus.",
            createChatPrimaryButtonText: "Legen Sie los",
            joinChatTitle: "Tritt einem Chat bei!",
            joinChatDescription: "",
            joinChatPrimaryButtonText: "Legen Sie los",
            recentGroupChatTitle: "Gruppenchats",
            recentGroupChatDescription: "",
            recentGroupChatPrimaryButtonText: "Alles anzeigen",
            recentTranslatorChatTitle: "Übersetzungen",
            recentTranslatorChatDescription: "",
            recentTranslatorChatPrimaryButtonText: "Alles anzeigen",
            recentSharedChatTitle: "Geteilte Bildschirm-Chats",
            recentQuickConnectsTitle: "Schnellverbindungen",
            recentSharedChatDescription: "",
            recentSharedChatPrimaryButtonText: "Alles anzeigen",

        },
        "it-IT": {
            createChatTitle: "Crea una chat!",
            createChatDescription: "Per creare una chat, seleziona il tipo e scegli le tue opzioni.",
            createChatPrimaryButtonText: "Inizia",
            joinChatTitle: "Partecipa a una chat!",
            joinChatDescription: "",
            joinChatPrimaryButtonText: "Inizia",
            recentGroupChatTitle: "Chat di gruppo",
            recentGroupChatDescription: "",
            recentGroupChatPrimaryButtonText: "Mostra tutto",
            recentTranslatorChatTitle: "Traduzioni",
            recentTranslatorChatDescription: "",
            recentTranslatorChatPrimaryButtonText: "Mostra tutto",
            recentSharedChatTitle: "Chat a schermo condiviso",
            recentQuickConnectsTitle: "Connessioni rapide",
            recentSharedChatDescription: "",
            recentSharedChatPrimaryButtonText: "Mostra tutto",

        },
        "hi-IN": {
            createChatTitle: "चैट बनाएँ!",
            createChatDescription: "चैट सेशन बनाने के लिए, टाइप चुनें और अपने विकल्प चुनें।",
            createChatPrimaryButtonText: "शुरू करें",
            joinChatTitle: "चैट में शामिल हों!",
            joinChatDescription: "",
            joinChatPrimaryButtonText: "शुरू करें",
            recentGroupChatTitle: "समूह चैट",
            recentGroupChatDescription: "",
            recentGroupChatPrimaryButtonText: "सब दिखाएँ",
            recentTranslatorChatTitle: "अनुवाद",
            recentTranslatorChatDescription: "",
            recentTranslatorChatPrimaryButtonText: "सब दिखाएँ",
            recentSharedChatTitle: "साझा स्क्रीन चैट",
            recentQuickConnectsTitle: "त्वरित संपर्क",
            recentSharedChatDescription: "",
            recentSharedChatPrimaryButtonText: "सब दिखाएँ",

        },
        "cmn-CN": {
            createChatTitle: "创建聊天！",
            createChatDescription: "要创建聊天会话，请选择类型并选择您的选项。",
            createChatPrimaryButtonText: "开始",
            joinChatTitle: "加入聊天！",
            joinChatDescription: "",
            joinChatPrimaryButtonText: "开始",
            recentGroupChatTitle: "群聊",
            recentGroupChatDescription: "",
            recentGroupChatPrimaryButtonText: "显示全部",
            recentTranslatorChatTitle: "翻译",
            recentTranslatorChatDescription: "",
            recentTranslatorChatPrimaryButtonText: "显示全部",
            recentSharedChatTitle: "共享屏幕聊天",
            recentQuickConnectsTitle: "快速连接",
            recentSharedChatDescription: "",
            recentSharedChatPrimaryButtonText: "显示全部",

        },
        "so-SO": {
            createChatTitle: "Samee sheeko!",
            createChatDescription: "Si aad sameysid qalab chat, dooro nooca iyo dooro xulashadaada.",
            createChatPrimaryButtonText: "Bilaabo",
            joinChatTitle: "Ku soo biir chatka!",
            joinChatDescription: "",
            joinChatPrimaryButtonText: "Bilaabo",
            recentGroupChatTitle: "Wada sheekaysi kooxeed",
            recentGroupChatDescription: "",
            recentGroupChatPrimaryButtonText: "Fiiri dhammaan",
            recentTranslatorChatTitle: "Turjumaada",
            recentTranslatorChatDescription: "",
            recentTranslatorChatPrimaryButtonText: "Fiiri dhammaan",
            recentSharedChatTitle: "Shaashada La Wadaago",
            recentQuickConnectsTitle: "Xiriirinta Degdegga ah",
            recentSharedChatDescription: "",
            recentSharedChatPrimaryButtonText: "Fiiri dhammaan",
        },
        "pt-PT": {
            createChatTitle: "Crie um chat!",
            createChatDescription: "Para criar uma sessão de chat, selecione o tipo e escolha suas opções.",
            createChatPrimaryButtonText: "Começar",
            joinChatTitle: "Junte-se a um chat!",
            joinChatDescription: "",
            joinChatPrimaryButtonText: "Começar",
            recentGroupChatTitle: "Bate-papos em grupo",
            recentGroupChatDescription: "",
            recentGroupChatPrimaryButtonText: "Mostrar tudo",
            recentTranslatorChatTitle: "Traduções",
            recentTranslatorChatDescription: "",
            recentTranslatorChatPrimaryButtonText: "Mostrar tudo",
            recentSharedChatTitle: "Bate-papos em ecrã partilhado",
            recentQuickConnectsTitle: "Conexões rápidas",
            recentSharedChatDescription: "",
            recentSharedChatPrimaryButtonText: "Mostrar tudo",
        },
        "pt-BR": {
            createChatTitle: "Crie um chat!",
            createChatDescription: "Para criar uma sessão de chat, selecione o tipo e escolha suas opções.",
            createChatPrimaryButtonText: "Começar",
            joinChatTitle: "Junte-se a um chat!",
            joinChatDescription: "",
            joinChatPrimaryButtonText: "Começar",
            recentGroupChatTitle: "Bate-papos em grupo",
            recentGroupChatDescription: "",
            recentGroupChatPrimaryButtonText: "Mostrar tudo",
            recentTranslatorChatTitle: "Traduções",
            recentTranslatorChatDescription: "",
            recentTranslatorChatPrimaryButtonText: "Mostrar tudo",
            recentSharedChatTitle: "Bate-papos em tela compartilhada",
            recentQuickConnectsTitle: "Conexões rápidas",
            recentSharedChatDescription: "",
            recentSharedChatPrimaryButtonText: "Mostrar tudo",
        },
        "id-ID": {
            createChatTitle: "Buat Obrolan!",
            createChatDescription: "Untuk membuat sesi obrolan, pilih jenis dan pilih opsi Anda.",
            createChatPrimaryButtonText: "Mulai",
            joinChatTitle: "Bergabung dengan Obrolan!",
            joinChatDescription: "",
            joinChatPrimaryButtonText: "Mulai",
            recentGroupChatTitle: "Obrolan Grup",
            recentGroupChatDescription: "",
            recentGroupChatPrimaryButtonText: "Tampilkan Semua",
            recentTranslatorChatTitle: "Terjemahan",
            recentTranslatorChatDescription: "",
            recentTranslatorChatPrimaryButtonText: "Tampilkan Semua",
            recentSharedChatTitle: "Obrolan Layar Bersama",
            recentQuickConnectsTitle: "Koneksi Cepat",
            recentSharedChatDescription: "",
            recentSharedChatPrimaryButtonText: "Tampilkan Semua",
        },
        "vi-VN": {
            createChatTitle: "Tạo cuộc trò chuyện!",
            createChatDescription: "Để tạo một phiên trò chuyện, hãy chọn loại và chọn tùy chọn của bạn.",
            createChatPrimaryButtonText: "Bắt đầu",
            joinChatTitle: "Tham gia cuộc trò chuyện!",
            joinChatDescription: "",
            joinChatPrimaryButtonText: "Bắt đầu",
            recentGroupChatTitle: "Trò chuyện nhóm",
            recentGroupChatDescription: "",
            recentGroupChatPrimaryButtonText: "Hiển thị tất cả",
            recentTranslatorChatTitle: "Bản dịch",
            recentTranslatorChatDescription: "",
            recentTranslatorChatPrimaryButtonText: "Hiển thị tất cả",
            recentSharedChatTitle: "Trò chuyện màn hình được chia sẻ",
            recentQuickConnectsTitle: "Kết nối nhanh",
            recentSharedChatDescription: "",
            recentSharedChatPrimaryButtonText: "Hiển thị tất cả",
        },
        "ko-KR": {
            createChatTitle: "채팅 만들기!",
            createChatDescription: "채팅 세션을 만들려면 유형을 선택하고 옵션을 선택하십시오.",
            createChatPrimaryButtonText: "시작",
            joinChatTitle: "채팅에 참여하십시오!",
            joinChatDescription: "",
            joinChatPrimaryButtonText: "시작",
            recentGroupChatTitle: "그룹 채팅",
            recentGroupChatDescription: "",
            recentGroupChatPrimaryButtonText: "모두 표시",
            recentTranslatorChatTitle: "번역",
            recentTranslatorChatDescription: "",
            recentTranslatorChatPrimaryButtonText: "모두 표시",
            recentSharedChatTitle: "공유 화면 채팅",
            recentQuickConnectsTitle: "빠른 연결",
            recentSharedChatDescription: "",
            recentSharedChatPrimaryButtonText: "모두 표시",
        },
        "ja-JP": {
            createChatTitle: "チャットを作成する！",
            createChatDescription: "チャットセッションを作成するには、タイプを選択し、オプションを選択してください。",
            createChatPrimaryButtonText: "開始",
            joinChatTitle: "チャットに参加する！",
            joinChatDescription: "",
            joinChatPrimaryButtonText: "開始",
            recentGroupChatTitle: "グループチャット",
            recentGroupChatDescription: "",
            recentGroupChatPrimaryButtonText: "すべて表示",
            recentTranslatorChatTitle: "翻訳",
            recentTranslatorChatDescription: "",
            recentTranslatorChatPrimaryButtonText: "すべて表示",
            recentSharedChatTitle: "共有画面チャット",
            recentQuickConnectsTitle: "クイック接続",
            recentSharedChatDescription: "",
            recentSharedChatPrimaryButtonText: "すべて表示",
        },
        "ar-IQ": {
            createChatTitle: "إنشاء محادثة!",
            createChatDescription: "لإنشاء جلسة محادثة، حدد النوع واختر خياراتك.",
            createChatPrimaryButtonText: "ابدأ",
            joinChatTitle: "الانضمام إلى المحادثة!",
            joinChatDescription: "",
            joinChatPrimaryButtonText: "ابدأ",
            recentGroupChatTitle: "الدردشات الجماعية",
            recentGroupChatDescription: "",
            recentGroupChatPrimaryButtonText: "عرض الكل",
            recentTranslatorChatTitle: "ترجمات",
            recentTranslatorChatDescription: "",
            recentTranslatorChatPrimaryButtonText: "عرض الكل",
            recentSharedChatTitle: "محادثات الشاشة المشتركة",
            recentQuickConnectsTitle: "اتصالات سريعة",
            recentSharedChatDescription: "",
            recentSharedChatPrimaryButtonText: "عرض الكل",
        },
        "fil-PH": {
            createChatTitle: "Gumawa ng Chat!",
            createChatDescription: "Upang lumikha ng sesyon ng chat, pumili ng uri at piliin ang iyong opsyon.",
            createChatPrimaryButtonText: "Simulan",
            joinChatTitle: "Sumali sa Chat!",
            joinChatDescription: "",
            joinChatPrimaryButtonText: "Simulan",
            recentGroupChatTitle: "Mga Panggrupong Chat",
            recentGroupChatDescription: "",
            recentGroupChatPrimaryButtonText: "Ipakita Lahat",
            recentTranslatorChatTitle: "Mga pagsasalin",
            recentTranslatorChatDescription: "",
            recentTranslatorChatPrimaryButtonText: "Ipakita Lahat",
            recentSharedChatTitle: "Mga Shared Screen Chat",
            recentQuickConnectsTitle: "Mabilis na Kumokonekta",
            recentSharedChatDescription: "",
            recentSharedChatPrimaryButtonText: "Ipakita Lahat",
        },
        "ne-NP": {
            createChatTitle: "च्याट सिर्जना गर्नुहोस्!",
            createChatDescription: "च्याट सेसन सिर्जना गर्नका लागि प्रकार छनौट गर्नुहोस् र तपाईंको विकल्प छनौट गर्नुहोस्।",
            createChatPrimaryButtonText: "सुरु गर्नुहोस्",
            joinChatTitle: "च्याटमा सामेल हुनुहोस्!",
            joinChatDescription: "",
            joinChatPrimaryButtonText: "सुरु गर्नुहोस्",
            recentGroupChatTitle: "समूह च्याटहरू",
            recentGroupChatDescription: "",
            recentGroupChatPrimaryButtonText: "सबै देखाउनुहोस्",
            recentTranslatorChatTitle: "अनुवादहरू",
            recentTranslatorChatDescription: "",
            recentTranslatorChatPrimaryButtonText: "सबै देखाउनुहोस्",
            recentSharedChatTitle: "साझा स्क्रिन च्याट",
            recentQuickConnectsTitle: "द्रुत जडानहरू",
            recentSharedChatDescription: "",
            recentSharedChatPrimaryButtonText: "सबै देखाउनुहोस्",
        },
        "ar-SA": {
            createChatTitle: "إنشاء محادثة!",
            createChatDescription: "لإنشاء جلسة محادثة، حدد النوع واختر خياراتك.",
            createChatPrimaryButtonText: "ابدأ",
            joinChatTitle: "الانضمام إلى المحادثة!",
            joinChatDescription: "",
            joinChatPrimaryButtonText: "ابدأ",
            recentGroupChatTitle: "الدردشات الجماعية",
            recentGroupChatDescription: "",
            recentGroupChatPrimaryButtonText: "عرض الكل",
            recentTranslatorChatTitle: "ترجمات",
            recentTranslatorChatDescription: "",
            recentTranslatorChatPrimaryButtonText: "عرض الكل",
            recentSharedChatTitle: "محادثات الشاشة المشتركة",
            recentQuickConnectsTitle: "اتصالات سريعة",
            recentSharedChatDescription: "",
            recentSharedChatPrimaryButtonText: "عرض الكل",
        },
        "am-ET": {
            createChatTitle: "ቻት አልተፈጠረም!",
            createChatDescription: "ቻት ስለሚፈጥር ስምምነት ምረጥ ይሆንብናል፣ ምርጫዎችዎን ይምረጡ።",
            createChatPrimaryButtonText: "አስጀምሩ",
            joinChatTitle: "ቻት ይቅር!",
            joinChatDescription: "",
            joinChatPrimaryButtonText: "አስጀምሩ",
            recentGroupChatTitle: "የቡድን ውይይቶች",
            recentGroupChatDescription: "",
            recentGroupChatPrimaryButtonText: "ሁሉንም እዚህ እዚህ",
            recentTranslatorChatTitle: "ትርጉሞች",
            recentTranslatorChatDescription: "",
            recentTranslatorChatPrimaryButtonText: "ሁሉንም እዚህ እዚህ",
            recentSharedChatTitle: "የተጋሩ ስክሪን ቻቶች",
            recentQuickConnectsTitle: "ፈጣን ማገናኛዎች",
            recentSharedChatDescription: "",
            recentSharedChatPrimaryButtonText: "ሁሉንም እዚህ እዚህ",
        }
    },
    userWidgetsTopRight: {
        "en-US": {
            logoutButtonText: "Logout",
            accountDescription: "Account",
            settingsDescription: "Settings"
        },
        "en-GB": {
            logoutButtonText: "Logout",
            accountDescription: "Account",
            settingsDescription: "Settings"
        },
        "es-ES": {
            logoutButtonText: "Cerrar sesión",
            accountDescription: "Cuenta",
            settingsDescription: "Configuración"

        },
        "es-MX": {
            logoutButtonText: "Cerrar sesión",
            accountDescription: "Cuenta",
            settingsDescription: "Configuración"
        },
        "fr-FR": {
            logoutButtonText: "Déconnexion",
            accountDescription: "Compte",
            settingsDescription: "Paramètres"
        },
        "de-DE": {
            logoutButtonText: "Abmelden",
            accountDescription: "Konto",
            settingsDescription: "Einstellungen"
        },
        "it-IT": {
            logoutButtonText: "Esci",
            accountDescription: "Account",
            settingsDescription: "Impostazioni"
        },
        "hi-IN": {
            logoutButtonText: "लॉग आउट करें",
            accountDescription: "खाता",
            settingsDescription: "सेटिंग्स"
        },
        "cmn-CN": {
            logoutButtonText: "退出",
            accountDescription: "帐户",
            settingsDescription: "设置"
        },
        "so-SO": {
            logoutButtonText: "Ka bax",
            accountDescription: "Xisaab",
            settingsDescription: "Go'aan"
        },
        "pt-PT": {
            logoutButtonText: "Sair",
            accountDescription: "Conta",
            settingsDescription: "Configurações"
        },
        "pt-BR": {
            logoutButtonText: "Sair",
            accountDescription: "Conta",
            settingsDescription: "Configurações"
        },
        "id-ID": {
            logoutButtonText: "Keluar",
            accountDescription: "Akun",
            settingsDescription: "Pengaturan"
        },
        "vi-VN": {
            logoutButtonText: "Đăng xuất",
            accountDescription: "Tài khoản",
            settingsDescription: "Cài đặt"
        },
        "ko-KR": {
            logoutButtonText: "로그 아웃",
            accountDescription: "계정",
            settingsDescription: "설정"
        },
        "ja-JP": {
            logoutButtonText: "ログアウト",
            accountDescription: "アカウント",
            settingsDescription: "設定"
        },
        "ar-IQ": {
            logoutButtonText: "تسجيل الخروج",
            accountDescription: "الحساب",
            settingsDescription: "الإعدادات"
        },
        "fil-PH": {
            logoutButtonText: "Mag-logout",
            accountDescription: "Account",
            settingsDescription: "Mga Setting"
        },
        "ne-NP": {
            logoutButtonText: "लगआउट",
            accountDescription: "खाता",
            settingsDescription: "सेटिंग्स"
        },
        "ar-SA": {
            logoutButtonText: "تسجيل الخروج",
            accountDescription: "الحساب",
            settingsDescription: "الإعدادات"
        },
        "am-ET": {
            logoutButtonText: "ውጣ",
            accountDescription: "መለያ",
            settingsDescription: "ቅንብሮች"
        }
    },
    createChatPanel: {
        "en-US": {
            title: "Create a Chat",
            headline: "Let's get started!",
            linkContentHead: "Connect with others!",
            linkContentDescription: "Invite others by having them scan this QR code or by sharing the link below.",
            linkContentButton: "Continue to the Chat Screen",
            selectChatTypeLabel: "Select Chat Type",
            selectChatTypeGroupChat: "New Group Chat",
            selectChatTypeTranslationChat: "New Personal Translator",
            selectChatTypeSharedScreenChat: "New Shared Screen Chat",
            selectChatTypeQuickConnectChat: "New Quick Connect Chat",
            descriptionGroupChat: "Create a group chat allows you to invite and chat with multiple people from many locations.",
            descriptionTranslationChat: "Start a personal translator that quickly translates words and phrases just for you.",
            descriptionSharedScreenChat: "Have a multi-lingual chat with another person on the same device.",
            descriptionQuickConnectChat: "Start a one-hour chat with a non-member that doesn't have their own account.",
            changeButton: "Change",
            chatNameLabel: "Chat Name",
            guestNameLabel: "Guest Name",
            myLanguageLabel: "My Language",
            translateToLanguageLabel: "Translate to Language",
            createChatDescription: "To create a chat session, select the type and choose your options.",
            cancelButton: "Cancel",
            createButton: "Create",
        },
        "en-GB": {
            title: "Create a Chat",
            headline: "Let's get started!",
            linkContentHead: "Connect with others!",
            linkContentDescription: "Invite others by having them scan this QR code or by sharing the link below.",
            linkContentButton: "Continue to the Chat Screen",
            selectChatTypeLabel: "Select Chat Type",
            selectChatTypeGroupChat: "New Group Chat",
            selectChatTypeTranslationChat: "New Personal Translator",
            selectChatTypeSharedScreenChat: "New Shared Screen Chat",
            selectChatTypeQuickConnectChat: "New Quick Connect Chat",
            descriptionGroupChat: "Create a group chat allows you to invite and chat with multiple people from many locations.",
            descriptionTranslationChat: "Start a personal translator that quickly translates words and phrases just for you.",
            descriptionSharedScreenChat: "Have a multi-lingual chat with another person on the same device.",
            descriptionQuickConnectChat: "Start a one-hour chat with a non-member that doesn't have their own account.",
            changeButton: "Change",
            chatNameLabel: "Chat Name",
            guestNameLabel: "Guest Name",
            myLanguageLabel: "My Language",
            translateToLanguageLabel: "Translate to Language",
            createChatDescription: "To create a chat session, select the type and choose your options.",
            cancelButton: "Cancel",
            createButton: "Create",
        },
        "es-ES": {
            title: "Crea un chat",
            headline: "¡Empecemos!",
            linkContentHead: "¡Conéctate con otros!",
            linkContentDescription: "Invita a otros haciéndoles escanear este código QR o compartiendo el enlace a continuación.",
            linkContentButton: "Continuar a la pantalla de chat",
            selectChatTypeLabel: "Selecciona el Tipo de Chat",
            selectChatTypeGroupChat: "Nuevo chat grupal",
            selectChatTypeTranslationChat: "Nuevo Traductor Personal",
            selectChatTypeSharedScreenChat: "Nueva pantalla compartida",
            selectChatTypeQuickConnectChat: "Nuevo chat de conexión rápida",
            descriptionGroupChat: "Crear un chat grupal te permite invitar y chatear con varias personas de diferentes lugares.",
            descriptionTranslationChat: "Inicia un traductor personal que traduce rápidamente palabras y frases solo para ti.",
            descriptionSharedScreenChat: "Tener un chat multilingüe con otra persona en el mismo dispositivo.",
            descriptionQuickConnectChat: "Inicie un chat de una hora con alguien que no sea miembro y que no tenga su propia cuenta.",
            changeButton: "Cambiar",
            chatNameLabel: "Nombre del chat",
            guestNameLabel: "Nombre del invitado",
            myLanguageLabel: "Mi idioma",
            translateToLanguageLabel: "Traducir al idioma",
            createChatDescription: "Para crear una sesión de chat, selecciona el tipo y elige tus opciones.",
            cancelButton: "Cancelar",
            createButton: "Crear",

        },
        "es-MX": {
            title: "Crea un chat",
            headline: "¡Empecemos!",
            linkContentHead: "¡Conéctate con otros!",
            linkContentDescription: "Invita a otros haciéndoles escanear este código QR o compartiendo el enlace a continuación.",
            linkContentButton: "Continuar a la pantalla de chat",
            selectChatTypeLabel: "Selecciona el Tipo de Chat",
            selectChatTypeGroupChat: "Nuevo grupo de chat",
            selectChatTypeTranslationChat: "Traductor personal nuevo",
            selectChatTypeSharedScreenChat: "Nueva pantalla compartida",
            selectChatTypeQuickConnectChat: "Nuevo chat de conexión rápida",
            descriptionGroupChat: "Crear un chat grupal te permite invitar y chatear con varias personas de diferentes lugares.",
            descriptionTranslationChat: "Inicia un traductor personal que traduce rápidamente palabras y frases solo para ti.",
            descriptionSharedScreenChat: "Tener un chat multilingüe con otra persona en el mismo dispositivo.",
            descriptionQuickConnectChat: "Inicie un chat de una hora con alguien que no sea miembro y que no tenga su propia cuenta.",
            changeButton: "Cambiar",
            chatNameLabel: "Nombre del chat",
            guestNameLabel: "Nombre del invitado",
            myLanguageLabel: "Mi idioma",
            translateToLanguageLabel: "Traducir al idioma",
            createChatDescription: "Para crear una sesión de chat, selecciona el tipo y elige tus opciones.",
            cancelButton: "Cancelar",
            createButton: "Crear",
        },
        "fr-FR": {
            title: "Créez un chat",
            headline: "Commençons !",
            linkContentHead: "Connectez-vous avec d'autres !",
            linkContentDescription: "Invitez d'autres personnes en leur faisant scanner ce code QR ou en partageant le lien ci-dessous.",
            linkContentButton: "Continuer vers l'écran de chat",
            selectChatTypeLabel: "Sélectionnez le type de chat",
            selectChatTypeGroupChat: "Nouveau chat de groupe",
            selectChatTypeTranslationChat: "Nouveau traducteur personnel",
            selectChatTypeSharedScreenChat: "Nouveau chat d'écran partagé",
            selectChatTypeQuickConnectChat: "Nouveau chat de connexion rapide",
            descriptionGroupChat: "Créer une discussion de groupe vous permet d'inviter et de discuter avec plusieurs personnes provenant de nombreux endroits.",
            descriptionTranslationChat: "Démarrez un traducteur personnel qui traduit rapidement des mots et des phrases juste pour vous.",
            descriptionSharedScreenChat: "Commencez un chat multilingue avec une autre personne sur le même appareil.",
            descriptionQuickConnectChat: "Démarrez une conversation d'une heure avec un non-membre qui ne possède pas son propre compte.",
            changeButton: "Changer",
            chatNameLabel: "Nom du chat",
            guestNameLabel: "Nom de l'invité",
            myLanguageLabel: "Ma langue",
            translateToLanguageLabel: "Traduire en langue",
            createChatDescription: "Pour créer une session de chat, sélectionnez le type et choisissez vos options.",
            cancelButton: "Annule",
            createButton: "Créer",

        },
        "de-DE": {
            title: "Erstelle einen Chat",
            headline: "Lass uns anfangen!",
            linkContentHead: "Verbinde dich mit anderen!",
            linkContentDescription: "Lade andere ein, indem sie diesen QR-Code scannen oder den unten stehenden Link teilen.",
            linkContentButton: "Weiter zum Chat-Bildschirm",
            selectChatTypeLabel: "Wähle Chat-Typ",
            selectChatTypeGroupChat: "Neuer Gruppenchat",
            selectChatTypeTranslationChat: "Neuer persönlicher Übersetzer",
            selectChatTypeSharedScreenChat: "Neuer geteilter Bildschirm-Chat",
            selectChatTypeQuickConnectChat: "Neuer Schnellverbindungschatt",
            descriptionGroupChat: "Erstellen Sie einen Gruppenchat, der es Ihnen ermöglicht, mehrere Personen aus verschiedenen Orten einzuladen und zu chatten.",
            descriptionTranslationChat: "Starten Sie einen persönlichen Übersetzer, der Wörter und Phrasen schnell nur für Sie übersetzt.",
            descriptionSharedScreenChat: "Starten Sie einen mehrsprachigen Chat mit einer anderen Person auf demselben Gerät.",
            descriptionQuickConnectChat: "Starten Sie einen einstündigen Chat mit einem Nichtmitglied, das kein eigenes Konto hat.",
            changeButton: "Ändern",
            chatNameLabel: "Chat-Name",
            guestNameLabel: "Gastname",
            myLanguageLabel: "Meine Sprache",
            translateToLanguageLabel: "Übersetzen in Sprache",
            createChatDescription: "Um einen Chat zu starten, wähle den Typ und deine Optionen aus.",
            cancelButton: "Stornieren",
            createButton: "Erstellen",

        },
        "it-IT": {
            title: "Crea una chat",
            headline: "Iniziamo!",
            linkContentHead: "Connettiti con gli altri!",
            linkContentDescription: "Invita gli altri facendoli scansionare questo codice QR o condividendo il link sottostante.",
            linkContentButton: "Continua alla schermata della chat",
            selectChatTypeLabel: "Seleziona il tipo di chat",
            selectChatTypeGroupChat: "Nuova chat di gruppo",
            selectChatTypeTranslationChat: "Nuovo traduttore personale",
            selectChatTypeSharedScreenChat: "Nuova chat condivisa",
            selectChatTypeQuickConnectChat: "Nuova chat di connessione rapida",
            descriptionGroupChat: "Avvia una chat di gruppo che ti consente di invitare e chattare con più persone da luoghi diversi.",
            descriptionTranslationChat: "Avvia un traduttore personale che traduce rapidamente parole e frasi solo per te.",
            descriptionSharedScreenChat: "Avvia una chat multilingue con un'altra persona sullo stesso dispositivo.",
            descriptionQuickConnectChat: "Avvia una chat di un'ora con un non membro che non dispone di un proprio account.",
            changeButton: "Cambia",
            chatNameLabel: "Nome della chat",
            guestNameLabel: "Nome dell'ospite",
            myLanguageLabel: "La mia lingua",
            translateToLanguageLabel: "Traduci in lingua",
            createChatDescription: "Per creare una chat, seleziona il tipo e scegli le tue opzioni.",
            cancelButton: "Annulla",
            createButton: "Crea",

        },
        "hi-IN": {
            title: "चैट बनाएँ!",
            headline: "आइए शुरू करें!",
            linkContentHead: "दूसरों से जुड़ें!",
            linkContentDescription: "इस QR कोड को स्कैन करके या नीचे दिए गए लिंक को साझा करके दूसरों को आमंत्रित करें।",
            linkContentButton: "चैट स्क्रीन पर जारी रखें",
            selectChatTypeLabel: "चैट प्रकार चुनें",
            selectChatTypeGroupChat: "नया समूह चैट",
            selectChatTypeTranslationChat: "नया व्यक्तिगत अनुवादक",
            selectChatTypeSharedScreenChat: "नया साझा स्क्रीन चैट",
            selectChatTypeQuickConnectChat: "नया त्वरित कनेक्ट चैट",
            descriptionGroupChat: "किएक समूह चैट बनाने से आप कई स्थानों से कई लोगों को आमंत्रित और चैट कर सकते हैं।",
            descriptionTranslationChat: "एक व्यक्तिगत अनुवादक शुरू करें जो शब्दों और वाक्यों का तुरंत अनुवाद करता है सिर्फ आपके लिए।",
            descriptionSharedScreenChat: "एक दूसरे व्यक्ति के साथ एक ही उपकरण पर बहु-भाषी चैट शुरू करें।",
            descriptionQuickConnectChat: "एक घंटे की चैट शुरू करें जिसमें एक गैर सदस्य के साथ चैट करें जिसके पास अपना खाता नहीं है।",
            changeButton: "बदलें",
            chatNameLabel: "चैट नाम",
            guestNameLabel: "अतिथि का नाम",
            myLanguageLabel: "मेरी भाषा",
            translateToLanguageLabel: "भाषा में अनुवाद करें",
            createChatDescription: "चैट सेशन बनाने के लिए, टाइप चुनें और अपने विकल्प चुनें।",
            cancelButton: "रद्द करें",
            createButton: "बनाएँ",

        },
        "cmn-CN": {
            title: "创建聊天",
            headline: "让我们开始吧！",
            linkContentHead: "与其他人联系！",
            linkContentDescription: "通过让其他人扫描此二维码或共享下面的链接来邀请其他人。",
            linkContentButton: "继续到聊天屏幕",
            selectChatTypeLabel: "选择聊天类型",
            selectChatTypeGroupChat: "新群聊",
            selectChatTypeTranslationChat: "新个人翻译",
            selectChatTypeSharedScreenChat: "新共享屏幕聊天",
            selectChatTypeQuickConnectChat: "新快速连接聊天",
            descriptionGroupChat: "开始与没有自己帐户的非会员进行一小时的聊天。",
            descriptionTranslationChat: "开始一个个人翻译器，它可以快速为您翻译单词和短语。",
            descriptionSharedScreenChat: "在同一设备上与另一个人进行多语言聊天。",
            descriptionQuickConnectChat: "开始一个群聊，允许您邀请并与来自不同地点的多人聊天。",
            changeButton: "更改",
            chatNameLabel: "聊天名称",
            guestNameLabel: "访客名称",
            myLanguageLabel: "我的语言",
            translateToLanguageLabel: "翻译成语言",
            createChatDescription: "要创建聊天会话，请选择类型并选择您的选项。",
            cancelButton: "取消",
            createButton: "创建",
        },
        "so-SO": {
            title: "Samee Sheeko",
            headline: "Bilow!",
            linkContentHead: "Iska xiriir dadka kale!",
            linkContentDescription: "Dhiirigelin dadka kale iyagoo ku kala duwan QR code-kaan ama wadaagga xiriirka hoose.",
            linkContentButton: "Ku soo bax Goobta Sheekada",
            selectChatTypeLabel: "Xulo Nooca Sheeko",
            selectChatTypeGroupChat: "Sheeko Gurmad ah",
            selectChatTypeTranslationChat: "Turjuma Shaqsiga ah",
            selectChatTypeSharedScreenChat: "Sheeko Laydhka Wadaagta",
            selectChatTypeQuickConnectChat: "Sheeko Xiriirka Koronto ah",
            descriptionGroupChat: "Bilow sheeko gurmad ah oo kuu oggolaanaya inaad ku kala duwanaato iyo kala duwanaato dad badan oo ka yimid meelo badan.",
            descriptionTranslationChat: "Bilow turjuma shaqsiga ah oo ku turjuma ereyo iyo qoraalo si aad u turjumaan.",
            descriptionSharedScreenChat: "Ku bilow sheeko luqadaha kala duwan ee qof kale ee la xiriira qalabka.",
            descriptionQuickConnectChat: "Ku bilow sheeko hal saac ah qof aan xubin ka ahayn oo aan lahayn akoon u gaar ah.",
            changeButton: "Badal",
            chatNameLabel: "Magaca Sheekada",
            guestNameLabel: "Magaca Taleefoonka",
            myLanguageLabel: "Luqadaaga",
            translateToLanguageLabel: "Turjuma Luqad",
            createChatDescription: "Si aad sameysid qalabka sheeko, dooro nooca iyo dooro xulashadaada.",
            cancelButton: "Ka soo bax",
            createButton: "Samee",
        },
        "pt-PT": {
            title: "Crie um chat",
            headline: "Vamos começar!",
            linkContentHead: "Conecte-se com os outros!",
            linkContentDescription: "Convide outros fazendo-os escanear este código QR ou compartilhando o link abaixo.",
            linkContentButton: "Continuar para a tela de chat",
            selectChatTypeLabel: "Selecione o tipo de chat",
            selectChatTypeGroupChat: "Nova conversa em grupo",
            selectChatTypeTranslationChat: "Novo tradutor pessoal",
            selectChatTypeSharedScreenChat: "Nova conversa de ecrã partilhado",
            selectChatTypeQuickConnectChat: "Nova conversa de ligação rápida",
            descriptionGroupChat: "Inicie um chat de grupo que lhe permite convidar e conversar com várias pessoas de diferentes locais.",
            descriptionTranslationChat: "Inicie um tradutor pessoal que traduz rapidamente palavras e frases apenas para si.",
            descriptionSharedScreenChat: "Inicie um chat multilingue com outra pessoa no mesmo dispositivo.",
            descriptionQuickConnectChat: "Inicie um chat de uma hora com um não-membro que não tenha conta própria.",
            changeButton: "Alterar",
            chatNameLabel: "Nome do chat",
            guestNameLabel: "Nome do convidado",
            myLanguageLabel: "Meu idioma",
            translateToLanguageLabel: "Traduzir para o idioma",
            createChatDescription: "Para criar uma sessão de chat, selecione o tipo e escolha suas opções.",
            cancelButton: "Cancelar",
            createButton: "Criar",
        },
        "pt-BR": {
            title: "Crie um chat",
            headline: "Vamos começar!",
            linkContentHead: "Conecte-se com os outros!",
            linkContentDescription: "Convide outros fazendo-os escanear este código QR ou compartilhando o link abaixo.",
            linkContentButton: "Continuar para a tela de chat",
            selectChatTypeLabel: "Selecione o tipo de chat",
            selectChatTypeGroupChat: "Nova conversa em grupo",
            selectChatTypeTranslationChat: "Novo tradutor pessoal",
            selectChatTypeSharedScreenChat: "Nova conversa de tela compartilhada",
            selectChatTypeQuickConnectChat: "Nova conversa de conexão rápida",
            descriptionGroupChat: "Inicie um chat de grupo que lhe permite convidar e conversar com várias pessoas de diferentes locais.",
            descriptionTranslationChat: "Inicie um tradutor pessoal que traduz rapidamente palavras e frases apenas para si.",
            descriptionSharedScreenChat: "Inicie um chat multilingue com outra pessoa no mesmo dispositivo.",
            descriptionQuickConnectChat: "Inicie um chat de uma hora com um não-membro que não tenha conta própria.",
            changeButton: "Alterar",
            chatNameLabel: "Nome do chat",
            guestNameLabel: "Nome do convidado",
            myLanguageLabel: "Meu idioma",
            translateToLanguageLabel: "Traduzir para o idioma",
            createChatDescription: "Para criar uma sessão de chat, selecione o tipo e escolha suas opções.",
            cancelButton: "Cancelar",
            createButton: "Criar",
        },
        "id-ID": {
            title: "Buat Obrolan",
            headline: "Ayo mulai!",
            linkContentHead: "Terhubung dengan orang lain!",
            linkContentDescription: "Undang orang lain dengan memindai kode QR ini atau dengan membagikan tautan di bawah.",
            linkContentButton: "Lanjutkan ke Layar Obrolan",
            selectChatTypeLabel: "Pilih Jenis Obrolan",
            selectChatTypeGroupChat: "Obrolan Grup Baru",
            selectChatTypeTranslationChat: "Penerjemah Pribadi Baru",
            selectChatTypeSharedScreenChat: "Obrolan Layar Bersama Baru",
            selectChatTypeQuickConnectChat: "Obrolan Koneksi Cepat Baru",
            descriptionGroupChat: "Mulai obrolan grup yang memungkinkan Anda mengundang dan mengobrol dengan beberapa orang dari berbagai lokasi.",
            descriptionTranslationChat: "Mulai penerjemah pribadi yang menerjemahkan kata dan frasa dengan cepat hanya untuk Anda.",
            descriptionSharedScreenChat: "Buat obrolan multibahasa dengan orang lain di perangkat yang sama.",
            descriptionQuickConnectChat: "Mulai obrolan satu jam dengan non-anggota yang tidak memiliki akun mereka sendiri.",
            changeButton: "Ubah",
            chatNameLabel: "Nama Obrolan",
            guestNameLabel: "Nama Tamu",
            myLanguageLabel: "Bahasa Saya",
            translateToLanguageLabel: "Terjemahkan ke Bahasa",
            createChatDescription: "Untuk membuat sesi obrolan, pilih jenis dan pilih opsi Anda.",
            cancelButton: "Batal",
            createButton: "Buat",
        },
        "vi-VN": {
            title: "Tạo Trò Chuyện",
            headline: "Hãy bắt đầu!",
            linkContentHead: "Kết nối với người khác!",
            linkContentDescription: "Mời người khác bằng cách cho họ quét mã QR này hoặc chia sẻ liên kết bên dưới.",
            linkContentButton: "Tiếp tục đến màn hình trò chuyện",
            selectChatTypeLabel: "Chọn Loại Trò Chuyện",
            selectChatTypeGroupChat: "Trò Chuyện Nhóm Mới",
            selectChatTypeTranslationChat: "Biên Dịch Cá Nhân Mới",
            selectChatTypeSharedScreenChat: "Trò Chuyện",
            selectChatTypeQuickConnectChat: "Trò Chuyện Kết Nối Nhanh Mới",
            descriptionGroupChat: "Tạo cuộc trò chuyện nhóm cho phép bạn mời và trò chuyện với nhiều người từ nhiều địa điểm.",
            descriptionTranslationChat: "Bắt đầu một trình dịch cá nhân dịch nhanh chóng từ và cụm từ chỉ dành cho bạn.",
            descriptionSharedScreenChat: "Có một cuộc trò chuyện đa ngôn ngữ với một người khác trên cùng một thiết bị.",
            descriptionQuickConnectChat: "Bắt đầu một cuộc trò chuyện một giờ với một người không phải là thành viên không có tài khoản riêng.",
            changeButton: "Thay đổi",
            chatNameLabel: "Tên Trò Chuyện",
            guestNameLabel: "Tên Khách",
            myLanguageLabel: "Ngôn Ngữ Của Tôi",
            translateToLanguageLabel: "Dịch Sang Ngôn Ngữ",
            createChatDescription: "Để tạo một phiên trò chuyện, chọn loại và chọn tùy chọn của bạn.",
            cancelButton: "Hủy",
            createButton: "Tạo",
        },
        "ko-KR": {
            title: "채팅 만들기",
            headline: "시작합시다!",
            linkContentHead: "다른 사람들과 연결하십시오!",
            linkContentDescription: "다른 사람들을 초대하려면 이 QR 코드를 스캔하거나 아래 링크를 공유하십시오.",
            linkContentButton: "채팅 화면으로 이동",
            selectChatTypeLabel: "채팅 유형 선택",
            selectChatTypeGroupChat: "새 그룹 채팅",
            selectChatTypeTranslationChat: "새 개인 번역",
            selectChatTypeSharedScreenChat: "새로 공유 화면 채팅",
            selectChatTypeQuickConnectChat: "새 빠른 연결 채팅",
            descriptionGroupChat: "그룹 채팅을 만들면 여러 위치에 있는 여러 사람을 초대하고 채팅할 수 있습니다.",
            descriptionTranslationChat: "개인 번역을 시작하면 단어와 구문을 빠르게 번역하여 당신만을 위해 번역합니다.",
            descriptionSharedScreenChat: "같은 장치에서 다른 사람과 다국어 채팅을 할 수 있습니다.",
            descriptionQuickConnectChat: "계정이 없는 비회원과 1시간 동안 채팅을 시작합니다.",
            changeButton: "변경",
            chatNameLabel: "채팅 이름",
            guestNameLabel: "게스트 이름",
            myLanguageLabel: "내 언어",
            translateToLanguageLabel: "언어로 번역",
            createChatDescription: "채팅 세션을 만들려면 유형을 선택하고 옵션을 선택하십시오.",
            cancelButton: "취소",
            createButton: "만들기",
        },
        "ja-JP": {
            title: "チャットを作成",
            headline: "始めましょう！",
            linkContentHead: "他の人とつながります！",
            linkContentDescription: "他の人を招待するには、このQRコードをスキャンするか、以下のリンクを共有してください。",
            linkContentButton: "チャット画面に進む",
            selectChatTypeLabel: "チャットの種類を選択",
            selectChatTypeGroupChat: "新しいグループチャット",
            selectChatTypeTranslationChat: "新しい個人翻訳",
            selectChatTypeSharedScreenChat: "新しい共有画面チャット",
            selectChatTypeQuickConnectChat: "新しいクイックコネクトチャット",
            descriptionGroupChat: "グループ チャットを作成すると、さまざまな場所から複数の人を招待してチャットできます。",
            descriptionTranslationChat: "あなただけのために単語やフレーズを素早く翻訳する個人翻訳を開始します。",
            descriptionSharedScreenChat: "同じデバイス上で他の人と多言語チャットを行います。",
            descriptionQuickConnectChat: "アカウントを持っていない非メンバーと1時間のチャットを開始します。",
            changeButton: "変更",
            chatNameLabel: "チャット名",
            guestNameLabel: "ゲスト名",
            myLanguageLabel: "私の言語",
            translateToLanguageLabel: "言語に翻訳",
            createChatDescription: "チャットセッションを作成するには、タイプを選択し、オプションを選択してください。",
            cancelButton: "キャンセル",
            createButton: "作成",
        },
        "ar-IQ": {
            title: "إنشاء محادثة",
            headline: "لنبدأ!",
            linkContentHead: "الاتصال بالآخرين!",
            linkContentDescription: "ادعو الآخرين بمسح هذا الرمز الاستجابة السريعة أو مشاركة الرابط أدناه.",
            linkContentButton: "المتابعة إلى شاشة المحادثة",
            selectChatTypeLabel: "اختر نوع المحادثة",
            selectChatTypeGroupChat: "محادثة جماعية جديدة",
            selectChatTypeTranslationChat: "مترجم شخصي جديد",
            selectChatTypeSharedScreenChat: "محادثة شاشة مشتركة جديدة",
            selectChatTypeQuickConnectChat: "محادثة اتصال سريع جديدة",
            descriptionGroupChat: "يتيح لك إنشاء دردشة جماعية دعوة عدة أشخاص والدردشة معهم من العديد من المواقع.",
            descriptionTranslationChat: "بدء مترجم شخصي يترجم الكلمات والعبارات بسرعة فقط لك.",
            descriptionSharedScreenChat: "إجراء دردشة متعددة اللغات مع شخص آخر على نفس الجهاز.",
            descriptionQuickConnectChat: "بدء محادثة لمدة ساعة مع شخص غير عضو ليس لديه حساب خاص به.",
            changeButton: "تغيير",
            chatNameLabel: "اسم المحادثة",
            guestNameLabel: "اسم الضيف",
            myLanguageLabel: "لغتي",
            translateToLanguageLabel: "ترجمة إلى اللغة",
            createChatDescription: "لإنشاء جلسة محادثة، حدد النوع واختر خياراتك.",
            cancelButton: "إلغاء",
            createButton: "إنشاء",
        },
        "fil-PH": {
            title: "Lumikha ng Chat",
            headline: "Simulan natin!",
            linkContentHead: "Kumonekta sa iba!",
            linkContentDescription: "Mag-imbita ng iba sa pamamagitan ng pag-scan sa QR code na ito o pagbabahagi ng link sa ibaba.",
            linkContentButton: "Magpatuloy sa Screen ng Chat",
            selectChatTypeLabel: "Pumili ng Uri ng Chat",
            selectChatTypeGroupChat: "Bagong Group Chat",
            selectChatTypeTranslationChat: "Bagong Personal na Pagsasalin",
            selectChatTypeSharedScreenChat: "Bagong Chat ng Nakabahaging Screen",
            selectChatTypeQuickConnectChat: "Bagong Quick Connect Chat",
            descriptionGroupChat: "Nagbibigay-daan sa iyo ang Lumikha ng panggrupong chat na mag-imbita at makipag-chat sa maraming tao mula sa maraming lokasyon.",
            descriptionTranslationChat: "Magsimula ng personal na tagasalin na mabilis na nagtutulak ng mga salita at parirala para sa iyo lamang.",
            descriptionSharedScreenChat: "Magkaroon ng isang multi-linggwal na chat sa ibang tao sa parehong aparato.",
            descriptionQuickConnectChat: "Magsimula ng isang oras na chat sa isang hindi miyembro na walang sariling account.",
            changeButton: "Baguhin",
            chatNameLabel: "Pangalan ng Chat",
            guestNameLabel: "Pangalan ng Guest",
            myLanguageLabel: "Aking Wika",
            translateToLanguageLabel: "Isalin sa Wika",
            createChatDescription: "Upang lumikha ng isang sesyon ng chat, pumili ng uri at pumili ng iyong mga opsyon.",
            cancelButton: "Kanselahin",
            createButton: "Lumikha",
        },
        "ne-NP": {
            title: "च्याट सिर्जना गर्नुहोस्",
            headline: "आइए सुरु गरौं!",
            linkContentHead: "अरुसँग जडान गर्नुहोस्!",
            linkContentDescription: "अरुहरूलाई यस QR कोड स्क्यान गराउने वा तलको लिंक साझा गर्दै अरुहरूलाई आमन्त्रित गर्नुहोस्।",
            linkContentButton: "च्याट स्क्रिनमा जारी राख्नुहोस्",
            selectChatTypeLabel: "च्याट प्रकार छनौट गर्नुहोस्",
            selectChatTypeGroupChat: "नयाँ समूह च्याट",
            selectChatTypeTranslationChat: "नयाँ व्यक्तिगत अनुवाद",
            selectChatTypeSharedScreenChat: "नयाँ साझा स्क्रिन च्याट",
            selectChatTypeQuickConnectChat: "नयाँ त्वरित कनेक्ट च्याट",
            descriptionGroupChat: "एउटा समूह च्याट सिर्जना गर्नुहोस् तपाईंलाई आमन्त्रित गर्न र धेरै स्थानहरूबाट धेरै व्यक्तिहरूसँग कुराकानी गर्न अनुमति दिन्छ।",
            descriptionTranslationChat: "तपाईंको लागि शब्द र वाक्यहरू त्वरित अनुवाद गर्ने व्यक्तिगत अनुवादक सुरु गर्नुहोस्।",
            descriptionSharedScreenChat: "एउटा अन्य व्यक्ति संग एउटा बहु-भाषी च्याट गर्नुहोस् जस्तै एउटा समान उपकरणमा।",
            descriptionQuickConnectChat: "आफ्नो खाता छैन भनेर एक घण्टा च्याट सत्र सुरु गर्नुहोस् जसले आफ्नो खाता छैन भनेर एक गैर सदस्यसँगै एक घण्टा च्याट सत्र सुरु गर्दछ।",
            changeButton: "परिवर्तन गर्नुहोस्",
            chatNameLabel: "च्याट नाम",
            guestNameLabel: "अतिथि नाम",
            myLanguageLabel: "मेरो भाषा",
            translateToLanguageLabel: "भाषामा अनुवाद गर्नुहोस्",
            createChatDescription: "च्याट सत्र सिर्जना गर्नका लागि, प्रकार छनौट गर्नुहोस् र तपाईंको विकल्पहरू छनौट गर्नुहोस्।",
            cancelButton: "रद्द गर्नुहोस्",
            createButton: "सिर्जना गर्नुहोस्",
        },
        "ar-SA": {
            title: "إنشاء محادثة",
            headline: "لنبدأ!",
            linkContentHead: "الاتصال بالآخرين!",
            linkContentDescription: "ادعو الآخرين بمسح هذا الرمز الاستجابة السريعة أو مشاركة الرابط أدناه.",
            linkContentButton: "المتابعة إلى شاشة المحادثة",
            selectChatTypeLabel: "اختر نوع المحادثة",
            selectChatTypeGroupChat: "محادثة جماعية جديدة",
            selectChatTypeTranslationChat: "مترجم شخصي جديد",
            selectChatTypeSharedScreenChat: "محادثة شاشة مشتركة جديدة",
            selectChatTypeQuickConnectChat: "محادثة اتصال سريع جديدة",
            descriptionGroupChat: "يتيح لك إنشاء دردشة جماعية دعوة عدة أشخاص والدردشة معهم من العديد من المواقع.",
            descriptionTranslationChat: "بدء مترجم شخصي يترجم الكلمات والعبارات بسرعة فقط لك.",
            descriptionSharedScreenChat: "إجراء دردشة متعددة اللغات مع شخص آخر على نفس الجهاز.",
            descriptionQuickConnectChat: "بدء محادثة لمدة ساعة مع شخص غير عضو ليس لديه حساب خاص به.",
            changeButton: "تغيير",
            chatNameLabel: "اسم المحادثة",
            guestNameLabel: "اسم الضيف",
            myLanguageLabel: "لغتي",
            translateToLanguageLabel: "ترجمة إلى اللغة",
            createChatDescription: "لإنشاء جلسة محادثة، حدد النوع واختر خياراتك.",
            cancelButton: "إلغاء",
            createButton: "انضم",
        },
        "am-ET": {
            title: "ቻት አድራሻ",
            headline: "እንኳን ድምፅ!",
            linkContentHead: "ሌሎችን በጣም ይጠናቀቃል!",
            linkContentDescription: "ሌሎችን እንዲህ በማስገባት ይጠቀሙ።",
            linkContentButton: "ቻት አድራሻ ይቅር",
            selectChatTypeLabel: "ቻት አይነት ይምረጡ",
            selectChatTypeGroupChat: "አዲስ ቡድን ቻት",
            selectChatTypeTranslationChat: "አዲስ ግርማ ቻት",
            selectChatTypeSharedScreenChat: "አዲስ አቅራቢያ ስክሪን ቻት",
            selectChatTypeQuickConnectChat: "አዲስ ትልቅ ተጠቃሚ ቻት",
            descriptionGroupChat: "ከተለያዩ ቦታዎች ያሉ ሰዎችን አጋርነው እና በተለያዩ ቦታዎች አንዳንድ ሰዎችን አጋርነው።",
            descriptionTranslationChat: "ከተለያዩ ቦታዎች ያሉ ሰዎችን አጋርነው እና በተለያዩ ቦታዎች አንዳንድ ሰዎችን አጋርነው።",
            descriptionSharedScreenChat: "ከተለያዩ ቦታዎች ያሉ ሰዎችን አጋርነው እና በተለያዩ ቦታዎች አንዳንድ ሰዎችን አጋርነው።",
            descriptionQuickConnectChat: "ከተለያዩ ቦታዎች ያሉ ሰዎችን አጋርነው እና በተለያዩ ቦታዎች አንዳንድ ሰዎችን አጋርነው።",
            changeButton: "ቀይር",
            chatNameLabel: "ቻት ስም",
            guestNameLabel: "አባል ስም",
            myLanguageLabel: "እኔን ቋንቋ",
            translateToLanguageLabel: "በቋንቋ ትርጉም",
            createChatDescription: "ቻት ስምን እና አንዳንድ አማራጮችን ለማስገባት አስተካክል ይምረጡ።",
            cancelButton: "ሰርዝ",
            createButton: "አድራሻ",

        }
    },

    createQuickConnectPanel: {
        "en-US": {
            title: "Create Quick Connect",
            selectChatTypeLabel: "Select Chat Type",
            selectChatTypeGroupChat: "New Group Chat",
            selectChatTypeTranslationChat: "New Personal Translator",
            chatNameLabel: "Chat Name",
            myLanguageLabel: "My Language",
            translateToLanguageLabel: "Translate to Language",
            createChatDescription: "To creat a 1 hour quick connect session, provide a name and your preferred language. Upon submit, you will receive a QR code for a peer.",
            cancelButton: "Cancel",
            createButton: "Create",
        },
        "en-GB": {
            title: "Create Quick Connect",
            selectChatTypeLabel: "Select Chat Type",
            selectChatTypeGroupChat: "New Group Chat",
            selectChatTypeTranslationChat: "New Personal Translator",
            chatNameLabel: "Chat Name",
            myLanguageLabel: "My Language",
            translateToLanguageLabel: "Translate to Language",
            createChatDescription: "To creat a 1 hour quick connect session, provide a name and your preferred language. Upon submit, you will receive a QR code for a peer.",
            cancelButton: "Cancel",
            createButton: "Create",
        },
        "es-ES": {
            title: "Crea un chat",
            selectChatTypeLabel: "Selecciona el Tipo de Chat",
            selectChatTypeGroupChat: "Nuevo chat grupal",
            selectChatTypeTranslationChat: "Nuevo Traductor Personal",
            chatNameLabel: "Nombre del chat",
            myLanguageLabel: "Mi idioma",
            translateToLanguageLabel: "Traducir al idioma",
            createChatDescription: "Para crear una sesión de chat, selecciona el tipo y elige tus opciones.",
            cancelButton: "Cancelar",
            createButton: "Crear",

        },
        "es-MX": {
            title: "Crea un chat",
            selectChatTypeLabel: "Selecciona el Tipo de Chat",
            selectChatTypeGroupChat: "Nuevo grupo de chat",
            selectChatTypeTranslationChat: "Traductor personal nuevo",
            chatNameLabel: "Nombre del chat",
            myLanguageLabel: "Mi idioma",
            translateToLanguageLabel: "Traducir al idioma",
            createChatDescription: "Para crear una sesión de chat, selecciona el tipo y elige tus opciones.",
            cancelButton: "Cancelar",
            createButton: "Crear",
        },
        "fr-FR": {
            title: "Créez un chat",
            selectChatTypeLabel: "Sélectionnez le type de chat",
            selectChatTypeGroupChat: "Nouveau chat de groupe",
            selectChatTypeTranslationChat: "Nouveau traducteur personnel",
            chatNameLabel: "Nom du chat",
            myLanguageLabel: "Ma langue",
            translateToLanguageLabel: "Traduire en langue",
            createChatDescription: "Pour créer une session de chat, sélectionnez le type et choisissez vos options.",
            cancelButton: "Annule",
            createButton: "Créer",

        },
        "de-DE": {
            title: "Erstelle einen Chat",
            selectChatTypeLabel: "Wähle Chat-Typ",
            selectChatTypeGroupChat: "Neuer Gruppenchat",
            selectChatTypeTranslationChat: "Neuer persönlicher Übersetzer",
            chatNameLabel: "Chat-Name",
            myLanguageLabel: "Meine Sprache",
            translateToLanguageLabel: "Übersetzen in Sprache",
            createChatDescription: "Um einen Chat zu starten, wähle den Typ und deine Optionen aus.",
            cancelButton: "Stornieren",
            createButton: "Erstellen",

        },
        "it-IT": {
            title: "Crea una chat",
            selectChatTypeLabel: "Seleziona il tipo di chat",
            selectChatTypeGroupChat: "Nuova chat di gruppo",
            selectChatTypeTranslationChat: "Nuovo traduttore personale",
            chatNameLabel: "Nome della chat",
            myLanguageLabel: "La mia lingua",
            translateToLanguageLabel: "Traduci in lingua",
            createChatDescription: "Per creare una chat, seleziona il tipo e scegli le tue opzioni.",
            cancelButton: "Annulla",
            createButton: "Crea",

        },
        "hi-IN": {
            title: "चैट बनाएँ!",
            selectChatTypeLabel: "चैट प्रकार चुनें",
            selectChatTypeGroupChat: "नया समूह चैट",
            selectChatTypeTranslationChat: "नया व्यक्तिगत अनुवादक",
            chatNameLabel: "चैट नाम",
            myLanguageLabel: "मेरी भाषा",
            translateToLanguageLabel: "भाषा में अनुवाद करें",
            createChatDescription: "चैट सेशन बनाने के लिए, टाइप चुनें और अपने विकल्प चुनें।",
            cancelButton: "रद्द करें",
            createButton: "बनाएँ",

        },
        "cmn-CN": {
            title: "创建聊天",
            selectChatTypeLabel: "选择聊天类型",
            selectChatTypeGroupChat: "新群聊",
            selectChatTypeTranslationChat: "新个人翻译",
            chatNameLabel: "聊天名称",
            myLanguageLabel: "我的语言",
            translateToLanguageLabel: "翻译成语言",
            createChatDescription: "要创建聊天会话，请选择类型并选择您的选项。",
            cancelButton: "取消",
            createButton: "创建",
        },
        "so-SO": {
            title: "Samee Sheeko",
            selectChatTypeLabel: "Xulo Nooca Sheeko",
            selectChatTypeGroupChat: "Sheeko Gurmad ah",
            selectChatTypeTranslationChat: "Turjuma Shaqsiga ah",
            chatNameLabel: "Magaca Sheekada",
            myLanguageLabel: "Luqadaaga",
            translateToLanguageLabel: "Turjuma Luqad",
            createChatDescription: "Si aad sameysid qalabka sheeko, dooro nooca iyo dooro xulashadaada.",
            cancelButton: "Ka soo bax",
            createButton: "Samee",
        },
        "pt-PT": {
            title: "Crie um chat",
            selectChatTypeLabel: "Selecione o tipo de chat",
            selectChatTypeGroupChat: "Nova conversa em grupo",
            selectChatTypeTranslationChat: "Novo tradutor pessoal",
            chatNameLabel: "Nome do chat",
            myLanguageLabel: "Meu idioma",
            translateToLanguageLabel: "Traduzir para o idioma",
            createChatDescription: "Para criar uma sessão de chat, selecione o tipo e escolha suas opções.",
            cancelButton: "Cancelar",
            createButton: "Criar",
        },
        "pt-BR": {
            title: "Crie um chat",
            selectChatTypeLabel: "Selecione o tipo de chat",
            selectChatTypeGroupChat: "Nova conversa em grupo",
            selectChatTypeTranslationChat: "Novo tradutor pessoal",
            chatNameLabel: "Nome do chat",
            myLanguageLabel: "Meu idioma",
            translateToLanguageLabel: "Traduzir para o idioma",
            createChatDescription: "Para criar uma sessão de chat, selecione o tipo e escolha suas opções.",
            cancelButton: "Cancelar",
            createButton: "Criar",
        },
        "id-ID": {
            title: "Buat Obrolan",
            selectChatTypeLabel: "Pilih Jenis Obrolan",
            selectChatTypeGroupChat: "Obrolan Grup Baru",
            selectChatTypeTranslationChat: "Penerjemah Pribadi Baru",
            chatNameLabel: "Nama Obrolan",
            myLanguageLabel: "Bahasa Saya",
            translateToLanguageLabel: "Terjemahkan ke Bahasa",
            createChatDescription: "Untuk membuat sesi obrolan, pilih jenis dan pilih opsi Anda.",
            cancelButton: "Batal",
            createButton: "Buat",
        },
        "vi-VN": {
            title: "Tạo Trò Chuyện",
            selectChatTypeLabel: "Chọn Loại Trò Chuyện",
            selectChatTypeGroupChat: "Trò Chuyện Nhóm Mới",
            selectChatTypeTranslationChat: "Biên Dịch Cá Nhân Mới",
            chatNameLabel: "Tên Trò Chuyện",
            myLanguageLabel: "Ngôn Ngữ Của Tôi",
            translateToLanguageLabel: "Dịch Sang Ngôn Ngữ",
            createChatDescription: "Để tạo một phiên trò chuyện, chọn loại và chọn tùy chọn của bạn.",
            cancelButton: "Hủy",
            createButton: "Tạo",
        },
        "ko-KR": {
            title: "채팅 만들기",
            selectChatTypeLabel: "채팅 유형 선택",
            selectChatTypeGroupChat: "새 그룹 채팅",
            selectChatTypeTranslationChat: "새 개인 번역",
            chatNameLabel: "채팅 이름",
            myLanguageLabel: "내 언어",
            translateToLanguageLabel: "언어로 번역",
            createChatDescription: "채팅 세션을 만들려면 유형을 선택하고 옵션을 선택하십시오.",
            cancelButton: "취소",
            createButton: "만들기",
        },
        "ja-JP": {
            title: "チャットを作成",
            selectChatTypeLabel: "チャットの種類を選択",
            selectChatTypeGroupChat: "新しいグループチャット",
            selectChatTypeTranslationChat: "新しい個人翻訳",
            chatNameLabel: "チャット名",
            myLanguageLabel: "私の言語",
            translateToLanguageLabel: "言語に翻訳",
            createChatDescription: "チャットセッションを作成するには、タイプを選択し、オプションを選択してください。",
            cancelButton: "キャンセル",
            createButton: "作成",
        },
        "ar-IQ": {
            title: "إنشاء محادثة",
            selectChatTypeLabel: "اختر نوع المحادثة",
            selectChatTypeGroupChat: "محادثة جماعية جديدة",
            selectChatTypeTranslationChat: "مترجم شخصي جديد",
            chatNameLabel: "اسم المحادثة",
            myLanguageLabel: "لغتي",
            translateToLanguageLabel: "ترجمة إلى اللغة",
            createChatDescription: "لإنشاء جلسة محادثة، حدد النوع واختر خياراتك.",
            cancelButton: "إلغاء",
            createButton: "إنشاء",
        },
        "fil-PH": {
            title: "Lumikha ng Chat",
            selectChatTypeLabel: "Pumili ng Uri ng Chat",
            selectChatTypeGroupChat: "Bagong Group Chat",
            selectChatTypeTranslationChat: "Bagong Personal na Pagsasalin",
            chatNameLabel: "Pangalan ng Chat",
            myLanguageLabel: "Aking Wika",
            translateToLanguageLabel: "Isalin sa Wika",
            createChatDescription: "Upang lumikha ng isang sesyon ng chat, pumili ng uri at pumili ng iyong mga opsyon.",
            cancelButton: "Kanselahin",
            createButton: "Lumikha",
        },
        "ne-NP": {
            title: "च्याट सिर्जना गर्नुहोस्",
            selectChatTypeLabel: "च्याट प्रकार छनौट गर्नुहोस्",
            selectChatTypeGroupChat: "नयाँ समूह च्याट",
            selectChatTypeTranslationChat: "नयाँ व्यक्तिगत अनुवाद",
            chatNameLabel: "च्याट नाम",
            myLanguageLabel: "मेरो भाषा",
            translateToLanguageLabel: "भाषामा अनुवाद गर्नुहोस्",
            createChatDescription: "च्याट सत्र सिर्जना गर्नका लागि, प्रकार छनौट गर्नुहोस् र तपाईंको विकल्पहरू छनौट गर्नुहोस्।",
            cancelButton: "रद्द गर्नुहोस्",
            createButton: "सिर्जना गर्नुहोस्",
        },
        "ar-SA": {
            title: "إنشاء محادثة",
            selectChatTypeLabel: "اختر نوع المحادثة",
            selectChatTypeGroupChat: "محادثة جماعية جديدة",
            selectChatTypeTranslationChat: "مترجم شخصي جديد",
            chatNameLabel: "اسم المحادثة",
            myLanguageLabel: "لغتي",
            translateToLanguageLabel: "ترجمة إلى اللغة",
            createChatDescription: "لإنشاء جلسة محادثة، حدد النوع واختر خياراتك.",
            cancelButton: "إلغاء",
            createButton: "انضم",
        },
        "am-ET": {
            title: "ቻት አስተካክል",
            selectChatTypeLabel: "ቻት አምርር ይምረጡ",
            selectChatTypeGroupChat: "አዲስ ቡድን ቻት",
            selectChatTypeTranslationChat: "አዲስ ሕብረት ቻት",
            chatNameLabel: "ቻት ስም",
            myLanguageLabel: "እኔን ቋንቋ",
            translateToLanguageLabel: "በቋንቋ ትርጉም",
            createChatDescription: "ቻት ሴስን አስተካክል የሚችል ለማስተካከል ቡድን አዲስ ቻት ይምረጡ።",
            cancelButton: "ሰርዝ",
            createButton: "አስተካክል",
        }
    },

    joinChatPanel: {
        "en-US": {
            title: "Join a Chat",
            description: "To join a chat session, provide your invite code and select a language below.",
            sessionCodeLabel: "Session Code",
            myLanguageLabel: "My Language",
            cancelButton: "Cancel",
            createButton: "Join",
        },
        "en-GB": {
            title: "Join a Chat",
            description: "To join a chat session, provide your invite code and select a language below.",
            sessionCodeLabel: "Session Code",
            myLanguageLabel: "My Language",
            cancelButton: "Cancel",
            createButton: "Join",
        },
        "es-ES": {
            title: "Únete a un chat",
            description: "Para unirte a una sesión de chat, proporciona tu código de invitación y selecciona un idioma a continuación.",
            sessionCodeLabel: "Código de sesión",
            myLanguageLabel: "Mi idioma",
            cancelButton: "Cancelar",
            createButton: "Únete",

        },
        "es-MX": {
            title: "Únete a un chat",
            description: "Para unirte a una sesión de chat, proporciona tu código de invitación y selecciona un idioma a continuación.",
            sessionCodeLabel: "Código de sesión",
            myLanguageLabel: "Mi idioma",
            cancelButton: "Cancelar",
            createButton: "Únete",
        },
        "fr-FR": {
            title: "Rejoignez un Chat",
            description: "Pour rejoindre une session de chat, fournissez votre code d'invitation et sélectionnez une langue ci-dessous.",
            sessionCodeLabel: "Code de session",
            myLanguageLabel: "Ma langue",
            cancelButton: "Annule",
            createButton: "Rejoignez-nous",

        },
        "de-DE": {
            title: "Mach mit im Chat",
            description: "Um an einer Chat-Sitzung teilzunehmen, geben Sie Ihren Einladungscode ein und wählen Sie unten eine Sprache aus.",
            sessionCodeLabel: "Sitzungscode",
            myLanguageLabel: "Meine Sprache",
            cancelButton: "Stornieren",
            createButton: "Mach mit!",

        },
        "it-IT": {
            title: "Unisciti a una Chat",
            description: "Per partecipare a una sessione di chat, fornisci il tuo codice di invito e seleziona una lingua di seguito.",
            sessionCodeLabel: "Codice sessione",
            myLanguageLabel: "La mia lingua",
            cancelButton: "Annulla",
            createButton: "Unisciti",

        },
        "hi-IN": {
            title: "चैट में जुड़ो",
            description: "चैट सत्र में शामिल होने के लिए, अपना आमंत्रण कोड प्रदान करें और नीचे एक भाषा का चयन करें।",
            sessionCodeLabel: "सत्र कोड",
            myLanguageLabel: "मेरी भाषा",
            cancelButton: "रद्द करें",
            createButton: "शामिल हो",
        },
        "cmn-CN": {
            title: "加入聊天",
            description: "要加入聊天会话，请提供您的邀请码并在下面选择一种语言。",
            sessionCodeLabel: "会话代码",
            myLanguageLabel: "我的语言",
            cancelButton: "取消",
            createButton: "加入",
        },
        "so-SO": {
            title: "Ku biir Sheekeysi",
            description: "Si aad ku biirto kulanka sheeko, bixi koodhkaaga daawada iyo dooro luqadka hoose.",
            sessionCodeLabel: "Koodhka Kulanka",
            myLanguageLabel: "Luqadayda",
            cancelButton: "Ka soo bax",
            createButton: "Ku biir",
        },
        "pt-PT": {
            title: "Participe num bate-papo",
            description: "Para participar de uma sessão de chat, forneça seu código de convite e selecione um idioma abaixo.",
            sessionCodeLabel: "Código da sessão",
            myLanguageLabel: "Meu idioma",
            cancelButton: "Cancelar",
            createButton: "Participar",
        },
        "pt-BR": {
            title: "Participe de um Chat",
            description: "Para participar de uma sessão de chat, forneça seu código de convite e selecione um idioma abaixo.",
            sessionCodeLabel: "Código da sessão",
            myLanguageLabel: "Meu idioma",
            cancelButton: "Cancelar",
            createButton: "Participar",
        },
        "id-ID": {
            title: "Bergabunglah dengan Obrolan",
            description: "Untuk bergabung dengan sesi obrolan, berikan kode undangan Anda dan pilih bahasa di bawah ini.",
            sessionCodeLabel: "Kode Sesi",
            myLanguageLabel: "Bahasa Saya",
            cancelButton: "Batal",
            createButton: "Bergabung",
        },
        "vi-VN": {
            title: "Tham gia Trò chuyện",
            description: "Để tham gia phiên trò chuyện, cung cấp mã mời của bạn và chọn một ngôn ngữ dưới đây.",
            sessionCodeLabel: "Mã phiên",
            myLanguageLabel: "Ngôn ngữ của tôi",
            cancelButton: "Hủy",
            createButton: "Tham gia",
        },
        "ko-KR": {
            title: "채팅에 참여",
            description: "채팅 세션에 참여하려면 초대 코드를 제공하고 아래에서 언어를 선택하십시오.",
            sessionCodeLabel: "세션 코드",
            myLanguageLabel: "내 언어",
            cancelButton: "취소",
            createButton: "참여",
        },
        "ja-JP": {
            title: "チャットに参加",
            description: "チャットセッションに参加するには、招待コードを提供し、以下から言語を選択してください。",
            sessionCodeLabel: "セッションコード",
            myLanguageLabel: "私の言語",
            cancelButton: "キャンセル",
            createButton: "参加",
        },
        "ar-IQ": {
            title: "انضم إلى الدردشة",
            description: "للانضمام إلى جلسة الدردشة، قدم رمز الدعوة الخاص بك وحدد لغة أدناه.",
            sessionCodeLabel: "رمز الجلسة",
            myLanguageLabel: "لغتي",
            cancelButton: "إلغاء",
            createButton: "انضم",
        },
        "fil-PH": {
            title: "Sumali sa Chat",
            description: "Upang sumali sa sesyon ng chat, magbigay ng iyong code ng imbitasyon at pumili ng wika sa ibaba.",
            sessionCodeLabel: "Code ng sesyon",
            myLanguageLabel: "Aking Wika",
            cancelButton: "Kanselahin",
            createButton: "Sumali",
        },
        "ne-NP": {
            title: "च्याटमा सामेल हुनुहोस्",
            description: "च्याट सत्रमा सामेल हुनका लागि, आफ्नो आमंत्रण कोड प्रदान गर्नुहोस् र तल एउटा भाषा छान्नुहोस्।",
            sessionCodeLabel: "सत्र कोड",
            myLanguageLabel: "मेरो भाषा",
            cancelButton: "रद्द गर्नुहोस्",
            createButton: "सामेल हुनुहोस्",
        },
        "ar-SA": {
            title: "انضم إلى الدردشة",
            description: "للانضمام إلى جلسة الدردشة، قدم رمز الدعوة الخاص بك وحدد لغة أدناه.",
            sessionCodeLabel: "رمز الجلسة",
            myLanguageLabel: "لغتي",
            cancelButton: "إلغاء",
            createButton: "انضم",
        },
        "am-ET": {
            title: "በቻት ላይ ተመዝግቧል",
            description: "በቻት ሴሸን ላይ ተመዝግቧል፣ እባኮትን አስተያየት እና ከዚህ በታች ቋንቋ ይምረጡ።",
            sessionCodeLabel: "ሴሸን ኮድ",
            myLanguageLabel: "እኔን ቋንቋ",
            cancelButton: "ሰምተው",
            createButton: "ተመዝግቧል",

        }


    },
    updateChatLanguagePanel: {
        "en-US": {
            title: "Update Chat Language",
            description: "Change preferred language.",
            secondaryDescription: "Change secondary language.",
            languageSelectLabel: "My Language",
            secondaryLanguageSelectLabel: "Secondary Language",
            cancelButton: "Cancel",
            createButton: "Update",
        },
        "en-GB": {
            title: "Update Chat Language",
            description: "Change preferred language.",
            secondaryDescription: "Change secondary language.",
            languageSelectLabel: "My Language",
            secondaryLanguageSelectLabel: "Secondary Language",
            cancelButton: "Cancel",
            createButton: "Update",
        },
        "es-ES": {
            title: "Actualizar idioma del chat",
            description: "Selecciona tu idioma preferido.",
            secondaryDescription: "Cambiar idioma secundario.",
            languageSelectLabel: "Mi idioma",
            secondaryLanguageSelectLabel: "Idioma secundario",
            cancelButton: "Cancelar",
            createButton: "Actualiza",

        },
        "es-MX": {
            title: "Actualizar idioma del chat",
            description: "Selecciona tu idioma preferido.",
            secondaryDescription: "Cambiar idioma secundario.",
            languageSelectLabel: "Mi idioma",
            secondaryLanguageSelectLabel: "Idioma secundario",
            cancelButton: "Cancelar",
            createButton: "Actualiza",
        },
        "fr-FR": {
            title: "Mettre à jour le langage de la conversation",
            description: "Sélectionnez votre langue préférée.",
            secondaryDescription: "Changer de langue secondaire.",
            languageSelectLabel: "Ma langue",
            secondaryLanguageSelectLabel: "Langue secondaire",
            cancelButton: "Annule",
            createButton: "Mets à jour",

        },
        "de-DE": {
            title: "Chat-Sprache aktualisieren",
            description: "Wählen Sie Ihre bevorzugte Sprache aus.",
            secondaryDescription: "Sekundäre Sprache ändern.",
            languageSelectLabel: "Meine Sprache",
            secondaryLanguageSelectLabel: "Sekundäre Sprache",
            cancelButton: "Stornieren",
            createButton: "Update",

        },
        "it-IT": {
            title: "Aggiorna la lingua della chat",
            description: "Scegli la tua lingua preferita.",
            secondaryDescription: "Cambia la lingua secondaria.",
            languageSelectLabel: "La mia lingua",
            secondaryLanguageSelectLabel: "Lingua secondaria",
            cancelButton: "Annulla",
            createButton: "Aggiorna",

        },
        "hi-IN": {
            title: "चैट लैंग्वेज अपडेट करो",
            description: "अपनी पसंद की भाषा चुनें",
            secondaryDescription: "द्वितीय भाषा बदलें",
            languageSelectLabel: "मेरी भाषा",
            secondaryLanguageSelectLabel: "द्वितीय भाषा",
            cancelButton: "रद्द करें",
            createButton: "अपडेट करो",
        },
        "cmn-CN": {
            title: "更新聊天语言",
            description: "选择您的首选语言。",
            secondaryDescription: "更改次要语言。",
            languageSelectLabel: "我的语言",
            secondaryLanguageSelectLabel: "次要语言",
            cancelButton: "取消",
            createButton: "更新",
        },
        "so-SO": {
            title: "Cusbooneysii Luqadda Wada-sheekeysiga",
            description: "Doorso luqaddaada isticmaalka ah.",
            secondaryDescription: "Beddel luqadda labaad.",
            languageSelectLabel: "Luqaddayda",
            secondaryLanguageSelectLabel: "Luqadda labaad",
            cancelButton: "Ka soo bax",
            createButton: "Cusbooneysii",
        },
        "pt-PT": {
            title: "Atualizar idioma do chat",
            description: "Selecione seu idioma preferido.",
            secondaryDescription: "Alterar idioma secundário.",
            languageSelectLabel: "Meu idioma",
            secondaryLanguageSelectLabel: "Idioma secundário",
            cancelButton: "Cancelar",
            createButton: "Atualizar",
        },
        "pt-BR": {
            title: "Atualizar idioma do chat",
            description: "Selecione seu idioma preferido.",
            secondaryDescription: "Alterar idioma secundário.",
            languageSelectLabel: "Meu idioma",
            secondaryLanguageSelectLabel: "Idioma secundário",
            cancelButton: "Cancelar",
            createButton: "Atualizar",
        },
        "id-ID": {
            title: "Perbarui Bahasa Obrolan",
            description: "Pilih bahasa pilihan Anda.",
            secondaryDescription: "Ubah bahasa sekunder.",
            languageSelectLabel: "Bahasa Saya",
            secondaryLanguageSelectLabel: "Bahasa Sekunder",
            cancelButton: "Batal",
            createButton: "Perbarui",
        },
        "vi-VN": {
            title: "Cập nhật Ngôn ngữ Trò chuyện",
            description: "Chọn ngôn ngữ ưa thích của bạn.",
            secondaryDescription: "Thay đổi ngôn ngữ phụ.",
            languageSelectLabel: "Ngôn ngữ của tôi",
            secondaryLanguageSelectLabel: "Ngôn ngữ phụ",
            cancelButton: "Hủy",
            createButton: "Cập nhật",
        },
        "ko-KR": {
            title: "채팅 언어 업데이트",
            description: "선호하는 언어를 선택하십시오.",
            secondaryDescription: "보조 언어 변경.",
            languageSelectLabel: "내 언어",
            secondaryLanguageSelectLabel: "보조 언어",
            cancelButton: "취소",
            createButton: "업데이트",
        },
        "ja-JP": {
            title: "チャット言語を更新",
            description: "お好みの言語を選択してください。",
            secondaryDescription: "セカンダリ言語を変更します。",
            languageSelectLabel: "私の言語",
            secondaryLanguageSelectLabel: "セカンダリ言語",
            cancelButton: "キャンセル",
            createButton: "更新",
        },
        "ar-IQ": {
            title: "تحديث لغة الدردشة",
            description: "حدد لغتك المفضلة.",
            secondaryDescription: "تغيير اللغة الثانوية.",
            languageSelectLabel: "لغتي",
            secondaryLanguageSelectLabel: "اللغة الثانوية",
            cancelButton: "إلغاء",
            createButton: "تحديث",
        },
        "fil-PH": {
            title: "I-update ang Wika ng Chat",
            description: "Pumili ng iyong nais na wika.",
            secondaryDescription: "Baguhin ang pangalawang wika.",
            languageSelectLabel: "Aking Wika",
            secondaryLanguageSelectLabel: "Pangalawang Wika",
            cancelButton: "Kanselahin",
            createButton: "I-update",
        },
        "ne-NP": {
            title: "च्याट भाषा अपडेट गर्नुहोस्",
            description: "तपाईंको पसंदीदा भाषा छान्नुहोस्।",
            secondaryDescription: "सेकेन्डरी भाषा परिवर्तन गर्नुहोस्।",
            languageSelectLabel: "मेरो भाषा",
            secondaryLanguageSelectLabel: "सेकेन्डरी भाषा",
            cancelButton: "रद्द गर्नुहोस्",
            createButton: "अपडेट गर्नुहोस्",
        },
        "ar-SA": {
            title: "تحديث لغة الدردشة",
            description: "حدد لغتك المفضلة.",
            secondaryDescription: "تغيير اللغة الثانوية.",
            languageSelectLabel: "لغتي",
            secondaryLanguageSelectLabel: "اللغة الثانوية",
            cancelButton: "إلغاء",
            createButton: "تحديث",
        },
        "am-ET": {
            title: "የቻት ቋንቋ አዘምን",
            description: "እርስዎ የሚያስፈልገው ቋንቋ ምረጥ።",
            secondaryDescription: "ሁለቱን ቋንቋ ቀይር።",
            languageSelectLabel: "የእኔ ቋንቋ",
            secondaryLanguageSelectLabel: "ሁለቱን ቋንቋ",
            cancelButton: "ሰርዝ",
            createButton: "አዘምን",

        }
    },

    micListeningOptionsPanel: {
        "en-US": {
            title: "Listening Options",
            subtitle: "Select your preferred microphone setting.",
            micTriggered: "The microphone is button-triggered and closes after you speak.",
            micContinuous: "Microphone remains on continuously after activated.",
            cancelButton: "Cancel",
            saveButton: "Save",
        },
        "en-GB": {
            title: "Listening Options",
            subtitle: "Select your preferred microphone setting.",
            micTriggered: "The microphone is button-triggered and closes after you speak.",
            micContinuous: "Microphone remains on continuously after activated.",
            cancelButton: "Cancel",
            saveButton: "Save",
        },
        "es-ES": {
            title: "Opciones de escucha",
            subtitle: "Selecciona tu configuración de micrófono preferida.",
            micTriggered: "El micrófono se activa con un botón y se cierra después de que hablas.",
            micContinuous: "El micrófono permanece encendido continuamente después de activarse.",
            cancelButton: "Cancelar",
            saveButton: "Guardar",

        },
        "es-MX": {
            title: "Opciones de escucha",
            subtitle: "Selecciona tu configuración de micrófono preferida.",
            micTriggered: "El micrófono se activa con un botón y se cierra después de que hablas.",
            micContinuous: "El micrófono permanece encendido continuamente después de activarse.",
            cancelButton: "Cancelar",
            saveButton: "Guardar",
        },
        "fr-FR": {
            title: "Options d'écoute",
            subtitle: "Sélectionnez votre paramètre de microphone préféré.",
            micTriggered: "Le micro reste allumé en permanence après activation.",
            micContinuous: "Le micro se déclenche par bouton et se coupe après que tu parles.",
            cancelButton: "Annule",
            saveButton: "Commence",

        },
        "de-DE": {
            title: "Hör-Optionen",
            subtitle: "Wählen Sie Ihre bevorzugte Mikrofoneinstellung.",
            micTriggered: "Das Mikrofon wird durch einen Knopf aktiviert und schaltet sich nach dem Sprechen aus.",
            micContinuous: "Das Mikrofon bleibt nach der Aktivierung dauerhaft eingeschaltet.",
            cancelButton: "Stornieren",
            saveButton: "Speichern",

        },
        "it-IT": {
            title: "Unisciti a una Chat",
            subtitle: "Seleziona la tua impostazione del microfono preferita.",
            micTriggered: "Il microfono si attiva con un pulsante e si chiude dopo che hai parlato.",
            micContinuous: "Il microfono rimane acceso continuamente dopo l'attivazione.",
            cancelButton: "Annulla",
            saveButton: "Salva",

        },
        "hi-IN": {
            title: "सुनने के विकल्प",
            subtitle: "अपनी पसंदीदा माइक्रोफोन सेटिंग का चयन करें।",
            micTriggered: "माइक्रोफ़ोन बटन से चालू होता है और आप बोलना बंद करने के बाद बंद हो जाता है।",
            micContinuous: "माइक्रोफ़ोन चालू होने के बाद लगातार चालू रहता है।",
            cancelButton: "रद्द करें",
            saveButton: "बचाओ",
        },
        "cmn-CN": {
            title: "收听选项",
            subtitle: "选择您的首选麦克风设置。",
            micTriggered: "麦克风是按钮触发的，说话后关闭。",
            micContinuous: "麦克风在激活后保持连续开启。",
            cancelButton: "取消",
            saveButton: "保存",
        },
        "so-SO": {
            title: "Ikhtiyaarada Dhageysiga",
            subtitle: "Doorso qalabkaaga mic ah ee aad ula socotid.",
            micTriggered: "Micruufonka waa in uu dhageysto badhanka iyo in uu xidho markaad hadal.",
            micContinuous: "Micruufonka wuxuu ka soo baxayaa ka dib markaad ugu dambeyso.",
            cancelButton: "Ka soo bax",
            saveButton: "Keyd",
        },
        "pt-PT": {
            title: "Opções de escuta",
            subtitle: "Selecione a sua definição de microfone preferida.",
            micTriggered: "O microfone é acionado por botão e fecha após falar.",
            micContinuous: "O microfone permanece ligado continuamente após ativado.",
            cancelButton: "Cancelar",
            saveButton: "Salvar",
        },
        "pt-BR": {
            title: "Opções de escuta",
            subtitle: "Selecione a sua definição de microfone preferida.",
            micTriggered: "O microfone é acionado por botão e fecha após falar.",
            micContinuous: "O microfone permanece ligado continuamente após ativado.",
            cancelButton: "Cancelar",
            saveButton: "Salvar",
        },
        "id-ID": {
            title: "Opsi Mendengarkan",
            subtitle: "Pilih pengaturan mikrofon yang Anda sukai.",
            micTriggered: "Mikrofon diaktifkan dengan tombol dan ditutup setelah Anda berbicara.",
            micContinuous: "Mikrofon tetap menyala terus setelah diaktifkan.",
            cancelButton: "Batal",
            saveButton: "Simpan",
        },
        "vi-VN": {
            title: "Tùy chọn Nghe",
            subtitle: "Chọn cài đặt micro ưa thích của bạn.",
            micTriggered: "Mic được kích hoạt bằng nút và đóng sau khi bạn nói.",
            micContinuous: "Mic luôn bật liên tục sau khi được kích hoạt.",
            cancelButton: "Hủy",
            saveButton: "Lưu",
        },
        "ko-KR": {
            title: "듣기 옵션",
            subtitle: "선호하는 마이크 설정을 선택하세요.",
            micTriggered: "마이크는 버튼으로 활성화되며 말한 후에 꺼집니다.",
            micContinuous: "마이크는 활성화된 후 계속 켜져 있습니다.",
            cancelButton: "취소",
            saveButton: "저장",
        },
        "ja-JP": {
            title: "リスニングオプション",
            subtitle: "お好みのマイク設定を選択してください。",
            micTriggered: "マイクはボタンでアクティブ化され、話した後にオフになります。",
            micContinuous: "マイクはアクティブ化された後、常にオンのままです。",
            cancelButton: "キャンセル",
            saveButton: "保存",
        },
        "ar-IQ": {
            title: "خيارات الاستماع",
            subtitle: "حدد إعداد الميكروفون المفضل لديك.",
            micTriggered: "يتم تنشيط الميكروفون بواسطة الزر ويتم إغلاقه بعد التحدث.",
            micContinuous: "يبقى الميكروفون مفتوحًا بشكل مستمر بعد التنشيط.",
            cancelButton: "إلغاء",
            saveButton: "حفظ",
        },
        "fil-PH": {
            title: "Mga Pagpipilian sa Pakikinig",
            subtitle: "Pumili ng iyong paboritong setting ng mikropono.",
            micTriggered: "Ang mikropono ay pinapatakbo ng button at isasara pagkatapos magsalita.",
            micContinuous: "Ang mikropono ay patuloy na bukas pagkatapos mapatakbo.",
            cancelButton: "Kanselahin",
            saveButton: "I-save",
        },
        "ne-NP": {
            title: "सुन्ने विकल्पहरू",
            subtitle: "तपाईंको पसंदीदा माइक्रोफोन सेटिङ छान्नुहोस्।",
            micTriggered: "माइक्रोफोनले बटनद्वारा सक्रिय गरिन्छ र तपाईंले बोलेपछि बन्द गरिन्छ।",
            micContinuous: "माइक्रोफोनले सक्रिय भएपछि सँधै चालु राखिन्छ।",
            cancelButton: "रद्द गर्नुहोस्",
            saveButton: "सुरक्षित गर्नुहोस्",
        },
        "ar-SA": {
            title: "خيارات الاستماع",
            subtitle: "حدد إعداد الميكروفون المفضل لديك.",
            micTriggered: "يتم تنشيط الميكروفون بواسطة الزر ويتم إغلاقه بعد التحدث.",
            micContinuous: "يبقى الميكروفون مفتوحًا بشكل مستمر بعد التنشيط.",
            cancelButton: "إلغاء",
            saveButton: "حفظ",
        },
        "am-ET": {
            title: "የማወቅ አማራጮች",
            subtitle: "እናደርሳለን እስከዚህ እናውቃለን የሚችሉ ሚኒስትዎችን ይምረጡ።",
            micTriggered: "ሚኒስትን በአዝራር እናድርግ፣ እና በአንድ ቀን የሚያደርግ ነው።",
            micContinuous: "ሚኒስት በአዝራር እናድርግ፣ በተጨማሪ እናውቃለን።",
            cancelButton: "ሰርዝ",
            saveButton: "አስቀምጥ",

        }
    },
    uploadAvatarPanel: {
        "en-US": {
            title: "Upload Avatar",
            uploadButtonText: "Upload Avatar Image",
            cancelButtonText: "Cancel",
            saveButtonText: "Save",
        },
        "en-GB": {
            title: "Upload Avatar",
            uploadButtonText: "Upload Avatar Image",
            cancelButtonText: "Cancel",
            saveButtonText: "Save",
        },
        "es-ES": {
            title: "Sube el avatar",
            uploadButtonText: "Sube la imagen de tu avatar",
            cancelButtonText: "Cancelar",
            saveButtonText: "Guardar",
        },
        "es-MX": {
            title: "Sube el avatar",
            uploadButtonText: "Sube la imagen de tu avatar",
            cancelButtonText: "Cancelar",
            saveButtonText: "Guardar",
        },
        "fr-FR": {
            title: "Télécharger l'avatar",
            uploadButtonText: "Télécharger l'image de l'avatar",
            cancelButtonText: "Annuler",
            saveButtonText: "Enregistrer",
        },
        "de-DE": {
            title: "Avatar hochladen",
            uploadButtonText: "Avatar-Bild hochladen",
            cancelButtonText: "Stornieren",
            saveButtonText: "Speichern",
        },
        "it-IT": {
            title: "Carica avatar",
            uploadButtonText: "Carica immagine avatar",
            cancelButtonText: "Annulla",
            saveButtonText: "Salva",
        },
        "hi-IN": {
            title: "अवतार अपलोड करें",
            uploadButtonText: "अवतार छवि अपलोड करें",
            cancelButtonText: "रद्द करें",
            saveButtonText: "सहेजें",
        },
        "cmn-CN": {
            title: "上传头像",
            uploadButtonText: "上传头像图片",
            cancelButtonText: "取消",
            saveButtonText: "保存",
        },
        "so-SO": {
            title: "Soo rar Avatar",
            uploadButtonText: "Soo ra sawirka Avatar",
            cancelButtonText: "Ka soo bax",
            saveButtonText: "Keyd",
        },
        "pt-PT": {
            title: "Carregar avatar",
            uploadButtonText: "Carregar imagem de avatar",
            cancelButtonText: "Cancelar",
            saveButtonText: "Salvar",
        },
        "pt-BR": {
            title: "Carregar avatar",
            uploadButtonText: "Carregar imagem de avatar",
            cancelButtonText: "Cancelar",
            saveButtonText: "Salvar",
        },
        "id-ID": {
            title: "Unggah Avatar",
            uploadButtonText: "Unggah Gambar Avatar",
            cancelButtonText: "Batal",
            saveButtonText: "Simpan",
        },
        "vi-VN": {
            title: "Tải lên Avatar",
            uploadButtonText: "Tải lên hình ảnh Avatar",
            cancelButtonText: "Hủy",
            saveButtonText: "Lưu",
        },
        "ko-KR": {
            title: "아바타 업로드",
            uploadButtonText: "아바타 이미지 업로드",
            cancelButtonText: "취소",
            saveButtonText: "저장",
        },
        "ja-JP": {
            title: "アバターをアップロード",
            uploadButtonText: "アバター画像をアップロード",
            cancelButtonText: "キャンセル",
            saveButtonText: "保存",
        },
        "ar-IQ": {
            title: "تحميل الصورة الرمزية",
            uploadButtonText: "تحميل صورة الصورة الرمزية",
            cancelButtonText: "إلغاء",
            saveButtonText: "حفظ",
        },
        "fil-PH": {
            title: "I-upload ang Avatar",
            uploadButtonText: "I-upload ang Larawan ng Avatar",
            cancelButtonText: "Kanselahin",
            saveButtonText: "I-save",
        },
        "ne-NP": {
            title: "अवतार अपलोड गर्नुहोस्",
            uploadButtonText: "अवतार तस्वीर अपलोड गर्नुहोस्",
            cancelButtonText: "रद्द गर्नुहोस्",
            saveButtonText: "बचत गर्नुहोस्",
        },
        "ar-SA": {
            title: "تحميل الصورة الرمزية",
            uploadButtonText: "تحميل صورة الصورة الرمزية",
            cancelButtonText: "إلغاء",
            saveButtonText: "حفظ",
        },
        "am-ET": {
            title: "አቫታር እንዴት እንደሚሰጥ እንደሚረዳ",
            uploadButtonText: "አቫታር ምስል እንደሚሰጥ",
            cancelButtonText: "ሰርዝ",
            saveButtonText: "አስቀምጥ",
        }
    },
    siteInfoPanel: {
        "en-US": {
            title: "Site Information",
            ownership: "EarthLingual is a product of Hexplo, LLC.",
            copyright: "Copyright © Hexplo, LLC - (https://hexplo.com)",
            infoBlurb: "For information on custom solutions, investment, or partnership opportunities, please contact us at info@hexplo.com.",
            closeButtonText: "Close",
            privacyPolicyLinkText: "Privacy Policy",
            termsOfServiceLinkText: "Terms of Service",
        },
        "en-GB": {
            title: "Site Information",
            ownership: "EarthLingual is a product of Hexplo, LLC.",
            copyright: "Copyright © Hexplo, LLC - (https://hexplo.com)",
            infoBlurb: "For information on custom solutions, investment, or partnership opportunities, please contact us at info@hexplo.com.",
            closeButtonText: "Close",
            privacyPolicyLinkText: "Privacy Policy",
            termsOfServiceLinkText: "Terms of Service",
        },
        "es-ES": {
            title: "Información del sitio",
            ownership: "EarthLingual es un producto de Hexplo, LLC.",
            copyright: "Derechos de autor © Hexplo, LLC - (https://hexplo.com)",
            infoBlurb: "Para obtener información sobre soluciones personalizadas, inversión u oportunidades de colaboración, contáctanos en info@hexplo.com.",
            closeButtonText: "Cerrar",
            privacyPolicyLinkText: "Política de privacidad",
            termsOfServiceLinkText: "Términos de servicio",
        },
        "es-MX": {
            title: "Información del sitio",
            ownership: "EarthLingual es un producto de Hexplo, LLC.",
            copyright: "Derechos de autor © Hexplo, LLC - (https://hexplo.com)",
            infoBlurb: "Para obtener información sobre soluciones personalizadas, inversión u oportunidades de colaboración, contáctanos en info@hexplo.com.",
            closeButtonText: "Cerrar",
            privacyPolicyLinkText: "Política de privacidad",
            termsOfServiceLinkText: "Términos de servicio",
        },
        "fr-FR": {
            title: "Informations sur le site",
            ownership: "EarthLingual est un produit de Hexplo, LLC.",
            copyright: "Droits d'auteur © Hexplo, LLC - (https://hexplo.com)",
            infoBlurb: "Pour plus d'informations sur les solutions personnalisées, l'investissement ou les opportunités de collaboration, contactez-nous à info@hexplo.com.",
            closeButtonText: "Fermer",
            privacyPolicyLinkText: "Politique de confidentialité",
            termsOfServiceLinkText: "Conditions d'utilisation",
        },
        "de-DE": {
            title: "Website-Informationen",
            ownership: "EarthLingual ist ein Produkt von Hexplo, LLC.",
            copyright: "Urheberrecht © Hexplo, LLC – (https://hexplo.com)",
            infoBlurb: "Für Informationen zu kundenspezifischen Lösungen, Investitionen oder Partnerschaftsmöglichkeiten kontaktieren Sie uns bitte unter info@hexplo.com.",
            closeButtonText: "Schließen",
            privacyPolicyLinkText: "Datenschutzrichtlinie",
            termsOfServiceLinkText: "Nutzungsbedingungen",
        },
        "it-IT": {
            title: "Informazioni sul sito",
            ownership: "EarthLingual è un prodotto di Hexplo, LLC.",
            copyright: "Diritti d'autore © Hexplo, LLC - (https://hexplo.com)",
            infoBlurb: "Se sei interessato a soluzioni personalizzate, investimenti o opportunità di partnership, mandaci un'email a info@hexplo.com.",
            closeButtonText: "Chiudi",
            privacyPolicyLinkText: "Informativa sulla privacy",
            termsOfServiceLinkText: "Condizioni di servizio",
        },
        "hi-IN": {
            title: "साइट सूचना",
            ownership: "EarthLingual एक Hexplo, LLC का उत्पाद है।",
            copyright: "कॉपीराइट © Hexplo, LLC - (https://hexplo.com)",
            infoBlurb: "कस्टम सॉल्यूशन्स, निवेश, या पार्टनरशिप के अवसरों के बारे में जानकारी के लिए, कृपया info@hexplo.com पर संपर्क करें।",
            closeButtonText: "बंद करें",
            privacyPolicyLinkText: "गोपनीयता नीति",
            termsOfServiceLinkText: "सेवा की शर्तें",
        },
        "cmn-CN": {
            title: "网站信息",
            ownership: "EarthLingual 是 Hexplo, LLC 的产品。",
            copyright: "版权 © Hexplo, LLC - (https://hexplo.com)",
            infoBlurb: "如果您对定制解决方案、投资或合作机会感兴趣，请发送电子邮件至 info@hexplo.com。",
            closeButtonText: "关闭",
            privacyPolicyLinkText: "隐私政策",
            termsOfServiceLinkText: "服务条款",
        },
        "so-SO": {
            title: "Macluumaadka Goobta",
            ownership: "EarthLingual waa wax ka barta Hexplo, LLC.",
            copyright: "Xafiiska Xafiiska © Hexplo, LLC - (https://hexplo.com)",
            infoBlurb: "Macluumaad ku saabsan xalalka gaarka ah, maalgashiga, ama fursadaha iskaashiga, fadlan nagala soo xiriir info@hexplo.com.",
            closeButtonText: "Xidh",
            privacyPolicyLinkText: "Qorshaha Arrimaha Gaarka ah",
            termsOfServiceLinkText: "Shuruudaha Adeegga",

        },
        "pt-PT": {
            title: "Informações do local",
            ownership: "EarthLingual é um produto da Hexplo, LLC.",
            copyright: "Direitos autorais © Hexplo, LLC - (https://hexplo.com)",
            infoBlurb: "Para obter informações sobre soluções personalizadas, investimentos ou oportunidades de parceria, contacte-nos através do e-mail info@hexplo.com.",
            closeButtonText: "Fechar",
            privacyPolicyLinkText: "Política de Privacidade",
            termsOfServiceLinkText: "Termos de Serviço",
        },
        "pt-BR": {
            title: "Informações do site",
            ownership: "EarthLingual é um produto da Hexplo, LLC.",
            copyright: "Direitos autorais © Hexplo, LLC - (https://hexplo.com)",
            infoBlurb: "Para obter informações sobre soluções personalizadas, investimentos ou oportunidades de parceria, contacte-nos através do e-mail info@hexplo.com.",
            closeButtonText: "Fechar",
            privacyPolicyLinkText: "Política de Privacidade",
            termsOfServiceLinkText: "Termos de Serviço",
        },
        "id-ID": {
            title: "Informasi Situs",
            ownership: "EarthLingual adalah produk dari Hexplo, LLC.",
            copyright: "Hak Cipta © Hexplo, LLC - (https://hexplo.com)",
            infoBlurb: "Untuk informasi tentang solusi khusus, investasi, atau peluang kemitraan, silakan hubungi kami di info@hexplo.com.",
            closeButtonText: "Tutup",
            privacyPolicyLinkText: "Kebijakan Privasi",
            termsOfServiceLinkText: "Syarat Layanan",
        },
        "vi-VN": {
            title: "Thông Tin Trang Web",
            ownership: "EarthLingual là một sản phẩm của Hexplo, LLC.",
            copyright: "Bản quyền © Hexplo, LLC - (https://hexplo.com)",
            infoBlurb: "Để biết thông tin về các giải pháp tùy chỉnh, cơ hội đầu tư hoặc hợp tác, vui lòng liên hệ với chúng tôi theo địa chỉ info@hexplo.com.",
            closeButtonText: "Đóng",
            privacyPolicyLinkText: "Chính Sách Bảo Mật",
            termsOfServiceLinkText: "Điều Khoản Dịch Vụ",
        },
        "ko-KR": {
            title: "사이트 정보",
            ownership: "EarthLingual은 Hexplo, LLC의 제품입니다.",
            copyright: "저작권 © Hexplo, LLC - (https://hexplo.com)",
            infoBlurb: "맞춤형 솔루션, 투자 또는 파트너십 기회에 대한 자세한 내용은 info@hexplo.com으로 문의하세요.",
            closeButtonText: "닫기",
            privacyPolicyLinkText: "개인정보 보호 정책",
            termsOfServiceLinkText: "서비스 약관",
        },
        "ja-JP": {
            title: "サイト情報",
            ownership: "EarthLingual は Hexplo, LLC の製品です。",
            copyright: "著作権 © Hexplo, LLC - (https://hexplo.com)",
            infoBlurb: "カスタム ソリューション、投資、パートナーシップの機会に関する情報については、info@hexplo.com までお問い合わせください。",
            closeButtonText: "閉じる",
            privacyPolicyLinkText: "プライバシーポリシー",
            termsOfServiceLinkText: "利用規約",
        },
        "ar-IQ": {
            title: "معلومات الموقع",
            ownership: "EarthLingual هو منتج من Hexplo, LLC.",
            copyright: "حقوق النشر © Hexplo, LLC - (https://hexplo.com)",
            infoBlurb: "للحصول على معلومات حول الحلول المخصصة أو الاستثمار أو فرص الشراكة، يرجى الاتصال بنا على info@hexplo.com.",
            closeButtonText: "إغلاق",
            privacyPolicyLinkText: "سياسة الخصوصية",
            termsOfServiceLinkText: "شروط الخدمة",
        },
        "fil-PH": {
            title: "Impormasyon ng Site",
            ownership: "Ang EarthLingual ay isang produkto ng Hexplo, LLC.",
            copyright: "Karapatan © Hexplo, LLC - (https://hexplo.com)",
            infoBlurb: "Para sa impormasyon sa mga custom na solusyon, pamumuhunan, o pagkakataon sa pakikipagsosyo, mangyaring makipag-ugnayan sa amin sa info@hexplo.com.",
            closeButtonText: "Isara",
            privacyPolicyLinkText: "Patakaran sa Privacy",
            termsOfServiceLinkText: "Mga Tuntunin ng Serbisyo",
        },
        "ne-NP": {
            title: "साइटको जानकारी",
            ownership: "EarthLingual एक Hexplo, LLC को उत्पादन हो।",
            copyright: "कपिराइट © Hexplo, LLC - (https://hexplo.com)",
            infoBlurb: "अनुकूलन समाधान, लगानी, वा साझेदारी अवसरहरूमा जानकारीको लागि, कृपया हामीलाई info@hexplo.com मा सम्पर्क गर्नुहोस्।",
            closeButtonText: "बन्द गर्नुहोस्",
            privacyPolicyLinkText: "गोपनीयता नीति",
            termsOfServiceLinkText: "सेवा सर्त",
        },
        "ar-SA": {
            title: "معلومات الموقع",
            ownership: "EarthLingual هو منتج من Hexplo, LLC.",
            copyright: "حقوق النشر © Hexplo, LLC - (https://hexplo.com)",
            infoBlurb: "للحصول على معلومات حول الحلول المخصصة أو الاستثمار أو فرص الشراكة، يرجى الاتصال بنا على info@hexplo.com.",
            closeButtonText: "إغلاق",
            privacyPolicyLinkText: "سياسة الخصوصية",
            termsOfServiceLinkText: "شروط الخدمة",
        },
        "am-ET": {
            title: "የድህረገፁ መረጃ",
            ownership: "EarthLingual ከ Hexplo, LLC ተማሪ ነው።",
            infoBlurb: "ስለ ብጁ መፍትሄዎች፣ ኢንቬስትመንት ወይም አጋርነት እድሎች መረጃ ለማግኘት እባክዎ በ info@hexplo.com ላይ ያግኙን።",
            closeButtonText: "ዝጋ",
            privacyPolicyLinkText: "ፍላሽ ፖሊሲ",
            termsOfServiceLinkText: "የአገልግሎት ግንኙነት",

        }
    },
    chatDeletePanel: {
        "en-US": {
            title: "Confirm Delete",
            textContent: "Are you sure you want to delete this conversation? Once deleted it cannot be restored.",
            cancelButtonText: "Cancel",
            deleteButtonText: "Delete it!"

        },
        "en-GB": {
            title: "Confirm Delete",
            textContent: "Are you sure you want to delete this conversation? Once deleted it cannot be restored.",
            cancelButtonText: "Cancel",
            deleteButtonText: "Delete it!"
        },
        "es-ES": {
            title: "Confirma eliminar",
            textContent: "¿Estás seguro que quieres borrar esta conversación? Una vez borrada no se puede recuperar.",
            cancelButtonText: "Cancelar",
            deleteButtonText: "¡Bórralo!"
        },
        "es-MX": {
            title: "Confirma Eliminar",
            textContent: "¿Estás seguro que quieres borrar esta conversación? Una vez borrada no se puede recuperar.",
            cancelButtonText: "Cancelar",
            deleteButtonText: "¡Bórralo!"
        },
        "fr-FR": {
            title: "Confirmer la Suppression",
            textContent: "Êtes-vous sûr de vouloir supprimer cette conversation ? Une fois supprimée, elle ne pourra pas être restaurée.",
            cancelButtonText: "Annule",
            deleteButtonText: "Supprime-le !"
        },
        "de-DE": {
            title: "Löschen bestätigen",
            textContent: "Möchtest du dieses Gespräch wirklich löschen? Einmal gelöscht, kann es nicht wiederhergestellt werden.",
            cancelButtonText: "Abbrechen",
            deleteButtonText: "Löschen!"
        },
        "it-IT": {
            title: "Conferma Eliminazione",
            textContent: "Sei sicuro di voler eliminare questa conversazione? Una volta eliminata, non può essere ripristinata.",
            cancelButtonText: "Annulla",
            deleteButtonText: "Elimina!"
        },
        "hi-IN": {
            title: "हटाने की पुष्टि करें",
            textContent: "क्या आप वाकई इस बातचीत को हटाना चाहते हैं? एक बार हटाने के बाद इसे पुनः प्राप्त नहीं किया जा सकता।",
            cancelButtonText: "रद्द करें",
            deleteButtonText: "हटा दें!"
        },
        "cmn-CN": {
            title: "确认删除",
            textContent: "您确定要删除此对话吗？删除后将无法恢复。",
            cancelButtonText: "取消",
            deleteButtonText: "删除！"
        },
        "so-SO": {
            title: "Xaqiiji Tirtir",
            textContent: "Ma hubtaa in",
            cancelButtonText: "Ka bax",
            deleteButtonText: "Tirtir!"
        },
        "pt-PT": {
            title: "Confirmar eliminação",
            textContent: "Tem a certeza de que deseja eliminar esta conversa? Uma vez eliminada, não poderá ser restaurada.",
            cancelButtonText: "Cancelar",
            deleteButtonText: "Eliminar!"
        },
        "pt-BR": {
            title: "Confirmar Exclusão",
            textContent: "Tem certeza de que deseja excluir esta conversa? Uma vez excluída, não poderá ser restaurada.",
            cancelButtonText: "Cancelar",
            deleteButtonText: "Excluir!"
        },
        "id-ID": {
            title: "Konfirmasi Hapus",
            textContent: "Apakah Anda yakin ingin menghapus percakapan ini? Setelah dihapus, tidak dapat dipulihkan.",
            cancelButtonText: "Batal",
            deleteButtonText: "Hapus!"
        },
        "vi-VN": {
            title: "Xác Nhận Xóa",
            textContent: "Bạn có chắc chắn muốn xóa cuộc trò chuyện này không? Một khi đã xóa, không thể khôi phục.",
            cancelButtonText: "Hủy",
            deleteButtonText: "Xóa!"
        },
        "ko-KR": {
            title: "삭제 확인",
            textContent: "이 대화를 삭제하시겠습니까? 삭제하면 복원할 수 없습니다.",
            cancelButtonText: "취소",
            deleteButtonText: "삭제!"
        },
        "ja-JP": {
            title: "削除の確認",
            textContent: "この会話を削除してもよろしいですか？削除すると元に戻すことはできません。",
            cancelButtonText: "キャンセル",
            deleteButtonText: "削除！"
        },
        "ar-IQ": {
            title: "تأكيد الحذف",
            textContent: "هل أنت متأكد أنك تريد حذف هذه المحادثة؟ بمجرد الحذف لا يمكن استعادتها.",
            cancelButtonText: "إلغاء",
            deleteButtonText: "حذفها!"
        },
        "fil-PH": {
            title: "Kumpirmahin ang Tanggalin",
            textContent: "Sigurado ka bang nais mong tanggalin ang usapang ito? Kapag tinanggal na ito, hindi na ito maaaring ibalik.",
            cancelButtonText: "Kanselahin",
            deleteButtonText: "Tanggalin!"
        },
        "ne-NP": {
            title: "हटाउनु पुष्टि गर्नुहोस्",
            textContent: "के तपाईं यो चर्चालाई हटाउन चाहनुहुन्छ? हटाएपछि यसलाई पुनः प्राप्त गर्न सकिँदैन।",
            cancelButtonText: "रद्द गर्नुहोस्",
            deleteButtonText: "हटाउनुहोस्!"
        },
        "ar-SA": {
            title: "تأكيد الحذف",
            textContent: "هل أنت متأكد أنك تريد حذف هذه المحادثة؟ بمجرد الحذف لا يمكن استعادتها.",
            cancelButtonText: "إلغاء",
            deleteButtonText: "حذفها!"
        },
        "am-ET": {
            title: "አረጋግጥ አስወግድ",
            textContent: "እርግጠኛ ነህ ይህን ክፍል ማስወገድ ይፈልጋሉ? ከለከለ ከሚያስወግድ አንዱ አልተወገደም።",
            cancelButtonText: "ሰርዝ",
            deleteButtonText: "አስወግድ!"
        }

    },

    avatarDeletePanel: {
        "en-US": {
            title: "Confirm Delete",
            textContent: "Are you sure you want to delete your avatar image? Once deleted it cannot be restored.",
            cancelButtonText: "Cancel",
            deleteButtonText: "Delete it!"

        },
        "en-GB": {
            title: "Confirm Delete",
            textContent: "Are you sure you want to delete your avatar image? Once deleted it cannot be restored.",
            cancelButtonText: "Cancel",
            deleteButtonText: "Delete it!"
        },
        "es-ES": {
            title: "Confirma eliminar",
            textContent: "¿Estás seguro que quieres borrar la imagen de tu avatar? Una vez borrada no se puede recuperar.",
            cancelButtonText: "Cancelar",
            deleteButtonText: "¡Bórralo!"
        },
        "es-MX": {
            title: "Confirma Eliminar",
            textContent: "¿Estás seguro que quieres borrar la imagen de tu avatar? Una vez borrada no se puede recuperar.",
            cancelButtonText: "Cancelar",
            deleteButtonText: "¡Bórralo!"
        },
        "fr-FR": {
            title: "Confirmer la Suppression",
            textContent: "Tu es sûr de vouloir supprimer ton image d'avatar ? Une fois supprimée, elle ne pourra pas être restaurée.",
            cancelButtonText: "Annule",
            deleteButtonText: "Supprime-le !"
        },
        "de-DE": {
            title: "Löschen bestätigen",
            textContent: "Möchtest du dein Avatarbild wirklich löschen? Einmal gelöscht, kann es nicht wiederhergestellt werden.",
            cancelButtonText: "Abbrechen",
            deleteButtonText: "Löschen!"
        },
        "it-IT": {
            title: "Conferma Eliminazione",
            textContent: "Sei sicuro di voler eliminare l'immagine del tuo avatar? Una volta eliminata, non può essere ripristinata.",
            cancelButtonText: "Annulla",
            deleteButtonText: "Elimina!"
        },
        "hi-IN": {
            title: "हटाने की पुष्टि करें",
            textContent: "क्या आप वाकई अपनी अवतार छवि को हटाना चाहते हैं? एक बार हटाने के बाद इसे पुनः प्राप्त नहीं किया जा सकता।",
            cancelButtonText: "रद्द करें",
            deleteButtonText: "हटा दें!"
        },
        "cmn-CN": {
            title: "确认删除",
            textContent: "您确定要删除您的头像图片吗？删除后将无法恢复。",
            cancelButtonText: "取消",
            deleteButtonText: "删除！"
        },
        "so-SO": {
            title: "Xaqiiji Tirtir",
            textContent: "Ma hubtaa in",
            cancelButtonText: "Ka bax",
            deleteButtonText: "Tirtir!"
        },
        "pt-PT": {
            title: "Confirmar eliminação",
            textContent: "Tem a certeza de que deseja eliminar a imagem do seu avatar? Uma vez eliminada, não poderá ser restaurada.",
            cancelButtonText: "Cancelar",
            deleteButtonText: "Eliminar!"
        },
        "pt-BR": {
            title: "Confirmar Exclusão",
            textContent: "Tem certeza de que deseja excluir a imagem do seu avatar? Uma vez excluída, não poderá ser restaurada.",
            cancelButtonText: "Cancelar",
            deleteButtonText: "Excluir!"
        },
        "id-ID": {
            title: "Konfirmasi Hapus",
            textContent: "Apakah Anda yakin ingin menghapus gambar avatar Anda? Setelah dihapus, tidak dapat dipulihkan.",
            cancelButtonText: "Batal",
            deleteButtonText: "Hapus!"
        },
        "vi-VN": {
            title: "Xác nhận Xóa",
            textContent: "Bạn có chắc chắn muốn xóa hình ảnh avatar của mình không? Một khi đã xóa, không thể khôi phục.",
            cancelButtonText: "Hủy",
            deleteButtonText: "Xóa!"
        },
        "ko-KR": {
            title: "삭제 확인",
            textContent: "아바타 이미지를 삭제하시겠습니까? 삭제하면 복원할 수 없습니다.",
            cancelButtonText: "취소",
            deleteButtonText: "삭제!"
        },
        "ja-JP": {
            title: "削除の確認",
            textContent: "アバター画像を削除してもよろしいですか？削除すると元に戻すことはできません。",
            cancelButtonText: "キャンセル",
            deleteButtonText: "削除する！"
        },
        "ar-IQ": {
            title: "تأكيد الحذف",
            textContent: "هل أنت متأكد أنك تريد حذف صورة الصورة الرمزية الخاصة بك؟ بمجرد حذفها لا يمكن استعادتها.",
            cancelButtonText: "إلغاء",
            deleteButtonText: "حذفها!"
        },
        "fil-PH": {
            title: "Kumpirmahin ang Pagtanggal",
            textContent: "Sigurado ka bang nais mong tanggalin ang iyong larawan ng avatar? Kapag tinanggal na ito, hindi na ito maaaring ibalik.",
            cancelButtonText: "Kanselahin",
            deleteButtonText: "Tanggalin ito!"
        },
        "ne-NP": {
            title: "हटाउनु पुष्टि गर्नुहोस्",
            textContent: "के तपाईं निश्चित रूपमा आफ्नो अवतार छवि हटाउन चाहनुहुन्छ? हटाएपछि यसलाई पुनः प्राप्त गर्न सकिँदैन।",
            cancelButtonText: "रद्द गर्नुहोस्",
            deleteButtonText: "हटाउनुहोस्!"
        },
        "ar-SA": {
            title: "تأكيد الحذف",
            textContent: "هل أنت متأكد أنك تريد حذف صورة الصورة الرمزية الخاصة بك؟ بمجرد حذفها لا يمكن استعادتها.",
            cancelButtonText: "إلغاء",
            deleteButtonText: "حذفها!"
        },
        "am-ET": {
            title: "ምንም አልተወንጆም",
            textContent: "እርግጠኛ ነበር የእርስዎ አቫታር ምስልን እንደሚያስወግድ ነው። ከአንድ ቀን በኋላ አስወግድለት አልተወንገም።",
            cancelButtonText: "ሰርዝ",
            deleteButtonText: "አስወግድ!"
        }
    },
    messageOptionsWidget: {
        "en-US": {
            deleteTitle: "Confirm Message Delete",
            deleteTextContent: "Are you sure you want to delete this message? Once deleted it cannot be restored.",
            deleteCancelButtonText: "Cancel",
            deleteDeleteButtonText: "Delete it!"
        },
        "en-GB": {
            deleteTitle: "Confirm Message Delete",
            deleteTextContent: "Are you sure you want to delete this message? Once deleted it cannot be restored.",
            deleteCancelButtonText: "Cancel",
            deleteDeleteButtonText: "Delete it!"
        },
        "es-ES": {
            deleteTitle: "Confirma eliminación de mensaje",
            deleteTextContent: "¿Estás seguro que quieres borrar este mensaje? Una vez borrado no se puede recuperar.",
            deleteCancelButtonText: "Cancelar",
            deleteDeleteButtonText: "¡Bórralo!"
        },
        "es-MX": {
            deleteTitle: "Confirma eliminación de mensaje",
            deleteTextContent: "¿Estás seguro que quieres borrar este mensaje? Una vez borrado no se puede recuperar.",
            deleteCancelButtonText: "Cancelar",
            deleteDeleteButtonText: "¡Bórralo!"
        },
        "fr-FR": {
            deleteTitle: "Confirmer la suppression du message",
            deleteTextContent: "Tu es sûr de vouloir supprimer ce message ? Une fois supprimé, il ne pourra pas être restauré.",
            deleteCancelButtonText: "Annule",
            deleteDeleteButtonText: "Supprime-le !"
        },
        "de-DE": {
            deleteTitle: "Bestätige die Löschung der Nachricht",
            deleteTextContent: "Möchtest du diese Nachricht wirklich löschen? Einmal gelöscht, kann sie nicht wiederhergestellt werden.",
            deleteCancelButtonText: "Abbrechen",
            deleteDeleteButtonText: "Löschen!"
        },
        "it-IT": {
            deleteTitle: "Conferma eliminazione del messaggio",
            deleteTextContent: "Sei sicuro di voler eliminare questo messaggio? Una volta eliminato, non può essere ripristinato.",
            deleteCancelButtonText: "Annulla",
            deleteDeleteButtonText: "Elimina!"
        },
        "hi-IN": {
            deleteTitle: "मैसेज डिलीट करने की पुष्टि करें",
            deleteTextContent: "क्या आप वाकई इस मैसेज को हटाना चाहते हैं? एक बार हटाने के बाद इसे पुनः प्राप्त नहीं किया जा सकता।",
            deleteCancelButtonText: "रद्द करें",
            deleteDeleteButtonText: "हटा दें!"
        },
        "cmn-CN": {
            deleteTitle: "确认删除消息",
            deleteTextContent: "您确定要删除此消息吗？删除后将无法恢复。",
            deleteCancelButtonText: "取消",
            deleteDeleteButtonText: "删除！"
        },
        "so-SO": {
            deleteTitle: "Xaqiiji Tirtir",
            deleteTextContent: "Ma hubtaa in",
            deleteCancelButtonText: "Ka bax",
            deleteDeleteButtonText: "Tirtir!"
        },
        "pt-PT": {
            deleteTitle: "Confirmar eliminação de mensagem",
            deleteTextContent: "Tem a certeza de que deseja eliminar esta mensagem? Uma vez eliminada, não poderá ser restaurada.",
            deleteCancelButtonText: "Cancelar",
            deleteDeleteButtonText: "Eliminar!"
        },
        "pt-BR": {
            deleteTitle: "",
            deleteTextContent: "",
            deleteCancelButtonText: "",
            deleteDeleteButtonText: ""
        },
        "id-ID": {
            deleteTitle: "Konfirmasi Hapus Pesan",
            deleteTextContent: "Apakah Anda yakin ingin menghapus pesan ini? Setelah dihapus, tidak dapat dipulihkan.",
            deleteCancelButtonText: "Batal",
            deleteDeleteButtonText: "Hapus!"
        },
        "vi-VN": {
            deleteTitle: "Xác nhận Xóa Tin nhắn",
            deleteTextContent: "Bạn có chắc chắn muốn xóa tin nhắn này không? Một khi đã xóa, không thể khôi phục.",
            deleteCancelButtonText: "Hủy",
            deleteDeleteButtonText: "Xóa!"
        },
        "ko-KR": {
            deleteTitle: "메시지 삭제 확인",
            deleteTextContent: "이 메시지를 삭제하시겠습니까? 삭제하면 복구할 수 없습니다.",
            deleteCancelButtonText: "취소",
            deleteDeleteButtonText: "삭제!"
        },
        "ja-JP": {
            deleteTitle: "メッセージの削除を確認",
            deleteTextContent: "このメッセージを削除してもよろしいですか？削除すると元に戻すことはできません。",
            deleteCancelButtonText: "キャンセル",
            deleteDeleteButtonText: "削除する！"
        },
        "ar-IQ": {
            deleteTitle: "تأكيد حذف الرسالة",
            deleteTextContent: "هل أنت متأكد أنك تريد حذف هذه الرسالة؟ بمجرد الحذف لا يمكن استعادتها.",
            deleteCancelButtonText: "إلغاء",
            deleteDeleteButtonText: "حذفها!"
        },
        "fil-PH": {
            deleteTitle: "Kumpirmahin ang Pagtanggal ng Mensahe",
            deleteTextContent: "Sigurado ka bang nais mong tanggalin ang mensaheng ito? Kapag tinanggal na, hindi na ito maaaring ibalik.",
            deleteCancelButtonText: "Kanselahin",
            deleteDeleteButtonText: "Tanggalin!"
        },
        "ne-NP": {
            deleteTitle: "संदेश हटाउनु पुष्टि गर्नुहोस्",
            deleteTextContent: "के तपाईं यो संदेश हटाउन चाहनुहुन्छ? हटाएपछि यसलाई पुनः प्राप्त गर्न सकिने छैन।",
            deleteCancelButtonText: "रद्द गर्नुहोस्",
            deleteDeleteButtonText: "हटाउनुहोस्!"
        },
        "ar-SA": {
            deleteTitle: "تأكيد حذف الرسالة",
            deleteTextContent: "هل أنت متأكد أنك تريد حذف هذه الرسالة؟ بمجرد الحذف لا يمكن استعادتها.",
            deleteCancelButtonText: "إلغاء",
            deleteDeleteButtonText: "حذفها!"
        },
        "am-ET": {
            deleteTitle: "መልስ አለው",
            deleteTextContent: "እባኮት እዚህ መልስ እንዴት እንደሚያስወግድ ነው? እንዲህ እንደሚያስወግድ አልቻልኩም።",
            deleteCancelButtonText: "ሰርዝ",
            deleteDeleteButtonText: "አስወግድ!"
        }
    },
    feedbackWidget: {
        "en-US": {
            reasonText: "Reason",
            cancelButtonText: "Cancel",
            submitButtonText: "Submit"
        },
        "en-GB": {
            reasonText: "Reason",
            cancelButtonText: "Cancel",
            submitButtonText: "Submit"
        },
        "es-ES": {
            reasonText: "Razón",
            cancelButtonText: "Cancelar",
            submitButtonText: "Enviar"
        },
        "es-MX": {
            reasonText: "Razón",
            cancelButtonText: "Cancelar",
            submitButtonText: "Enviar"
        },
        "fr-FR": {
            reasonText: "Raison",
            cancelButtonText: "Annuler",
            submitButtonText: "Envoyer"
        },
        "de-DE": {
            reasonText: "Grund",
            cancelButtonText: "Abbrechen",
            submitButtonText: "Senden"
        },
        "it-IT": {
            reasonText: "Motivo",
            cancelButtonText: "Annulla",
            submitButtonText: "Invia"
        },
        "hi-IN": {
            reasonText: "कारण",
            cancelButtonText: "रद्द करें",
            submitButtonText: "सबमिट करें"
        },
        "cmn-CN": {
            reasonText: "原因",
            cancelButtonText: "取消",
            submitButtonText: "提交"
        },
        "so-SO": {
            reasonText: "Sabab",
            cancelButtonText: "Ka bax",
            submitButtonText: "Dir"
        },
        "pt-PT": {
            reasonText: "Razão",
            cancelButtonText: "Cancelar",
            submitButtonText: "Enviar"
        },
        "pt-BR": {
            reasonText: "Razão",
            cancelButtonText: "Cancelar",
            submitButtonText: "Enviar"
        },
        "id-ID": {
            reasonText: "Alasan",
            cancelButtonText: "Batal",
            submitButtonText: "Kirim"
        },
        "vi-VN": {
            reasonText: "Lý do",
            cancelButtonText: "Hủy",
            submitButtonText: "Gửi"
        },
        "ko-KR": {
            reasonText: "이유",
            cancelButtonText: "취소",
            submitButtonText: "제출"
        },
        "ja-JP": {
            reasonText: "理由",
            cancelButtonText: "キャンセル",
            submitButtonText: "送信"
        },
        "ar-IQ": {
            reasonText: "سبب",
            cancelButtonText: "يلغي",
            submitButtonText: "إرسال"
        },
        "fil-PH": {
            reasonText: "Dahilan",
            cancelButtonText: "Kanselahin",
            submitButtonText: "Ipasa"
        },
        "ne-NP": {
            reasonText: "कारण",
            cancelButtonText: "रद्द गर्नुहोस्",
            submitButtonText: "पेश गर्नुहोस्"
        },
        "ar-SA": {
            reasonText: "سبب",
            cancelButtonText: "يلغي",
            submitButtonText: "إرسال"
        },
        "am-ET": {
            reasonText: "ምንም ምንም",
            cancelButtonText: "ሰርዝ",
            submitButtonText: "አስወግድ"
        }
    },

    notificationsPanel: {
        "en-US": {
            title: "Notifications",
            joinRequest: "wants to join the conversation",
            joinedAnnouncement: "joined the conversation",
            invite: "You were invited to join the conversation",
            inviteApproved: "You joined the conversation",
            approveButton: "Approve",
            acceptButton: "Accept",
            rejectButton: "Reject",
            openConversationButton: "Open Conversation",
            noNotifications: "There are currently no notifications to display."
        },
        "en-GB": {
            title: "Notifications",
            joinRequest: "wants to join the conversation",
            joinedAnnouncement: "joined the conversation",
            invite: "You were invited to join the conversation",
            inviteApproved: "You joined the conversation",
            approveButton: "Approve",
            acceptButton: "Accept",
            rejectButton: "Reject",
            openConversationButton: "Open Conversation",
            noNotifications: "There are currently no notifications to display."
        },
        "es-ES": {
            title: "Notificaciones",
            joinRequest: "quiere unirse a la conversación",
            joinedAnnouncement: "se unió a la conversación",
            invite: "Te invitaron a unirte a la conversación",
            inviteApproved: "Te uniste a la conversación",
            approveButton: "Aprobar",
            acceptButton: "Aceptar",
            rejectButton: "Rechazar",
            openConversationButton: "Conversación abierta",
            noNotifications: "Actualmente no hay notificaciones para mostrar."
        },
        "es-MX": {
            title: "Notificaciones",
            joinRequest: "quiere unirse a la conversación",
            joinedAnnouncement: "se unió a la conversación",
            invite: "Te invitaron a unirte a la conversación",
            inviteApproved: "Te uniste a la conversación",
            approveButton: "Aprobar",
            acceptButton: "Aceptar",
            rejectButton: "Rechazar",
            openConversationButton: "Conversación abierta",
            noNotifications: "Actualmente no hay notificaciones para mostrar."
        },
        "fr-FR": {
            title: "Notifications",
            joinRequest: "veut rejoindre la conversation",
            joinedAnnouncement: "a rejoint la conversation",
            invite: "Vous avez été invité à rejoindre la conversation",
            inviteApproved: "Vous avez rejoint la conversation",
            approveButton: "Approuver",
            acceptButton: "Accepter",
            rejectButton: "Rejeter",
            openConversationButton: "Ouvrir la conversation",
            noNotifications: "Il n'y a actuellement aucune notification à afficher."
        },
        "de-DE": {
            title: "Benachrichtigungen",
            joinRequest: "möchte dem Gespräch beitreten",
            joinedAnnouncement: "hat dem Gespräch beigetreten",
            invite: "Sie wurden eingeladen, dem Gespräch beizutreten",
            inviteApproved: "Sie haben dem Gespräch beigetreten",
            approveButton: "Genehmigen",
            acceptButton: "Akzeptieren",
            rejectButton: "Ablehnen",
            openConversationButton: "Gespräch öffnen",
            noNotifications: "Derzeit sind keine Benachrichtigungen verfügbar."
        },
        "it-IT": {
            title: "Notifiche",
            joinRequest: "vuole unirsi alla conversazione",
            joinedAnnouncement: "si è unito alla conversazione",
            invite: "Sei stato invitato a partecipare alla conversazione",
            inviteApproved: "Ti sei unito alla conversazione",
            approveButton: "Approva",
            acceptButton: "Accetta",
            rejectButton: "Rifiuta",
            openConversationButton: "Apri conversazione",
            noNotifications: "Al momento non ci sono notifiche da visualizzare."
        },
        "hi-IN": {
            title: "सूचनाएं",
            joinRequest: "बातचीत में शामिल होना चाहता है",
            joinedAnnouncement: "बातचीत में शामिल हुए",
            invite: "आपको बातचीत में शामिल होने के लिए आमंत्रित किया गया था",
            inviteApproved: "आपने बातचीत में शामिल हो गए",
            approveButton: "मंजूरी दें",
            acceptButton: "स्वीकार करें",
            rejectButton: "अस्वीकार करें",
            openConversationButton: "बातचीत खोलें",
            noNotifications: "वर्तमान में कोई सूचनाएं प्रदर्शित करने के लिए नहीं हैं।"
        },
        "cmn-CN": {
            title: "通知",
            joinRequest: "想加入对话",
            joinedAnnouncement: "加入了对话",
            invite: "您被邀请加入对话",
            inviteApproved: "您加入了对话",
            approveButton: "批准",
            acceptButton: "接受",
            rejectButton: "拒绝",
            openConversationButton: "打开对话",
            noNotifications: "当前没有通知可显示。"
        },
        "so-SO": {
            title: "Ogeysiisyada",
            joinRequest: "raba inuu ku biiro wada hadalka",
            joinedAnnouncement: "ku biiray sheekada",
            invite: "Waxaad la socotay inaad ku biiro wada hadalka",
            inviteApproved: "Waad ku biiray sheekada",
            approveButton: "Aqbal",
            acceptButton: "Qaado",
            rejectButton: "Diid",
            openConversationButton: "Fur sheekada",
            noNotifications: "Hadda ma jirto ogeysiiso lagu muujin karo."
        },
        "pt-PT": {
            title: "Notificações",
            joinRequest: "quer juntar-se à conversa",
            joinedAnnouncement: "entrou na conversa",
            invite: "Foi convidado a juntar-se à conversa",
            inviteApproved: "Você entrou na conversa",
            approveButton: "Aprovar",
            acceptButton: "Aceitar",
            rejectButton: "Rejeitar",
            openConversationButton: "Abrir Conversa",
            noNotifications: "Atualmente não há notificações para mostrar."
        },
        "pt-BR": {
            title: "Notificações",
            joinRequest: "quer juntar-se à conversa",
            joinedAnnouncement: "entrou na conversa",
            invite: "Foi convidado a juntar-se à conversa",
            inviteApproved: "Você entrou na conversa",
            approveButton: "Aprovar",
            acceptButton: "Aceitar",
            rejectButton: "Rejeitar",
            openConversationButton: "Abrir Conversa",
            noNotifications: "Atualmente não há notificações para mostrar."
        },
        "id-ID": {
            title: "Pemberitahuan",
            joinRequest: "ingin bergabung dalam percakapan",
            joinedAnnouncement: "bergabung dalam percakapan",
            invite: "Anda diundang untuk bergabung dalam percakapan",
            inviteApproved: "Anda bergabung dalam percakapan",
            approveButton: "Setujui",
            acceptButton: "Terima",
            rejectButton: "Tolak",
            openConversationButton: "Buka Percakapan",
            noNotifications: "Saat ini tidak ada pemberitahuan yang tersedia."
        },
        "vi-VN": {
            title: "Thông báo",
            joinRequest: "muốn tham gia cuộc trò chuyện",
            joinedAnnouncement: "đã tham gia cuộc trò chuyện",
            invite: "Bạn đã được mời tham gia cuộc trò chuyện",
            inviteApproved: "Bạn đã tham gia cuộc trò chuyện",
            approveButton: "Phê duyệt",
            acceptButton: "Chấp nhận",
            rejectButton: "Từ chối",
            openConversationButton: "Mở cuộc trò chuyện",
            noNotifications: "Hiện tại không có thông báo nào để hiển thị."
        },
        "ko-KR": {
            title: "알림",
            joinRequest: "대화에 참여하고 싶어합니다",
            joinedAnnouncement: "대화에 참여했습니다",
            invite: "대화에 참여하도록 초대되었습니다",
            inviteApproved: "대화에 참여했습니다",
            approveButton: "승인",
            acceptButton: "수락",
            rejectButton: "거부",
            openConversationButton: "대화 열기",
            noNotifications: "현재 표시할 알림이 없습니다."
        },
        "ja-JP": {
            title: "通知",
            joinRequest: "が会話に参加したいと思っています",
            joinedAnnouncement: "が会話に参加しました",
            invite: "会話に参加するよう招待されました",
            inviteApproved: "会話に参加しました",
            approveButton: "承認",
            acceptButton: "受け入れる",
            rejectButton: "拒否",
            openConversationButton: "会話を開く",
            noNotifications: "現在表示する通知はありません。"
        },
        "ar-IQ": {
            title: "إشعارات",
            joinRequest: "يريد الانضمام إلى المحادثة",
            joinedAnnouncement: "انضم إلى المحادثة",
            invite: "تمت دعوتك للانضمام إلى المحادثة",
            inviteApproved: "لقد انضممت إلى المحادثة",
            approveButton: "يعتمد",
            acceptButton: "يقبل",
            rejectButton: "ينكر",
            openConversationButton: "فتح المحادثة",
            noNotifications: "لا توجد إشعارات متاحة حاليًا."
        },
        "fil-PH": {
            title: "Mga abiso",
            joinRequest: "gusto sumali sa usapan",
            joinedAnnouncement: "sumali sa usapan",
            invite: "Inimbitahan ka na sumali sa usapan",
            inviteApproved: "Sumali ka na sa usapan",
            approveButton: "Aprubahan",
            acceptButton: "Tanggapin",
            rejectButton: "Tanggihan",
            openConversationButton: "Buksan ang",
            noNotifications: "Walang abiso na magagamit sa kasalukuyan."
        },
        "ne-NP": {
            title: "सूचनाहरू",
            joinRequest: "बार्तामा सामेल हुन चाहन्छ",
            joinedAnnouncement: "बार्तामा सामेल भएको छ",
            invite: "तपाईंलाई बार्तामा सामेल हुनको लागि आमन्त्रित गरिएको छ",
            inviteApproved: "तपाईंले बार्तामा सामेल भएको छ",
            approveButton: "स्वीकार गर्नुहोस्",
            acceptButton: "स्वीकार गर्नुहोस्",
            rejectButton: "अस्वीकार गर्नुहोस्",
            openConversationButton: "बार्ता खोल्नुहोस्",
            noNotifications: "हालको लागि कुनै सूचनाहरू उपलब्ध छैन।"
        },
        "ar-SA": {
            title: "إشعارات",
            joinRequest: "يريد الانضمام إلى المحادثة",
            joinedAnnouncement: "انضم إلى المحادثة",
            invite: "تمت دعوتك للانضمام إلى المحادثة",
            inviteApproved: "لقد انضممت إلى المحادثة",
            approveButton: "يعتمد",
            acceptButton: "يقبل",
            rejectButton: "ينكر",
            openConversationButton: "فتح المحادثة",
            noNotifications: "لا توجد إشعارات متاحة حاليًا."
        },
        "am-ET": {
            title: "ማሳወቂያዎች",
            joinRequest: "እንዴት እንደሚታይ እንደሚታይ ነው",
            joinedAnnouncement: "ውይይቱን ተቀላቀለ",
            invite: "ውይይቱን እንድትቀላቀል ተጋብዘሃል",
            inviteApproved: "ውይይቱን ተቀላቅለዋል።",
            approveButton: "አጽድቅ",
            acceptButton: "ተቀበል",
            rejectButton: "እምቢ",
            openConversationButton: "ውይይቱን ክፈት",
            noNotifications: "አሁን ምንም ማሳወቂያዎች የለም።"
        }
    }

};


/*
siteInfoPanel: {
    "en-US": {

    },
    "en-GB": {

    },
    "es-ES": {

    },
    "es-MX": {

    },
    "fr-FR": {

    },
    "de-DE": {

    },
    "it-IT": {

    },
    "hi-IN": {

    },
    "cmn-CN": {

    },
    "so-SO": {

    },
    "pt-PT": {

    },
    "pt-BR": {

    },
    "id-ID": {

    },
    "vi-VN": {

    },
    "ko-KR": {

    },
    "ja-JP": {

    },
    "ar-IQ": {

    },
    "fil-PH": {

    },
    "ne-NP": {

    },
    "ar-SA": {

    },
    "am-ET": {

    }
}
*/
